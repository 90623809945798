/* @generated from adl */
import { declResolver, ScopedDecl } from "./runtime/adl";
import { _AST_MAP as adlc_config_typescript } from "./adlc/config/typescript";
import { _AST_MAP as common } from "./common";
import { _AST_MAP as common_adminui_api } from "./common/adminui/api";
import { _AST_MAP as common_adminui_db } from "./common/adminui/db";
import { _AST_MAP as common_config_aws } from "./common/config/aws";
import { _AST_MAP as common_config_db } from "./common/config/db";
import { _AST_MAP as common_config_log } from "./common/config/log";
import { _AST_MAP as common_db } from "./common/db";
import { _AST_MAP as common_http } from "./common/http";
import { _AST_MAP as common_strings } from "./common/strings";
import { _AST_MAP as common_tabular } from "./common/tabular";
import { _AST_MAP as common_ui } from "./common/ui";
import { _AST_MAP as sys_adlast } from "./sys/adlast";
import { _AST_MAP as sys_annotations } from "./sys/annotations";
import { _AST_MAP as sys_dynamic } from "./sys/dynamic";
import { _AST_MAP as sys_types } from "./sys/types";
import { _AST_MAP as whistle_propte_accounting } from "./whistle/propte/accounting";
import { _AST_MAP as whistle_propte_api } from "./whistle/propte/api";
import { _AST_MAP as whistle_propte_calcs } from "./whistle/propte/calcs";
import { _AST_MAP as whistle_propte_category } from "./whistle/propte/category";
import { _AST_MAP as whistle_propte_contact } from "./whistle/propte/contact";
import { _AST_MAP as whistle_propte_db } from "./whistle/propte/db";
import { _AST_MAP as whistle_propte_expense } from "./whistle/propte/expense";
import { _AST_MAP as whistle_propte_lease } from "./whistle/propte/lease";
import { _AST_MAP as whistle_propte_property } from "./whistle/propte/property";
import { _AST_MAP as whistle_propte_scenario } from "./whistle/propte/scenario";
import { _AST_MAP as whistle_propte_uiconfig } from "./whistle/propte/uiconfig";
import { _AST_MAP as whistle_propte_utils } from "./whistle/propte/utils";
import { _AST_MAP as whistle_propte_views } from "./whistle/propte/views";

export const ADL: { [key: string]: ScopedDecl } = {
  ...adlc_config_typescript,
  ...common,
  ...common_adminui_api,
  ...common_adminui_db,
  ...common_config_aws,
  ...common_config_db,
  ...common_config_log,
  ...common_db,
  ...common_http,
  ...common_strings,
  ...common_tabular,
  ...common_ui,
  ...sys_adlast,
  ...sys_annotations,
  ...sys_dynamic,
  ...sys_types,
  ...whistle_propte_accounting,
  ...whistle_propte_api,
  ...whistle_propte_calcs,
  ...whistle_propte_category,
  ...whistle_propte_contact,
  ...whistle_propte_db,
  ...whistle_propte_expense,
  ...whistle_propte_lease,
  ...whistle_propte_property,
  ...whistle_propte_scenario,
  ...whistle_propte_uiconfig,
  ...whistle_propte_utils,
  ...whistle_propte_views,
};

export const RESOLVER = declResolver(ADL);
