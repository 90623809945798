import { observer } from "mobx-react";
import React from "react";
import { Header, Table, Segment, Button, Rail, Grid } from "semantic-ui-react";
import moment from "moment";
import { PropteStore } from "../../../stores/propte-store";
import { emptyTurnoverSummaryTable, TurnoverSummaryTableData } from "./turnoverSummaryStore";

const styles = require("./turnoverSummaryTable.css");

/** Props for the component */
export interface TurnoverSummaryTableProps {
  propteStore: PropteStore
}

/**
 * Component for table of turnover summary data
 */
@observer
export class TurnoverSummaryTable extends React.Component<TurnoverSummaryTableProps> {

  componentDidMount() {
    this.refresh();
  }

  /** Renders the component */
  render() {
    const propteStore = this.props.propteStore;
    const turnoverSummaryStore = propteStore.turnoverSummaryStore;

    const updating = turnoverSummaryStore.tableData.updating > 0;
    const value = turnoverSummaryStore.tableData.value;

    return <>
      <Segment loading={updating}>
        {this.renderContents(value)}
      </Segment>
    </>;
  }

  refresh() {
    const turnoverSummaryStore = this.props.propteStore.turnoverSummaryStore;

    // table currently has no input... placeholder for feeding in input
    turnoverSummaryStore.input.set({});
  }

  renderContents(tosTable: TurnoverSummaryTableData|null) : JSX.Element|undefined {
    return <>
      <Segment basic>
        <Header>Turnover Summary</Header>

        {(tosTable !== null) && (tosTable.rows.length > 0) &&
        <Rail attached internal position='right'>
          <Segment basic textAlign='right'>
            <Grid>
              <Grid.Column width={8}>
                <Button onClick={()=>{this.refresh()}}>Refresh</Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button primary onClick={()=>{this.downloadTable(tosTable)}}>Download</Button>
              </Grid.Column>

            </Grid>

          </Segment>
        </Rail>}
      </Segment>

      {this.renderTable(tosTable || emptyTurnoverSummaryTable)}
    </>;
  }

  downloadTable(tosTable: TurnoverSummaryTableData) {

    const filename = `turnover-summary-${moment().format("YYYY-MM-DD-HH-mm")}.csv`;

    const element = document.createElement("a");
    const file = new Blob([ this.renderTableCSV(tosTable) ], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  renderTableCSV(tosTable: TurnoverSummaryTableData) : string {
    const header : string[] = tosTable.columns.map( (col)=>`"${col.header}"`);

    const body : string[][] = tosTable.rows.map((rowData)=>
      tosTable.columns.map((col)=>`"${col.get(rowData)}"`)
    );

    return [header, ...body].join('\n');
  }

  renderTable(tosTable: TurnoverSummaryTableData) : JSX.Element {
    return <div className={styles.table}>

      <Table celled >
        <Table.Header>
          <Table.Row>
          {
            tosTable.columns.map( (col,i)=><Table.HeaderCell key={i}>{col.header}</Table.HeaderCell>)
          }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tosTable.rows.length > 0 ? tosTable.rows.map((rowData,i)=>
            <Table.Row key={i}>
              {tosTable.columns.map((col,j)=><Table.Cell key={j}>{col.get(rowData)}</Table.Cell>)}
            </Table.Row>
          )

          :
          <Table.Row>
              {tosTable.columns.map((col,j)=><Table.Cell key={j}> </Table.Cell>)}
          </Table.Row>

          }
        </Table.Body>
      </Table>
    </div>;
  }
}

