import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Dropdown, Grid, Icon, DropdownProps } from "semantic-ui-react";
import { LeaseStore } from "./leaseStore";
import { LeaseSelectionStore } from "./leaseSelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Lease } from "../../../adl-gen/whistle/propte/lease";
import { defaultFromUndefined } from "../../../utils/defaultFromUndefined";
import { isReturnStatement } from "typescript";

/** Props for the component */
export interface LeaseSelectorProps {
  leaseStore: LeaseStore,
  leaseSelectionStore: LeaseSelectionStore
}

/**
 * Component for Selecting one or more properties (Whistle realestate properties)
 */
@observer
export class LeaseSelectorMulti extends React.Component<LeaseSelectorProps> {

  /** Renders the component */
  render() {
    const leaseStore = this.props.leaseStore;
    const options = leaseStore.selectorOptions.getValue() || [];
    const updating = leaseStore.selectorOptions.isUpdating();

    return (
      <Dropdown
        placeholder='Properties'
        fluid
        multiple
        search
        selection
        scrolling
        clearable
        options={options}
        loading={updating}
        onChange={this.onChange}
        value={this.getSelection()}
      />
    );
  }

  private getSelection() : DbKey<Lease>[] {
    const vals = this.props.leaseSelectionStore.leaseSelection.getValue();
    if(vals === null) {
      return [];
    }
    return Array.from(vals.keys())
  }

  private assignSelection = (dbKeys: DbKey<Lease>[])=>{
    const x = this.props.leaseStore.leases.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>dbKeys.find(i=>i===kv[0]));
      return new Map<DbKey<Lease>, Lease>(entries);
    });
    this.props.leaseSelectionStore.leaseSelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    console.log('leaseSelectorMulti onChange', data);
    const dbKeys : DbKey<Lease>[] = data.value as string[];
    this.assignSelection(dbKeys);
    return;
  }
}

@observer
export class LeaseSelectorSingle extends React.Component<LeaseSelectorProps> {

  /** Renders the component */
  render() {
    const leaseStore = this.props.leaseStore;
    const options = leaseStore.selectorOptions.getValue() || [];
    const updating = leaseStore.selectorOptions.isUpdating();

    return (
      <Dropdown
        placeholder='Leases'
        fluid
        search
        selection
        scrolling
        clearable
        options={options}
        loading={updating}
        onChange={this.onChange}
        value={this.getSelection()}
      />
    );
  }

  private getSelection() : DbKey<Lease> {
    const vals = this.props.leaseSelectionStore.leaseSelection.getValue();
    if(vals === null) {
      return "";
    }
    const keys = Array.from(vals.keys());
    if(keys.length === 0) {
      return "";
    }
    return keys[0];
  }

  private assignSelection = (dbKey: DbKey<Lease>)=>{
    const x = this.props.leaseStore.leases.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>[dbKey].find(i=>i===kv[0]));
      return new Map<DbKey<Lease>, Lease>(entries);
    });
    this.props.leaseSelectionStore.leaseSelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    const dbKey : DbKey<Lease> = data.value as string;
    this.assignSelection(dbKey);
    return;
  }
}
