import { sysCustomize } from "@adltools/adl-customize";
import { createVEditor, CustomContext, Customize, VEditor } from "@adltools/adl-veditor";
import { DDSMMSYYYY_FORMAT } from "@hx/dateformats";
import { assertNever } from "@hx/util/types";
import { action, observable, runInAction } from "mobx";

import { Paginated, Unit } from "../adl-gen/common";
import { DbResult, DbRow, QueryReq, Table } from "../adl-gen/common/adminui/api";
import { DbKey, snDbKey, WithDbId } from "../adl-gen/common/db";
import * as common_http from "../adl-gen/common/http";
import { makeTableQuery, makeTableView, TableView } from "../adl-gen/common/tabular";
import { ATypeExpr, DeclResolver } from "../adl-gen/runtime/adl";
import { createJsonBinding, JsonBinding } from "../adl-gen/runtime/json";
import { scopedNamesEqual } from "../adl-gen/runtime/utils";
import * as adlast from "../adl-gen/sys/adlast";

import { dbKeyFieldFns, dbKeyVEditor } from "./dbkey";
import { AdminService } from "./service";

// DbRow consists of ADL serialized values using the standard
// schema (https://github.com/timbod7/adl/blob/master/docs/serialization.md)
//
// The typescript ADL forms and table tooling is in terms of the typescript
// in-memory representation. We'll define a type for that for clarity.

export interface TSRow {
  [key: string]: unknown;
}

export interface TablePageState {
  table: string;
  tmetadata: Loading<TableMetadata>;
  loadedRows: Paginated<WithDbId<TSRow>> | null;
  canPageBack: boolean;
  canPageForward: boolean;
  rowsLoading: boolean;
  tableView: TableView | null;
  pendingDbError: string | null;
};

export type TablePageEvent
  = {kind: 'pageback' }
  | {kind: 'pageforward' }
  | {kind: 'reload' }
  | {kind: 'cleardberror' }
  | {kind: 'settableview', view: TableView }
  | {kind: 'update', tmetadata: TableMetadata, value: WithDbId<TSRow>}
  | {kind: 'create', tmetadata: TableMetadata, value: TSRow}
  | {kind: 'delete', tmetadata: TableMetadata, id: DbKey<TSRow>}
  ;

export type TableHrefFn = (tablename: string) => string;

export class AdminStore {
  @observable.deep
  metadata: Loading<Metadata>;

  @observable
  tableName: string | null;

  @observable.deep
  lastQuery: {
    tmetadata: TableMetadata;
    view: TableView;
    query: QueryReq;
  } | null;

  @observable.deep
  loadedRows: Paginated<WithDbId<TSRow>> | null;

  @observable
  rowsLoading: boolean;

  @observable
  pendingDbError: string | null = null;

  actions: ActionData[] = [];

  pageSize: number = 20;

  constructor(
    readonly service: AdminService,
    readonly tableHrefFn: TableHrefFn,
    readonly actionFactories: ActionFactory[],
    private readonly appDeclResolver: DeclResolver
  ) {
    this.metadata = { kind: "loading" };
    this.tableName = null;
    this.lastQuery = null;
    this.loadedRows = null;
    void this.reloadMetadata();
  }

  @action
  reloadMetadata = async () => {
    const tables = await this.service.adminQueryTables(makeTableQuery({}));
    const decls = await this.service.adminQueryDecls(makeTableQuery({}));
    const declMap: { [key: string]: adlast.ScopedDecl | undefined } = {};
    decls.items.forEach(mdecl => {
      const sdecl = { moduleName: mdecl.moduleName, decl: mdecl.decl };
      declMap[sdecl.moduleName + "." + sdecl.decl.name] = sdecl;
    });

    const tableMap: { [name: string]: Table } = {};
    tables.items.forEach(table => {
      tableMap[table.name] = table;
    });

    const mergedResolver = (sname: adlast.ScopedName): adlast.ScopedDecl => {
      const decl = declMap[sname.moduleName + "." + sname.name];
      if (decl !== undefined) {
        return decl;
      }
      return this.appDeclResolver(sname);
    };

    const metadata = {
      tableNames: tables.items.map(t => t.name),
      tableMap,
      resolver: mergedResolver
    };
    this.metadata = { kind: "ready", value: metadata };
    if (this.tableName !== null) {
      await this.initialLoad(metadata, this.tableName);
    }
  };

  @action
  setTable = async (tableName: string): Promise<void> => {
    this.tableName = tableName;
    if (this.metadata.kind === "ready") {
      await this.initialLoad(this.metadata.value, tableName);
    }
  };

  initialLoad = async (metadata: Metadata, tableName: string): Promise<void> => {
    const tmetadata = createTableMetadata(metadata, tableName, this.tableHrefFn);
    this.lastQuery = {
      tmetadata,
      view: makeTableView({
        columns: []
      }),
      query: {
        table: tmetadata.table.name,
        columns: [],
        query: makeTableQuery({
          offset: 0,
          count: this.pageSize
        })
      }
    };
    await this.loadRows();
  };

  @action
  loadRows = async (): Promise<void> => {
    if (this.lastQuery !== null) {
      const tmetadata = this.lastQuery.tmetadata;
      this.rowsLoading = true;
      this.lastQuery.query.query.filter = this.lastQuery.view.filter;
      this.lastQuery.query.query.sorting = this.lastQuery.view.sorting;
      const dbrows = await this.service.adminQuery(this.lastQuery.query);
      const tsrows = dbrows.items.map(dbrow => {
        const id = dbrow.id;
        const value = tmetadata.jsonBinding.fromJsonE(dbrow.value);
        return { id, value };
      });
      this.loadedRows = {
        items: tsrows,
        current_offset: dbrows.current_offset,
        total_size: dbrows.total_size
      };
      this.rowsLoading = false;
    }
  };

  @action
  setView = async (view: TableView): Promise<void> => {
    if (this.lastQuery !== null) {
      this.lastQuery.view = view;
      await this.loadRows();
    }
    return;
  };

  // @computed
  getTableView = (): TableView | null => {
    return this.lastQuery && this.lastQuery.view;
  };

  @action
  create = async (tmetadata: TableMetadata, tsrow: TSRow): Promise<void> => {
    const dbresult = await this.service.adminCreate({
      table: tmetadata.table.name,
      values: tmetadata.jsonBinding.toJson(tsrow) as DbRow
    });
    await this.updateAfterDbChange(dbresult);
  };

  @action
  update = async (tmetadata: TableMetadata, tsrow: WithDbId<TSRow>): Promise<void> => {
    const dbresult = await this.service.adminUpdate({
      table: tmetadata.table.name,
      values: {
        id: tsrow.id,
        value: tmetadata.jsonBinding.toJson(tsrow.value) as DbRow
      }
    });
    await this.updateAfterDbChange(dbresult);
  };

  @action
  delete = async (tmetadata: TableMetadata, id: DbKey<TSRow>): Promise<void> => {
    const dbresult = await this.service.adminDelete({
      table: tmetadata.table.name,
      id
    });
    await this.updateAfterDbChange(dbresult);
  };

  updateAfterDbChange = async (dbresult: DbResult<Unit>): Promise<void> => {
    if (dbresult.kind === "ok") {
      await this.loadRows();
    } else {
      runInAction(() => {
        this.pendingDbError = dbresult.value;
      });
    }
  }

  //  @computed
  getLoadedRows = (): WithDbId<TSRow>[] => {
    if (this.loadedRows) {
      return this.loadedRows.items;
    }
    return [];
  };

  //  @computed
  getTables = (): Loading<Table[]> => {
    return mapLoading(m => {
      const tables: Table[] = [];
      m.tableNames.forEach(tn => {
        const table = m.tableMap[tn];
        if (table !== undefined) {
          tables.push(table);
        }
      });
      return tables;
    }, this.metadata);
  };

  //  @computed
  canPageBack = (): boolean => {
    return !this.rowsLoading && this.loadedRows !== null && this.loadedRows.current_offset > 0;
  };

  @action
  pageBack = async (): Promise<void> => {
    if (this.canPageBack() && this.lastQuery !== null) {
      this.lastQuery.query.query.offset -= this.pageSize;
      if (this.lastQuery.query.query.offset < 0) {
        this.lastQuery.query.query.offset = 0;
      }
    }
    await this.loadRows();
  };

  //  @computed
  canPageForward = (): boolean => {
    return (
      !this.rowsLoading &&
      this.loadedRows !== null &&
      this.loadedRows.current_offset + this.loadedRows.items.length < this.loadedRows.total_size
    );
  };

  @action
  pageForward = async (): Promise<void> => {
    if (this.canPageForward() && this.lastQuery !== null) {
      this.lastQuery.query.query.offset += this.pageSize;
    }
    await this.loadRows();
  };

  getActions() {
    if (this.actions.length === 0 && this.metadata.kind === "ready") {
      const ctx: ActionContext = {
        resolver: this.appDeclResolver,
        customize: appCustomize(this.metadata.value, this.tableHrefFn)
      };
      this.actions = this.actionFactories.map(af => {
        return af(ctx);
      });
    }
    return this.actions;
  }

  getTablePageState(): TablePageState {
    return {
      table: this.tableName || "",
      tmetadata: this.lastQuery? {kind:"ready", value:this.lastQuery.tmetadata} : {kind:"loading"},
      tableView: this.lastQuery? this.lastQuery.view: null,
      loadedRows: this.loadedRows,
      rowsLoading: this.rowsLoading,
      canPageBack: this.canPageBack(),
      canPageForward: this.canPageForward(),
      pendingDbError: this.pendingDbError,
    };
  }

  @action
  onTablePageEvent(ev: TablePageEvent) {
    switch (ev.kind) {
      case 'pageback': void this.pageBack(); break;
      case 'pageforward': void this.pageForward(); break;
      case 'reload': void this.loadRows(); break;
      case 'cleardberror': this.pendingDbError = null; break;
      case 'settableview':
        if (this.lastQuery) {
          this.lastQuery.view = ev.view;
        }
        break;
      case 'create': void this.create(ev.tmetadata, ev.value); break;
      case 'update': void this.update(ev.tmetadata, ev.value); break;
      case 'delete': void this.delete(ev.tmetadata, ev.id); break;
      default:
        assertNever(ev, "unhandled event type");
    }
  }
}

export interface Metadata {
  tableNames: string[];
  tableMap: { [name: string]: Table | undefined };
  resolver: DeclResolver;
}

export type Loading<T> = { kind: "loading" } | { kind: "ready"; value: T };

export function mapLoading<A, B>(fn: (a: A) => B, la: Loading<A>): Loading<B> {
  if (la.kind === "loading") {
    return la;
  }
  return { kind: "ready", value: fn(la.value) };
}

export interface TableMetadata {
  table: Table;
  resolver: DeclResolver;
  veditor: VEditor<TSRow, unknown, unknown>;
  jsonBinding: JsonBinding<TSRow>;
  customize: Customize;
}

export function createTableMetadata(
  metadata: Metadata,
  tableName: string,
  tableHrefFn: TableHrefFn
): TableMetadata {
  const table = metadata.tableMap[tableName];
  if (table === undefined) {
    throw new Error("Unknown table " + tableName);
  }
  const tableScopedDecl = scopedDeclFromTable(table);
  const tableScopedName = {
    moduleName: tableScopedDecl.moduleName,
    name: tableScopedDecl.decl.name
  };
  const tableTypeExpr: ATypeExpr<TSRow> = {
    value: { typeRef: { kind: "reference", value: tableScopedName }, parameters: [] }
  };
  function resolver(scopedName: adlast.ScopedName): adlast.ScopedDecl {
    if (scopedNamesEqual(scopedName, tableScopedName)) {
      return tableScopedDecl;
    } else {
      return metadata.resolver(scopedName);
    }
  }
  const customize = appCustomize(metadata, tableHrefFn);
  const veditor: VEditor<TSRow, unknown, unknown> = createVEditor(
    tableTypeExpr,
    resolver,
    customize
  );
  const jsonBinding = createJsonBinding(resolver, tableTypeExpr);
  return {
    table,
    resolver: metadata.resolver,
    veditor,
    jsonBinding,
    customize
  };
}

export const SYS_CUSTOMIZE = sysCustomize({
  dateFormat: DDSMMSYYYY_FORMAT
});

function appCustomize(metadata: Metadata, tableHrefFn: TableHrefFn): Customize {
  return {
    getCustomVEditor: (ctx: CustomContext) => {
      const dbKeyTableName = getDbKeyTableName(ctx.typeExpr, metadata);
      if (dbKeyTableName !== null) {
        return dbKeyVEditor({
          tableName: dbKeyTableName,
          tableHref: tableHrefFn(dbKeyTableName)
        });
      }
      return SYS_CUSTOMIZE.getCustomVEditor(ctx);
    },
    getCustomField: (ctx: CustomContext) => {
      const dbKeyTableName = getDbKeyTableName(ctx.typeExpr, metadata);
      if (dbKeyTableName !== null) {
        return dbKeyFieldFns(dbKeyTableName);
      }
      return SYS_CUSTOMIZE.getCustomField(ctx);
    }
  };
}

// Return the associated table name if the typeExpression is DbKey<T>, otherwise
// return null
function getDbKeyTableName(typeExpr: adlast.TypeExpr, metadata: Metadata): string | null {
  let tableName: string | null = null;
  if (typeExpr.typeRef.kind === "reference" && scopedNamesEqual(typeExpr.typeRef.value, snDbKey)) {
    // Custom veditor for a db key
    if (typeExpr.parameters[0].typeRef.kind === "reference") {
      const sn = typeExpr.parameters[0].typeRef.value;
      for (const tn of metadata.tableNames) {
        const table = metadata.tableMap[tn];
        if (table && table.declModuleName === sn.moduleName && table.declName === sn.name) {
          tableName = table.name;
          break;
        }
      }
    }
  }
  return tableName;
}

function scopedDeclFromTable(table: Table): adlast.ScopedDecl {
  const struct = adlast.makeStruct({
    typeParams: [],
    fields: table.columns.map(tcol => {
      return adlast.makeField({
        name: tcol.name,
        serializedName: tcol.name,
        typeExpr: tcol.typeExpr,
        default: tcol.defaultValue,
        annotations: []
      });
    })
  });
  const decl = adlast.makeDecl({
    name: table.declName,
    version: { kind: "nothing" },
    type_: { kind: "struct_", value: struct },
    annotations: []
  });
  return {
    moduleName: "__LOCAL__",
    decl
  };
}

export interface TActionData<I, O> {
  method: string;
  path: string;
  description: string;
  veditorReq: VEditor<I, unknown, unknown>;
  jsonBindingReq: JsonBinding<I>;
  call(req: I): Promise<O>;
  veditorResp: VEditor<O, unknown, unknown>;
  jsonBindingResp: JsonBinding<O>;
}

export type ActionData = TActionData<unknown, unknown>;

export interface ActionContext {
  resolver: DeclResolver;
  customize: Customize;
}

export type ActionFactory = (ctx: ActionContext) => ActionData;

interface PostFn<I, O> {
  description(): string;
  rtype: common_http.HttpPost<I, O>;
  call(req: I): Promise<O>;
}

export function makePostAction<I, O>(details: PostFn<I, O>): ActionFactory {
  return (ctx: ActionContext) => {
    const veditorReq: VEditor<I, unknown, unknown> = createVEditor(
      details.rtype.reqType,
      ctx.resolver,
      ctx.customize
    );
    const jsonBindingReq = createJsonBinding(ctx.resolver, details.rtype.reqType);
    const veditorResp: VEditor<O, unknown, unknown> = createVEditor(
      details.rtype.respType,
      ctx.resolver,
      ctx.customize
    );
    const jsonBindingResp = createJsonBinding(ctx.resolver, details.rtype.respType);

    return {
      method: "post",
      path: details.rtype.path,
      description: details.description(),
      veditorReq,
      jsonBindingReq,
      veditorResp,
      jsonBindingResp,
      call: details.call
    };
  };
}
