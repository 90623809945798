
import {Property} from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { computed, reaction } from 'mobx';
import { DbKey, WithDbId } from '../../../adl-gen/common/db';
import { Service } from '../../../service/service';
import { makeFieldComparison, makeFieldPredicate, makeTableQuery, makeValueOrFunction } from '../../../adl-gen/common/tabular';
import { Lease } from '../../../adl-gen/whistle/propte/lease';

export type SelectorOption = {
  key: string;
  text: string;
  value: DbKey<Lease>
};

export class LeaseStore {
  constructor(private service: Service, private property: Updating<WithDbId<Property>>) {
    reaction(()=>this.property.clone(), (p)=>{
      console.log("LeaseStore reaction to property", p);
    });
  }

  @computed get curProperty() : WithDbId<Property> {
    return this.property.value!;
  }

  @computed get leases() : Updating<Map<DbKey<Lease>, Lease>> {
    return this.property.then(wp=>{
      return Updating.ofPromise(this.fetchLeases(wp.id))
    });
  }

  private async fetchLeases(property_id: DbKey<Property>) : Promise<Map<DbKey<Lease>, Lease>> {
    const pag = await this.service.queryLease(makeTableQuery({
      filter: makeFieldPredicate('equalTo', makeFieldComparison({field: 'property_id', value: makeValueOrFunction('value',property_id)}))
    }));
    const map = new Map<DbKey<Lease>, Lease>(pag.items.map(wid=>{
      return [wid.id, wid.value];
    }));
    return map;
  }

  @computed get selectorOptions() : Updating<SelectorOption[]> {
    return this.leases.map(m=>{
      const selOpts : SelectorOption[] = Array.from(m.entries()).map(entry=>{
        const [key,prop] = entry;
        return {
          key,
          text: prop.name,
          value: key
        };
      });
      return selOpts;
    });
  }
};
