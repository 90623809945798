import { Updating } from '../../../models/updating';
import { DbKey, WithDbId } from '../../../adl-gen/common/db';
import { computed, reaction } from 'mobx';
import { LeaseTurnoverScenario } from '../../../adl-gen/whistle/propte/lease';

export class LeaseTurnoverScenarioSelectionStore {
  leaseTurnoverScenarioSelection: Updating<Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario>> = new Updating();

  @computed get leaseTurnoverScenarioSelectionSingle() : Updating<WithDbId<LeaseTurnoverScenario>> {
    return this.leaseTurnoverScenarioSelection.then(vals=>{
      const entries = Array.from(vals.entries());
      if(entries.length === 0) {
        return new Updating();
      }
      const x = entries[0];
      return new Updating({
        id: x[0],
        value: x[1]
      });
    });
  }
};

