
import {Property} from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { ObservableMap, computed } from 'mobx';
import { DbKey } from '../../../adl-gen/common/db';
import { Service } from '../../../service/service';
import { makeTableQuery } from '../../../adl-gen/common/tabular';

export type SelectorOption = {
  key: string;
  text: string;
  value: DbKey<Property>
};

export class PropertyStore {
  properties: Updating<Map<DbKey<Property>, Property>> = new Updating();

  constructor(private service: Service) {
  }

  update() {
    this.properties.update(this._update());
  }

  private async _update() : Promise<Map<DbKey<Property>, Property>> {
    const pag = await this.service.queryProperty(makeTableQuery({}));
    const map = new Map<DbKey<Property>, Property>(pag.items.map(wid=>{
      return [wid.id, wid.value];
    }));
    return map;
  }

  @computed get selectorOptions() : Updating<SelectorOption[]> {
    return this.properties.map(m=>{
      const selOpts : SelectorOption[] = Array.from(m.entries()).map(entry=>{
        const [key,prop] = entry;
        return {
          key,
          text: prop.name,
          value: key
        };
      });
      return selOpts;
    });
  }
};
