import { observer } from "mobx-react";
import * as React from "react";
import { PropteStore } from "../../../stores/propte-store";
import { ScenarioSelector } from "../../components/scenarioSelector/scenarioSelector";
import { ScenarioTableCombined } from "../../components/scenarioTables/scenarioTableCombined";
import { TurnoverSummaryTable } from "../../components/turnoverSummaryTable/turnoverSummaryTable";

export interface TurnoverSummaryExportPageProps {
  propteStore: PropteStore;
}

@observer
export class TurnoverSummaryExportPage extends React.Component<TurnoverSummaryExportPageProps> {
  /** Renders the page */
  render() {
    // TODO: Consider filtering by property and lease (not implemented in backend query either)

    /*
    const propertyStore = this.props.propteStore.propertyStore;
    const propertySelectionStore = this.props.propteStore
      .propertySelectionStore;

    const leaseStore = this.props.propteStore.leaseStore;
    const leaseSelectionStore = this.props.propteStore.leaseSelectionStore;
    */

    return (
      <>
        <TurnoverSummaryTable
          propteStore={this.props.propteStore}
        />
      </>
    );
  }
}
