import { observer } from "mobx-react";
import * as React from "react";

interface LogoutPageProps {
  message: string;
}

@observer
export class LogoutPage extends React.Component<LogoutPageProps> {
  render() {
    return <p>You have been logged out </p>;
  }
}
