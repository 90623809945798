import { observer } from "mobx-react";
import * as React from "react";
import { PropteStore } from "../../../stores/propte-store";
import { TenancySchedule } from "../../components/tenancySchedule/tenancySchedule";

export interface TenancySchedulePageProps {
  propteStore: PropteStore;
}

/**
 *
 */
@observer
export class TenancySchedulePage extends React.Component<TenancySchedulePageProps> {
  /** Renders the page */
  render() {
    const propertyStore = this.props.propteStore.propertyStore;
    const propertySelectionStore = this.props.propteStore
      .propertySelectionStore;
    const tenancyScheduleStore = this.props.propteStore.tenancyScheduleStore;

    return (
      <TenancySchedule
        propertyStore={propertyStore}
        tenancyScheduleStore={tenancyScheduleStore}
        propertySelectionStore={propertySelectionStore}
      />
    );
  }
}
