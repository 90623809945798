import { FieldFns } from "@hx/fields";
import * as React from "react";
import { Form, Input, Label, TextArea } from "semantic-ui-react";

import { uniqueId } from "../adl-tools-helpers";

// A text field type that takes a record of functions to
// parameterize its operation:
//
//    toText   : convert a js value to the text content
//    validate : validate the text content returning a validate
//               error message on failure, or null on success
//    fromText : convert the text content back into a js value
//    equals   : compare two js values for this field

export interface AdlFieldProps {
  disabled: boolean;
  text: string;
  fieldfns: FieldFns<unknown>;
  type?: string;
  onChange(event: string): void;
  autoFocus?: boolean;
}

interface AdlFieldState {
  // We need a per-field id in order to tie an (optional) datalist to the
  // input field.
  id: string;
};

export class AdlField extends React.Component<AdlFieldProps,AdlFieldState> {

  constructor(props: AdlFieldProps) {
    super(props);
    this.state = {
      id: uniqueId("field_"),
    };
  }

  render() {
    const validationError = this.props.fieldfns.validate(this.props.text);
    const errlabel = validationError ? <Label color="red">{validationError}</Label> : null;

    if (this.props.fieldfns.rows > 1) {
      return this.renderTextArea(errlabel);
    } else {
      return this.renderField(errlabel);
    }
  }

  renderField(errlabel: JSX.Element | null) {
    const opts = {
      disabled: this.props.disabled,
      style: { width: this.props.fieldfns.width + "em" },
      autoFocus: this.props.autoFocus,
      error: false,
      list: ""
    };
    if (errlabel !== null) {
      opts.error = true;
    }
    let datalist: JSX.Element | null = null;
    if (this.props.fieldfns.datalist) {
      // tslint:disable-next-line: no-string-literal
      opts.list = this.state.id;
      datalist = (
        <datalist id={this.state.id}>
          {this.props.fieldfns.datalist.map((value, i) => (
            <option key={i} value={value} />
          ))}
        </datalist>
      );
    }
    return (
      <Form.Field>
        <Input
          type={this.props.type || "text"}
          value={this.props.text}
          onChange={this.onChange}
          onFocus={this.onFocus}
          {...opts}
        />
        {datalist}
        {errlabel}
      </Form.Field>
    );
  }

  renderTextArea(errlabel: JSX.Element | null) {
    return (
      <Form>
        <TextArea
          value={this.props.text}
          rows={this.props.fieldfns.rows}
          onChange={this.onChange}
          style={{ width: this.props.fieldfns.width + "em" }}
          disabled={this.props.disabled}
        />
        {errlabel}
      </Form>
    );
  }

  // tslint:disable-next-line: no-any
  onChange = (event: any) => {
    this.props.onChange(event.target.value);
  };

  // tslint:disable-next-line: no-any
  onFocus = (event: any) => {
    if (this.props.autoFocus) {
      event.target.select();
    }
  };
}
