import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Dropdown, Grid, Icon, DropdownProps, Button, Menu } from "semantic-ui-react";
import { ScenarioStore } from "./scenarioStore";
import { ScenarioSelectionStore } from "./scenarioSelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { Scenario } from "../../../adl-gen/whistle/propte/scenario";

/** Props for the component */
export interface ScenarioSelectorProps {
  scenarioStore: ScenarioStore,
  scenarioSelectionStore: ScenarioSelectionStore
}

/**
 * Component for Selecting one or more properties (Whistle realestate properties)
 */
@observer
export class ScenarioSelector extends React.Component<ScenarioSelectorProps> {

  /** Renders the component */
  render() {
    const scenarioStore = this.props.scenarioStore;
    const options = scenarioStore.selectorOptions.getValue() || [];

    const empty = options.length===0;
    const updating = scenarioStore.selectorOptions.isUpdating();

    /*
    <Grid stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column width={14}>
            <Header icon>
              <Icon name='search' />
              Scenarios
            </Header>


          </Grid.Column>
        </Grid.Row>
      </Grid>
    */

    return <Menu fluid>
      <Menu.Item>
      Scenarios
      </Menu.Item>
        <Dropdown
          placeholder='Scenarios'
          multiple
          search
          selection
          scrolling
          clearable
          options={options}
          loading={empty || updating}
          onChange={this.onChange}
          value={this.getSelection()}
        />

      <Menu.Item position={'right'}>
        <Button primary onClick={()=>this.selectAll()}>Select All</Button>
      </Menu.Item>

    </Menu>;
  }
  selectAll(): void {
    const scenarioStore = this.props.scenarioStore;
    const options = scenarioStore.selectorOptions.getValue() || [];
    this.assignSelection(options.map(x=>x.value));
  }

  private getSelection() : DbKey<Scenario>[] {
    const vals = this.props.scenarioSelectionStore.scenarioSelection.getValue();
    if(vals === null) {
      return [];
    }
    return Array.from(vals.keys())
  }

  private assignSelection = (dbKeys: DbKey<Property>[])=>{
    const x = this.props.scenarioStore.scenarios.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>dbKeys.find(i=>i===kv[0]));
      return new Map<DbKey<Scenario>, Scenario>(entries);
    });
    this.props.scenarioSelectionStore.scenarioSelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    const dbKeys : DbKey<Property>[] = data.value as string[];
    this.assignSelection(dbKeys);
  }
}
