/** A typesafe route */

export type Route =
  | { route: "main" }
  | { route: "property-data" }
  | { route: "tenancy-schedule" }
  | { route: "turnover-summary" }
  | { route: "scenario-analysis" }
  | { route: "scenario-graphs" }
  | { route: "scenario-tables" }
  | { route: "scenario-tables-export" }
  | { route: "message-board" }
  | { route: "login" }
  | { route: "logout" }
  | { route: "admin" }
  | { route: "admin-table"; table: string };

export function routeFromPath(path: string): Route | undefined {
  if (path.startsWith("/")) {
    const parts = path
      .substr(1)
      .split("/")
      .map(decodeURIComponent);
    if (parts.length === 1 && parts[0] === "") {
      return { route: "main" };
    }
    if (parts.length === 1 && parts[0] === "message-board") {
      return { route: "message-board" };
    }
    if (parts.length === 1 && parts[0] === "property-data") {
      return { route: "property-data" };
    }
    if (parts.length === 1 && parts[0] === "tenancy-schedule") {
      return { route: "tenancy-schedule" };
    }
    if (parts.length === 1 && parts[0] === "turnover-summary") {
      return { route: "turnover-summary" };
    }
    if (parts.length === 1 && parts[0] === "scenario-analysis") {
      return { route: "scenario-analysis" };
    }
    if (parts.length === 1 && parts[0] === "scenario-tables") {
      return { route: "scenario-tables" };
    }
    if (parts.length === 1 && parts[0] === "scenario-graphs") {
      return { route: "scenario-graphs" };
    }
    if (parts.length === 1 && parts[0] === "scenario-tables-export") {
      return { route: "scenario-tables-export" };
    }
    if (parts.length === 1 && parts[0] === "login") {
      return { route: "login" };
    }
    if (parts.length === 1 && parts[0] === "logout") {
      return { route: "logout" };
    }
    if (parts.length === 1 && parts[0] === "admin") {
      return { route: "admin" };
    }
    if (parts.length === 2 && parts[0] === "admin-table") {
      return { route: "admin-table", table: parts[1] };
    }
  }
  return undefined;
}

export function pathFromRoute(route: Route): string {
  switch (route.route) {
    case "main":
      return "/";
    case "property-data":
      return "/property-data";
    case "tenancy-schedule":
      return "/tenancy-schedule";
    case "turnover-summary":
      return "/turnover-summary";
    case "scenario-analysis":
      return "/scenario-analysis";
    case "scenario-tables":
      return "/scenario-tables";
    case "scenario-graphs":
      return "/scenario-graphs";
    case "scenario-tables-export":
      return "/scenario-tables-export";
    case "message-board":
      return "/message-board";
    case "login":
      return "/login";
    case "logout":
      return "/logout";
    case "admin":
      return "/admin";
    case "admin-table":
      return "/admin-table/" + route.table;
  }
}
