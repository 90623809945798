
import { Property } from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { DbKey, WithDbId } from '../../../adl-gen/common/db';
import { computed, reaction } from 'mobx';

export class PropertySelectionStore {
  propertySelection: Updating<Map<DbKey<Property>, Property>> = new Updating();

  @computed get propertySelectionSingle() : Updating<WithDbId<Property>> {
    return this.propertySelection.then(vals=>{
      const entries = Array.from(vals.entries());
      if(entries.length === 0) {
        return new Updating();
      }
      const x = entries[0];
      return new Updating({
        id: x[0],
        value: x[1]
      });
    });
  }
};

