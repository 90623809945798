import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

const style = require("./icon-font/webfont.css");

/** All icons for easy iterating */
export const ALL_ICONS: {
  name: string;
  component: React.FunctionComponent;
}[] = [];

function makeIconComponent(name: string): React.FunctionComponent {
  const iconComponent = observer(({}) => {
    return <span className={classnames(style[`icon-${name}`], style.icon)} />;
  });
  ALL_ICONS.push({
    name,
    component: iconComponent
  });
  return iconComponent;
}

export const DropdownIcon: React.FunctionComponent = makeIconComponent(
  "dropdown"
);
export const EditIcon: React.FunctionComponent = makeIconComponent("edit");
export const MenuIcon: React.FunctionComponent = makeIconComponent("menu");
