
import { Updating } from '../../../models/updating';

import { accountCategories } from '../../../models/accountCategories';
import { AccountCategory } from '../../../adl-gen/whistle/propte/category';
import { capitalCase } from 'change-case';

export type SelectorOption = {
  key: string;
  text: string;
  value: string;
  //value: AccountCategory
};

export class AccountCategoryStore {
  selectorOptions : SelectorOption[];

  constructor() {
    const selectorOptions : SelectorOption[] = [];

    // generated from apiclient  npx ts-node ./tools/generateFiniteTypes.ts
    for(const key of Object.keys(accountCategories)) {
      //const acCat : AccountCategory = accountCategories[key];

      const text = key.split("|").map(x=>x.trim()).map(x=>capitalCase(x)).join(" | ");
      const value = key;

      selectorOptions.push({
        key, text, value
      });
    }

    selectorOptions.push({
      key: "accum. profit/loss", text: "accum. profit/loss", value: "accum. profit/loss"
    });

    this.selectorOptions = selectorOptions;
  }

  isProfitAndLossCategory(opt: SelectorOption) : boolean {
    return opt.key.startsWith('revenue') || opt.key.startsWith('expense');
  }

  isBalanceSheetCategory(opt: SelectorOption) : boolean {
    return !this.isProfitAndLossCategory(opt);
  }

  getProfitAndLossCategories() : SelectorOption[] {
    return this.selectorOptions.filter(opt=>this.isProfitAndLossCategory(opt));
  }

  getBalanceSheetCategories() : SelectorOption[] {
    return this.selectorOptions.filter(opt=>this.isBalanceSheetCategory(opt));
  }

  /// Find categories matching the prefix - only categories in detail up to a given depth
  getByPrefix(prefix: string) : SelectorOption[] {
    const pfTrimmed = prefix.trim();
    return this.selectorOptions.filter(opt=>opt.key.startsWith(pfTrimmed));
  }
};
