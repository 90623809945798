import { observable, runInAction } from "mobx";

import { Message } from "../adl-gen/whistle/propte/api";
import { Loading } from "../models/loading2";
import { Service } from "../service/service";
import { Event, State } from "../ui/page/message-board/message-board-page";

/**
 * Data and actions for the message board
 */
export class MessageBoardStore {
  /** Response from the server from saying hello */
  @observable messages: Loading<Message[]> = {kind:"loading"};

  constructor(
    /** Service to interact with the server */
    private readonly service: Pick<Service, "newMessage" | "recentMessages">
  ) {
    // tslint:disable-next-line: no-floating-promises
    this.loadMessages();
  }

  loadMessages = async () => {
    const messages = await this.service.recentMessages({offset:0,count:20});
    runInAction( () => {
      this.messages = {kind:"value", value:messages.items};
    });
  }

  onEvent = async (event: Event): Promise<void> => {
    switch(event.kind) {
      case "new-message":
        await this.service.newMessage({
          message: event.message
        });
        await this.loadMessages();
    }
  }

  getViewState(): State {
    return {
      messages: this.messages
    };
  }
}
