import { LocalDate } from "../../../adl-gen/common"
import { Updating } from "../../../models/updating"
import { DbTenancySchedule } from "../../../adl-gen/whistle/propte/views";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { PropertySelectionStore } from "../propertySelector/propertySelectionStore";
import { computed } from "mobx";
import { Service } from "../../../service/service";

/// Provide the tenancy schedule of the selected properties
export class TenancyScheduleStore {
  date: Updating<LocalDate> = new Updating();

  constructor(
    private service : Service,
    private propertySelectionStore : PropertySelectionStore) {
  }

  @computed get tenancySchedule() : Updating<Map<DbKey<Property>, DbTenancySchedule[]>> {
    return this.propertySelectionStore.propertySelection.then(properties=>{
      return this.date.then(date=>{
        const propertyIds = Array.from(properties.keys());
        return Updating.ofPromise(this.loadTenancySchedules(propertyIds, date));
      });
    });
  }

  private async loadTenancySchedules(propertyIds : DbKey<Property>[], atDate: LocalDate) : Promise<Map<DbKey<Property>, DbTenancySchedule[]>> {
    const result : Map<DbKey<Property>, DbTenancySchedule[]> = new Map();
    await Promise.all(propertyIds.map((propertyId) => this.loadTenancySchedule(propertyId, atDate, result)));
    return result;
  }

  private async loadTenancySchedule(propertyId: DbKey<Property>, atDate: LocalDate, dest: Map<DbKey<Property>, DbTenancySchedule[]>) : Promise<void> {
    const tsrows = await this.service.dbTenancySchedule({
      propertyId,
      atDate
    });
    dest.set(propertyId, tsrows);
    return;
  }
}
