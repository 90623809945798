
import { Updating } from '../../../models/updating';
import { computed } from 'mobx';
import { DbKey } from '../../../adl-gen/common/db';
import { Service } from '../../../service/service';
import { makeTableQuery } from '../../../adl-gen/common/tabular';
import { LeaseTurnoverScenario } from '../../../adl-gen/whistle/propte/lease';

export type SelectorOption = {
  key: string;
  text: string;
  value: DbKey<LeaseTurnoverScenario>
};

export class LeaseTurnoverScenarioStore {
  properties: Updating<Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario>> = new Updating();

  constructor(private service: Service) {
  }

  update() {
    this.properties.update(this._update());
  }

  private async _update() : Promise<Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario>> {
    const pag = await this.service.queryLeaseTurnoverScenario(makeTableQuery({}));
    const map = new Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario>(pag.items.map(wid=>{
      return [wid.id, wid.value];
    }));
    return map;
  }

  @computed get selectorOptions() : Updating<SelectorOption[]> {
    return this.properties.map(m=>{
      const selOpts : SelectorOption[] = Array.from(m.entries()).map(entry=>{
        const [key,prop] = entry;
        return {
          key,
          text: prop.name,
          value: key
        };
      });
      return selOpts;
    });
  }
};
