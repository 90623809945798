import { LoginReq,  LoginResp,  NewMessageReq,  RecentMessagesReq,  Message,  UserProfile,  UserReq } from "../adl-gen/whistle/propte/api";
import { Unit,  Paginated } from "../adl-gen/common";
import { AppUserId,  AppUser } from "../adl-gen/whistle/propte/db";
import { WithDbId,  DbKey } from "../adl-gen/common/db";
import { TableQuery } from "../adl-gen/common/tabular";
import { Property,  PropertyId } from "../adl-gen/whistle/propte/property";
import { LeaseTurnoverScenario,  LeaseTurnoverScenarioId,  LeaseTurnover,  LeaseTurnoverId,  Lease,  LeaseId,  LeaseContract,  LeaseContractId,  LeaseEvalRequest,  LeaseEvalOutput,  LeaseTurnoverSummaryQuery } from "../adl-gen/whistle/propte/lease";
import { Scenario,  ScenarioId,  ScenarioExecutionResult } from "../adl-gen/whistle/propte/scenario";
import { Contact,  ContactId } from "../adl-gen/whistle/propte/contact";
import { Journal,  Account,  JournalEntry,  JournalEntryAccount,  JournalBalance,  JournalBalanceAccount,  JournalDelta,  JournalDeltaAccount } from "../adl-gen/whistle/propte/accounting";
import { JournalEntriesView,  DbTenancyScheduleReq,  DbTenancySchedule,  ScenarioTenancyScheduleReq,  ScenarioTenancySchedule,  ScenarioMetrics,  ScenarioPeriodMetricsReq,  ScenarioPeriodMetricRow,  ScenarioInstantMetricsReq,  ScenarioInstantMetricRow } from "../adl-gen/whistle/propte/views";
import { Service } from "./service";
import { HttpFetch } from "./http";
import { DeclResolver } from "../adl-gen/runtime/adl";
import { TokenManager } from "./token-manager";
import { HttpServiceError } from "./http-service-error";
import { HttpServiceBase, PostFn, GetFn, GetStreamFn } from "./http-service-base";

export class HttpService extends HttpServiceBase implements Service {
  postLogin: PostFn<LoginReq, LoginResp>;
  postNewMessage: PostFn<NewMessageReq, Unit>;
  postRecentMessages: PostFn<RecentMessagesReq, Paginated<Message>>;
  getWhoAmI: GetFn<UserProfile>;
  postCreateUser: PostFn<UserReq, AppUserId>;
  postUpdateUser: PostFn<WithDbId<UserReq>, Unit>;
  postDeleteUser: PostFn<AppUserId, Unit>;
  postQueryUsers: PostFn<TableQuery, Paginated<WithDbId<AppUser>>>;
  postUpsertProperty: PostFn<Property, PropertyId>;
  postCreateProperty: PostFn<Property, PropertyId>;
  postUpdateProperty: PostFn<WithDbId<Property>, Unit>;
  postDeleteProperty: PostFn<PropertyId, Unit>;
  postQueryProperty: PostFn<TableQuery, Paginated<WithDbId<Property>>>;
  postUpsertLeaseTurnoverScenario: PostFn<LeaseTurnoverScenario, LeaseTurnoverScenarioId>;
  postCreateLeaseTurnoverScenario: PostFn<LeaseTurnoverScenario, LeaseTurnoverScenarioId>;
  postUpdateLeaseTurnoverScenario: PostFn<WithDbId<LeaseTurnoverScenario>, Unit>;
  postDeleteLeaseTurnoverScenario: PostFn<LeaseTurnoverScenarioId, Unit>;
  postQueryLeaseTurnoverScenario: PostFn<TableQuery, Paginated<WithDbId<LeaseTurnoverScenario>>>;
  postUpsertLeaseTurnover: PostFn<LeaseTurnover, LeaseTurnoverId>;
  postCreateLeaseTurnover: PostFn<LeaseTurnover, LeaseTurnoverId>;
  postUpdateLeaseTurnover: PostFn<WithDbId<LeaseTurnover>, Unit>;
  postDeleteLeaseTurnover: PostFn<LeaseTurnoverId, Unit>;
  postQueryLeaseTurnover: PostFn<TableQuery, Paginated<WithDbId<LeaseTurnover>>>;
  postUpsertScenario: PostFn<Scenario, ScenarioId>;
  postCreateScenario: PostFn<Scenario, ScenarioId>;
  postUpdateScenario: PostFn<WithDbId<Scenario>, Unit>;
  postDeleteScenario: PostFn<ScenarioId, Unit>;
  postQueryScenario: PostFn<TableQuery, Paginated<WithDbId<Scenario>>>;
  postExecuteScenario: PostFn<ScenarioId, ScenarioExecutionResult>;
  postExecuteScenarioDeferred: PostFn<ScenarioId, ScenarioExecutionResult>;
  postCheckScenario: PostFn<ScenarioId, ScenarioExecutionResult>;
  postUpsertContact: PostFn<Contact, ContactId>;
  postCreateContact: PostFn<Contact, ContactId>;
  postUpdateContact: PostFn<WithDbId<Contact>, Unit>;
  postDeleteContact: PostFn<ContactId, Unit>;
  postQueryContact: PostFn<TableQuery, Paginated<WithDbId<Contact>>>;
  postUpsertLease: PostFn<Lease, LeaseId>;
  postCreateLease: PostFn<Lease, LeaseId>;
  postUpdateLease: PostFn<WithDbId<Lease>, Unit>;
  postDeleteLease: PostFn<LeaseId, Unit>;
  postQueryLease: PostFn<TableQuery, Paginated<WithDbId<Lease>>>;
  postUpsertLeaseContract: PostFn<LeaseContract, LeaseContractId>;
  postCreateLeaseContract: PostFn<LeaseContract, LeaseContractId>;
  postUpdateLeaseContract: PostFn<WithDbId<LeaseContract>, Unit>;
  postDeleteLeaseContract: PostFn<LeaseContractId, Unit>;
  postQueryLeaseContract: PostFn<TableQuery, Paginated<WithDbId<LeaseContract>>>;
  postEvaluateLease: PostFn<LeaseEvalRequest, LeaseEvalOutput>;
  postQueryJournal: PostFn<TableQuery, Paginated<WithDbId<Journal>>>;
  postQueryAccount: PostFn<TableQuery, Paginated<WithDbId<Account>>>;
  postQueryJournalEntry: PostFn<TableQuery, Paginated<WithDbId<JournalEntry>>>;
  postQueryJournalEntryAccount: PostFn<TableQuery, Paginated<WithDbId<JournalEntryAccount>>>;
  postQueryJournalBalance: PostFn<TableQuery, Paginated<WithDbId<JournalBalance>>>;
  postQueryJournalBalanceAccount: PostFn<TableQuery, Paginated<WithDbId<JournalBalanceAccount>>>;
  postQueryJournalDelta: PostFn<TableQuery, Paginated<WithDbId<JournalDelta>>>;
  postQueryJournalDeltaAccount: PostFn<TableQuery, Paginated<WithDbId<JournalDeltaAccount>>>;
  getJournalEntriesView: GetStreamFn<JournalEntriesView>;
  postDbTenancySchedule: PostFn<DbTenancyScheduleReq, DbTenancySchedule[]>;
  postLeaseTurnoverSummaryQuery: PostFn<Unit, LeaseTurnoverSummaryQuery[]>;
  postScenarioTenancySchedule: PostFn<ScenarioTenancyScheduleReq, ScenarioTenancySchedule[]>;
  postScenarioMetrics: PostFn<DbKey<Scenario>, ScenarioMetrics>;
  postScenarioPeriodMetrics: PostFn<ScenarioPeriodMetricsReq, ScenarioPeriodMetricRow[]>;
  postScenarioInstantMetrics: PostFn<ScenarioInstantMetricsReq, ScenarioInstantMetricRow[]>;
  constructor(
    /** Fetcher over HTTP */
    http: HttpFetch,
    /** Base URL of the API endpoints */
    baseUrl: string,
    /** Resolver for ADL types */
    resolver: DeclResolver,
    /** Token manager storing the authentication tokens */
    tokenManager: TokenManager,
    /** Error handler to allow for cross cutting concerns, e.g. authorization errors */
    handleError: (error: HttpServiceError) => void
  ) {
  super(http, baseUrl, resolver, tokenManager, handleError);
  this.postLogin = this.mkPostFn(this.requests.login);
  this.postNewMessage = this.mkPostFn(this.requests.newMessage);
  this.postRecentMessages = this.mkPostFn(this.requests.recentMessages);
  this.getWhoAmI = this.mkGetFn(this.requests.whoAmI);
  this.postCreateUser = this.mkPostFn(this.requests.createUser);
  this.postUpdateUser = this.mkPostFn(this.requests.updateUser);
  this.postDeleteUser = this.mkPostFn(this.requests.deleteUser);
  this.postQueryUsers = this.mkPostFn(this.requests.queryUsers);
  this.postUpsertProperty = this.mkPostFn(this.requests.upsertProperty);
  this.postCreateProperty = this.mkPostFn(this.requests.createProperty);
  this.postUpdateProperty = this.mkPostFn(this.requests.updateProperty);
  this.postDeleteProperty = this.mkPostFn(this.requests.deleteProperty);
  this.postQueryProperty = this.mkPostFn(this.requests.queryProperty);
  this.postUpsertLeaseTurnoverScenario = this.mkPostFn(this.requests.upsertLeaseTurnoverScenario);
  this.postCreateLeaseTurnoverScenario = this.mkPostFn(this.requests.createLeaseTurnoverScenario);
  this.postUpdateLeaseTurnoverScenario = this.mkPostFn(this.requests.updateLeaseTurnoverScenario);
  this.postDeleteLeaseTurnoverScenario = this.mkPostFn(this.requests.deleteLeaseTurnoverScenario);
  this.postQueryLeaseTurnoverScenario = this.mkPostFn(this.requests.queryLeaseTurnoverScenario);
  this.postUpsertLeaseTurnover = this.mkPostFn(this.requests.upsertLeaseTurnover);
  this.postCreateLeaseTurnover = this.mkPostFn(this.requests.createLeaseTurnover);
  this.postUpdateLeaseTurnover = this.mkPostFn(this.requests.updateLeaseTurnover);
  this.postDeleteLeaseTurnover = this.mkPostFn(this.requests.deleteLeaseTurnover);
  this.postQueryLeaseTurnover = this.mkPostFn(this.requests.queryLeaseTurnover);
  this.postUpsertScenario = this.mkPostFn(this.requests.upsertScenario);
  this.postCreateScenario = this.mkPostFn(this.requests.createScenario);
  this.postUpdateScenario = this.mkPostFn(this.requests.updateScenario);
  this.postDeleteScenario = this.mkPostFn(this.requests.deleteScenario);
  this.postQueryScenario = this.mkPostFn(this.requests.queryScenario);
  this.postExecuteScenario = this.mkPostFn(this.requests.executeScenario);
  this.postExecuteScenarioDeferred = this.mkPostFn(this.requests.executeScenarioDeferred);
  this.postCheckScenario = this.mkPostFn(this.requests.checkScenario);
  this.postUpsertContact = this.mkPostFn(this.requests.upsertContact);
  this.postCreateContact = this.mkPostFn(this.requests.createContact);
  this.postUpdateContact = this.mkPostFn(this.requests.updateContact);
  this.postDeleteContact = this.mkPostFn(this.requests.deleteContact);
  this.postQueryContact = this.mkPostFn(this.requests.queryContact);
  this.postUpsertLease = this.mkPostFn(this.requests.upsertLease);
  this.postCreateLease = this.mkPostFn(this.requests.createLease);
  this.postUpdateLease = this.mkPostFn(this.requests.updateLease);
  this.postDeleteLease = this.mkPostFn(this.requests.deleteLease);
  this.postQueryLease = this.mkPostFn(this.requests.queryLease);
  this.postUpsertLeaseContract = this.mkPostFn(this.requests.upsertLeaseContract);
  this.postCreateLeaseContract = this.mkPostFn(this.requests.createLeaseContract);
  this.postUpdateLeaseContract = this.mkPostFn(this.requests.updateLeaseContract);
  this.postDeleteLeaseContract = this.mkPostFn(this.requests.deleteLeaseContract);
  this.postQueryLeaseContract = this.mkPostFn(this.requests.queryLeaseContract);
  this.postEvaluateLease = this.mkPostFn(this.requests.evaluateLease);
  this.postQueryJournal = this.mkPostFn(this.requests.queryJournal);
  this.postQueryAccount = this.mkPostFn(this.requests.queryAccount);
  this.postQueryJournalEntry = this.mkPostFn(this.requests.queryJournalEntry);
  this.postQueryJournalEntryAccount = this.mkPostFn(this.requests.queryJournalEntryAccount);
  this.postQueryJournalBalance = this.mkPostFn(this.requests.queryJournalBalance);
  this.postQueryJournalBalanceAccount = this.mkPostFn(this.requests.queryJournalBalanceAccount);
  this.postQueryJournalDelta = this.mkPostFn(this.requests.queryJournalDelta);
  this.postQueryJournalDeltaAccount = this.mkPostFn(this.requests.queryJournalDeltaAccount);
  this.getJournalEntriesView = this.mkGetStreamFn(this.requests.journalEntriesView);
  this.postDbTenancySchedule = this.mkPostFn(this.requests.dbTenancySchedule);
  this.postLeaseTurnoverSummaryQuery = this.mkPostFn(this.requests.leaseTurnoverSummaryQuery);
  this.postScenarioTenancySchedule = this.mkPostFn(this.requests.scenarioTenancySchedule);
  this.postScenarioMetrics = this.mkPostFn(this.requests.scenarioMetrics);
  this.postScenarioPeriodMetrics = this.mkPostFn(this.requests.scenarioPeriodMetrics);
  this.postScenarioInstantMetrics = this.mkPostFn(this.requests.scenarioInstantMetrics);
  }
      
    /** Login a user */
    async login(req: LoginReq): Promise<LoginResp> {
      return this.postLogin.call(req);
    }
    
    /** Post a message to the noticeboard */
    async newMessage(req: NewMessageReq): Promise<Unit> {
      return this.postNewMessage.call(req);
    }
    
    /** Get recent noticeboard messages */
    async recentMessages(req: RecentMessagesReq): Promise<Paginated<Message>> {
      return this.postRecentMessages.call(req);
    }
    
    /** Gets the logged in user details */
    async whoAmI(): Promise<UserProfile> {
      return this.getWhoAmI.call();
    }
    
    /** Create a new user */
    async createUser(req: UserReq): Promise<AppUserId> {
      return this.postCreateUser.call(req);
    }
    
    /** Update an existing user */
    async updateUser(req: WithDbId<UserReq>): Promise<Unit> {
      return this.postUpdateUser.call(req);
    }
    
    /** Delete an existing user */
    async deleteUser(req: AppUserId): Promise<Unit> {
      return this.postDeleteUser.call(req);
    }
    
    /** Query existing users sorted and filters according to the TableQuery request. */
    async queryUsers(req: TableQuery): Promise<Paginated<WithDbId<AppUser>>> {
      return this.postQueryUsers.call(req);
    }
    
    /** Create or insert property */
    async upsertProperty(req: Property): Promise<PropertyId> {
      return this.postUpsertProperty.call(req);
    }
    
    /** Create Property */
    async createProperty(req: Property): Promise<PropertyId> {
      return this.postCreateProperty.call(req);
    }
    
    /** Update an existing property */
    async updateProperty(req: WithDbId<Property>): Promise<Unit> {
      return this.postUpdateProperty.call(req);
    }
    
    /** Delete an existing property */
    async deleteProperty(req: PropertyId): Promise<Unit> {
      return this.postDeleteProperty.call(req);
    }
    
    /** Query existing property sorted and filtered according to the TableQuery request. */
    async queryProperty(req: TableQuery): Promise<Paginated<WithDbId<Property>>> {
      return this.postQueryProperty.call(req);
    }
    
    /** Create or insert leaseturnoverscenario */
    async upsertLeaseTurnoverScenario(req: LeaseTurnoverScenario): Promise<LeaseTurnoverScenarioId> {
      return this.postUpsertLeaseTurnoverScenario.call(req);
    }
    
    /** Create LeaseTurnoverScenario */
    async createLeaseTurnoverScenario(req: LeaseTurnoverScenario): Promise<LeaseTurnoverScenarioId> {
      return this.postCreateLeaseTurnoverScenario.call(req);
    }
    
    /** Update an existing leaseturnoverscenario */
    async updateLeaseTurnoverScenario(req: WithDbId<LeaseTurnoverScenario>): Promise<Unit> {
      return this.postUpdateLeaseTurnoverScenario.call(req);
    }
    
    /** Delete an existing leaseturnoverscenario */
    async deleteLeaseTurnoverScenario(req: LeaseTurnoverScenarioId): Promise<Unit> {
      return this.postDeleteLeaseTurnoverScenario.call(req);
    }
    
    /** Query existing leaseturnoverscenario sorted and filtered according to the TableQuery request. */
    async queryLeaseTurnoverScenario(req: TableQuery): Promise<Paginated<WithDbId<LeaseTurnoverScenario>>> {
      return this.postQueryLeaseTurnoverScenario.call(req);
    }
    
    /** Create or insert leaseturnover */
    async upsertLeaseTurnover(req: LeaseTurnover): Promise<LeaseTurnoverId> {
      return this.postUpsertLeaseTurnover.call(req);
    }
    
    /** Create LeaseTurnover */
    async createLeaseTurnover(req: LeaseTurnover): Promise<LeaseTurnoverId> {
      return this.postCreateLeaseTurnover.call(req);
    }
    
    /** Update an existing leaseturnover */
    async updateLeaseTurnover(req: WithDbId<LeaseTurnover>): Promise<Unit> {
      return this.postUpdateLeaseTurnover.call(req);
    }
    
    /** Delete an existing leaseturnover */
    async deleteLeaseTurnover(req: LeaseTurnoverId): Promise<Unit> {
      return this.postDeleteLeaseTurnover.call(req);
    }
    
    /** Query existing leaseturnover sorted and filtered according to the TableQuery request. */
    async queryLeaseTurnover(req: TableQuery): Promise<Paginated<WithDbId<LeaseTurnover>>> {
      return this.postQueryLeaseTurnover.call(req);
    }
    
    /** Create or insert scenario */
    async upsertScenario(req: Scenario): Promise<ScenarioId> {
      return this.postUpsertScenario.call(req);
    }
    
    /** Create Scenario */
    async createScenario(req: Scenario): Promise<ScenarioId> {
      return this.postCreateScenario.call(req);
    }
    
    /** Update an existing scenario */
    async updateScenario(req: WithDbId<Scenario>): Promise<Unit> {
      return this.postUpdateScenario.call(req);
    }
    
    /** Delete an existing scenario */
    async deleteScenario(req: ScenarioId): Promise<Unit> {
      return this.postDeleteScenario.call(req);
    }
    
    /** Query existing scenario sorted and filtered according to the TableQuery request. */
    async queryScenario(req: TableQuery): Promise<Paginated<WithDbId<Scenario>>> {
      return this.postQueryScenario.call(req);
    }
    
    /** Execute a scenario (results indicate that it ran OK - will have a different endpoint for getting detailed results from DB) */
    async executeScenario(req: ScenarioId): Promise<ScenarioExecutionResult> {
      return this.postExecuteScenario.call(req);
    }
    
    /** Execute a scenario (check and then run in background to save to DB) */
    async executeScenarioDeferred(req: ScenarioId): Promise<ScenarioExecutionResult> {
      return this.postExecuteScenarioDeferred.call(req);
    }
    
    /** Execute a scenario - check only (dry run) */
    async checkScenario(req: ScenarioId): Promise<ScenarioExecutionResult> {
      return this.postCheckScenario.call(req);
    }
    
    /** Create or insert contact */
    async upsertContact(req: Contact): Promise<ContactId> {
      return this.postUpsertContact.call(req);
    }
    
    /** Create Contact */
    async createContact(req: Contact): Promise<ContactId> {
      return this.postCreateContact.call(req);
    }
    
    /** Update an existing contact */
    async updateContact(req: WithDbId<Contact>): Promise<Unit> {
      return this.postUpdateContact.call(req);
    }
    
    /** Delete an existing contact */
    async deleteContact(req: ContactId): Promise<Unit> {
      return this.postDeleteContact.call(req);
    }
    
    /** Query existing contact sorted and filtered according to the TableQuery request. */
    async queryContact(req: TableQuery): Promise<Paginated<WithDbId<Contact>>> {
      return this.postQueryContact.call(req);
    }
    
    /** Create or insert lease */
    async upsertLease(req: Lease): Promise<LeaseId> {
      return this.postUpsertLease.call(req);
    }
    
    /** Create Lease */
    async createLease(req: Lease): Promise<LeaseId> {
      return this.postCreateLease.call(req);
    }
    
    /** Update an existing lease */
    async updateLease(req: WithDbId<Lease>): Promise<Unit> {
      return this.postUpdateLease.call(req);
    }
    
    /** Delete an existing lease */
    async deleteLease(req: LeaseId): Promise<Unit> {
      return this.postDeleteLease.call(req);
    }
    
    /** Query existing lease sorted and filtered according to the TableQuery request. */
    async queryLease(req: TableQuery): Promise<Paginated<WithDbId<Lease>>> {
      return this.postQueryLease.call(req);
    }
    
    /** Create or insert leasecontract */
    async upsertLeaseContract(req: LeaseContract): Promise<LeaseContractId> {
      return this.postUpsertLeaseContract.call(req);
    }
    
    /** Create LeaseContract */
    async createLeaseContract(req: LeaseContract): Promise<LeaseContractId> {
      return this.postCreateLeaseContract.call(req);
    }
    
    /** Update an existing leasecontract */
    async updateLeaseContract(req: WithDbId<LeaseContract>): Promise<Unit> {
      return this.postUpdateLeaseContract.call(req);
    }
    
    /** Delete an existing leasecontract */
    async deleteLeaseContract(req: LeaseContractId): Promise<Unit> {
      return this.postDeleteLeaseContract.call(req);
    }
    
    /** Query existing leasecontract sorted and filtered according to the TableQuery request. */
    async queryLeaseContract(req: TableQuery): Promise<Paginated<WithDbId<LeaseContract>>> {
      return this.postQueryLeaseContract.call(req);
    }
    
    async evaluateLease(req: LeaseEvalRequest): Promise<LeaseEvalOutput> {
      return this.postEvaluateLease.call(req);
    }
    
    async queryJournal(req: TableQuery): Promise<Paginated<WithDbId<Journal>>> {
      return this.postQueryJournal.call(req);
    }
    
    async queryAccount(req: TableQuery): Promise<Paginated<WithDbId<Account>>> {
      return this.postQueryAccount.call(req);
    }
    
    async queryJournalEntry(req: TableQuery): Promise<Paginated<WithDbId<JournalEntry>>> {
      return this.postQueryJournalEntry.call(req);
    }
    
    async queryJournalEntryAccount(req: TableQuery): Promise<Paginated<WithDbId<JournalEntryAccount>>> {
      return this.postQueryJournalEntryAccount.call(req);
    }
    
    async queryJournalBalance(req: TableQuery): Promise<Paginated<WithDbId<JournalBalance>>> {
      return this.postQueryJournalBalance.call(req);
    }
    
    async queryJournalBalanceAccount(req: TableQuery): Promise<Paginated<WithDbId<JournalBalanceAccount>>> {
      return this.postQueryJournalBalanceAccount.call(req);
    }
    
    async queryJournalDelta(req: TableQuery): Promise<Paginated<WithDbId<JournalDelta>>> {
      return this.postQueryJournalDelta.call(req);
    }
    
    async queryJournalDeltaAccount(req: TableQuery): Promise<Paginated<WithDbId<JournalDeltaAccount>>> {
      return this.postQueryJournalDeltaAccount.call(req);
    }
    
    async journalEntriesView(): Promise<JournalEntriesView[]> {
      return this.getJournalEntriesView.call();
    }
    
    async dbTenancySchedule(req: DbTenancyScheduleReq): Promise<DbTenancySchedule[]> {
      return this.postDbTenancySchedule.call(req);
    }
    
    async leaseTurnoverSummaryQuery(req: Unit): Promise<LeaseTurnoverSummaryQuery[]> {
      return this.postLeaseTurnoverSummaryQuery.call(req);
    }
    
    async scenarioTenancySchedule(req: ScenarioTenancyScheduleReq): Promise<ScenarioTenancySchedule[]> {
      return this.postScenarioTenancySchedule.call(req);
    }
    
    async scenarioMetrics(req: DbKey<Scenario>): Promise<ScenarioMetrics> {
      return this.postScenarioMetrics.call(req);
    }
    
    async scenarioPeriodMetrics(req: ScenarioPeriodMetricsReq): Promise<ScenarioPeriodMetricRow[]> {
      return this.postScenarioPeriodMetrics.call(req);
    }
    
    async scenarioInstantMetrics(req: ScenarioInstantMetricsReq): Promise<ScenarioInstantMetricRow[]> {
      return this.postScenarioInstantMetrics.call(req);
    }
};