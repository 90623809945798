import { observer } from "mobx-react";
import React from "react";
import { Grid, Header, Icon, Input, Divider, Table, Placeholder } from "semantic-ui-react";
import { PropertySelectorMulti } from "../propertySelector/propertySelector";
import { ScenarioStore } from "../scenarioSelector/scenarioStore";
import { ScenarioTablesStore, ScenarioPeriodMetricTable, accountCategoryToStr, amountWithDbCrToStr } from "./scenarioTablesStore";
import { ScenarioSelectionStore } from "../scenarioSelector/scenarioSelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Scenario } from "../../../adl-gen/whistle/propte/scenario";
import { DbTenancySchedule, ScenarioMetrics, ScenarioPeriodMetricRow, AmountWithDbCr } from "../../../adl-gen/whistle/propte/views";
import { ScenarioSelector } from "../scenarioSelector/scenarioSelector";
import {Big} from 'big.js'
import { Updating } from "../../../models/updating";
import ts from "typescript";
import { AccountCategory, texprAccountCategory } from "../../../adl-gen/whistle/propte/category";
import { createJsonBinding } from "../../../adl-gen/runtime/json";
import { RESOLVER } from "../../../adl-gen/resolver";
import { DebitOrCredit } from "../../../adl-gen/whistle/propte/accounting";
import {capitalCase} from 'change-case';

const accountCategoryJB = createJsonBinding(RESOLVER, texprAccountCategory());

const styles = require("./scenarioTables.css");

/** Props for the component */
export interface ScenarioAnalysisProps {
  scenarioStore: ScenarioStore;
  scenarioTablesStore: ScenarioTablesStore;
  scenarioSelectionStore: ScenarioSelectionStore;
}

/**
 * Component for Scenario Analysis (table of info about scenarios)
 */
@observer
export class ScenarioTables extends React.Component<ScenarioAnalysisProps> {
  /** Renders the component */
  render() {
    const scenarioSelection = this.props.scenarioSelectionStore.scenarioSelection.value;
    const scenarioPeriodMetrics = this.props.scenarioTablesStore.scenarioPeriodMetrics.value;
    const selectionsReady = (scenarioSelection!== null);

    const scenarioIds = Array.from(scenarioSelection?.entries() || []).map(kv=>kv[0]);
    const dataLoading = scenarioPeriodMetrics===null;

    const scenarioPeriodMetricTable = this.props.scenarioTablesStore.scenarioPeriodMetricTable.value;

    console.log('scenarioPeriodMetrics:', scenarioPeriodMetrics);

    return <>
      {scenarioPeriodMetricTable ? this.renderTable(scenarioSelection!,scenarioPeriodMetricTable) : undefined }
    </>;
  }



  renderTable(scenarioSelection : Map<DbKey<Scenario>, Scenario>, spmt : ScenarioPeriodMetricTable) : JSX.Element {

    const rowsPrep : {
      scenarioId: DbKey<Scenario>,
      scenarioName: string,
      accountCategory: AccountCategory,
      accountCategoryStr: string,
      valStrs: string[]
    }[] = [];
    spmt.rowLabels.forEach((rl,i)=>{
      rowsPrep.push(
        {
          scenarioId: rl.scenarioId,
          scenarioName: scenarioSelection.get(rl.scenarioId)!.name,
          accountCategory: rl.accountCategory,
          accountCategoryStr: accountCategoryToStr(rl.accountCategory),
          valStrs: spmt.values[i].map((v,k)=>amountWithDbCrToStr(v))
        }
      );
    });

    rowsPrep.sort((a,b)=>{
      let cmp = stringCmp(a.scenarioName, b.scenarioName);
      if(cmp === 0) {
        cmp = stringCmp(a.accountCategoryStr, b.accountCategoryStr);
      }
      return cmp;
    });

    return <div className={styles.table}>
    <Header>Monthly account balance change</Header>
    <Table celled >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Scenario</Table.HeaderCell>
          <Table.HeaderCell>Account</Table.HeaderCell>
          {spmt.colLabels.map((v,i)=><Table.HeaderCell key={i}>{v.periodFrom}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {rowsPrep.map((r,i)=>
          <Table.Row key={i}>
            <Table.Cell>{r.scenarioName}</Table.Cell>
            <Table.Cell>{r.accountCategoryStr}</Table.Cell>
            {r.valStrs.map((v,j)=>
              <Table.Cell key={j}>{v}</Table.Cell>
            )}
          </Table.Row>
        )}
      </Table.Body>

    </Table>
    </div>;
  }
}

function stringCmp(a:string, b:string) {
  return (a < b ? -1 : a > b ? 1 : 0);
}
