import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Button, Form, Input, Loader } from "semantic-ui-react";
import { DbKey, WithDbId } from "../../../adl-gen/common/db";
import { LeaseTurnover, makeDataRecordCategory } from "../../../adl-gen/whistle/propte/lease";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { Updating } from "../../../models/updating";
import { Service } from "../../../service/service";
import { LeaseTurnoverSelectionStore } from "../../components/leaseTurnover/leaseTurnoverSelectionStore";
import { LeaseTurnoverStore } from "../../components/leaseTurnover/leaseTurnoverStore";

export interface Props {
  service: Service;
  leaseTurnoverStore : LeaseTurnoverStore;
  leaseTurnoverSelectionStore: LeaseTurnoverSelectionStore;
}

export type LeaseTurnoverWithIdPartial = {
  leaseTurnover: LeaseTurnover;
  id?: DbKey<Property>;
}

@observer
export class LeaseTurnoverCreate extends React.Component<Props> {

  @observable
  editing : LeaseTurnoverWithIdPartial|null = null;

  constructor(props:Props) {
    super(props);
    this.startEditNew();
  }

  @computed get selectedLeaseTurnover() : Updating<LeaseTurnoverWithIdPartial> {
    return this.props.leaseTurnoverSelectionStore.leaseturnoverSelectionSingle.map(m=>{
      return {
        id: m.id,
        leaseTurnover: m.value
      };
    });
  }

  saving: Updating<LeaseTurnoverWithIdPartial> = new Updating();

  render() {

    if (this.editing) {
      return <>
        <LeaseTurnoverEdit editing={this.editing}/>
        <Button primary onClick={()=>{
          this.saveEdit();
        }}>Save</Button>
        <Button primary onClick={()=>{
          this.cancelEdit();
        }}>Cancel</Button>
        <Loader active={this.saving.isUpdating()}/>
      </>;
    }

    const selectedLeaseTurnover = this.selectedLeaseTurnover.value;
    if (selectedLeaseTurnover === null) {
      return <>
        <Button primary onClick={()=>{
          this.startEditNew();
        }}>New</Button>
      </>;
    }
    return <>
      <Button primary onClick={()=>{
        this.startEditSelected(selectedLeaseTurnover);
      }}>Edit</Button>
    </>;
  }

  startEditSelected(selectedLeaseTurnover: LeaseTurnoverWithIdPartial) {
    this.editing = {
      id: selectedLeaseTurnover.id,
      leaseTurnover: {
        ...selectedLeaseTurnover.leaseTurnover
      }
    };
  }

  startEditNew() {
    this.editing = {
      leaseTurnover:{
        leaseId: this.props.leaseTurnoverStore.curLease.id,
        turnoverScenarioId: this.props.leaseTurnoverStore.curLeaseTurnoverScenario.id,
        date: "2020-01-01",
        incGst: true,
        numDays: null,
        amount: "0.0",
        category: makeDataRecordCategory('actual',{})
      }
    };
  }

  @action
  cancelEdit() {
    //this.editing = null;
    this.startEditNew();
  }

  @action
  saveEdit() {
    if(this.editing) {
      this.props.leaseTurnoverSelectionStore.leaseturnoverSelection.set(new Map)

      const value = this.editing.leaseTurnover;

      const promise : Promise<WithDbId<LeaseTurnover>> = this.props.service.upsertLeaseTurnover(value)
      .then((id)=>{
        return {
          id,
          value
        };
      });

      this.saving.update(
        promise.then(p=>{
          return {
            id: p.id,
            leaseTurnover: p.value
          };
        })
      );

      promise.then(p=>{
        // update global store
        this.props.leaseTurnoverStore.update();

        /*
        // update as selected property
        const sel : Map<DbKey<LeaseTurnover>, LeaseTurnover> = new Map();
        sel.set(p.id, p.value);
        this.props.leaseTurnoverSelectionStore.leaseturnoverSelection.set(sel);
        */

        // clear editing  //this.editing = null;
        //this.startEditNew();
        // actually: keep value as-is for easier incrementing.
      });
    }
  }
}

export interface LeaseTurnoverEditProps {
  editing: LeaseTurnoverWithIdPartial;
};

@observer
export class LeaseTurnoverEdit extends React.Component<LeaseTurnoverEditProps> {

  render() {
    const editing = this.props.editing;
    return <>
      <Form>
        <Form.Field>
          <label>Date</label>
          <Input type={"string"} value={editing.leaseTurnover.date} placeholder={"Date"} onChange={(ev,data)=>{
            editing.leaseTurnover.date = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Num Days</label>
          <Input type={"number"} value={editing.leaseTurnover.numDays || "30"} placeholder={"Num Days"} onChange={(ev,data)=>{
            editing.leaseTurnover.numDays = parseFloat(data.value);
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Amount</label>
          <Input type={"number"} value={editing.leaseTurnover.amount} placeholder={"Amount"} onChange={(ev,data)=>{
            editing.leaseTurnover.amount = data.value;
          }}></Input>
        </Form.Field>

      </Form>
    </>;
  }
}
