import { boolFieldFns, FieldFns, intFieldFns, jsonFieldFns, numberFieldFns, stringFieldFns } from "@hx/fields";

import { typeExprsEqual, typeExprToString } from "./adl-gen/runtime/utils";
import * as adlast from "./adl-gen/sys/adlast";
import * as systypes from "./adl-gen/sys/types";

export const typeExprFieldFns: FieldFns<adlast.TypeExpr> = {
  width: 30,
  rows: 1,
  toText(typeExpr) {
    return typeExprToString(typeExpr);
  },
  validate(text) {
    const typeExpr = parseTypeExpr(text);
    if (typeExpr === null) {
      return "must be an ADL type expression";
    }
    return null;
  },
  fromText(text) {
    const typeExpr = parseTypeExpr(text);
    if (typeExpr === null) {
      throw new Error("must be an ADL type expression");
    }
    return typeExpr;
  },
  equals(v1, v2) {
    return typeExprsEqual(v1, v2);
  }
};

function parseTypeExpr(_s: string): adlast.TypeExpr | null {
  // Split a string into TypeExpr tokens
  // function tokenize(str: string): string[] {
  //   let remaining = str;
  //   const tkns: string[] = [];
  //   const re = new RegExp(",|<|>| ");

  //   function save(n: number) {
  //     if (n > 0) {
  //       tkns.push( remaining.substr(0,n) );
  //       remaining = remaining.substr(n);
  //     }
  //   }

  //   while (remaining.length > 0) {
  //     const match = re.exec(remaining);
  //     if (match === null) {
  //       save(remaining.length);
  //     } else {
  //       save(match.index);
  //       if (match[0] !== ' ') {
  //         save(match.length);
  //       }
  //     }
  //   }

  //   return tkns;
  // }

  // let tokens = tokenize(s);

  // function nextToken(): string {
  //   if (tokens.length <= 0) {
  //     throw new Error("no more tokens");
  //   }
  //   const token = tokens[0];
  //   tokens = tokens.slice(0, 1);
  //   return token;
  // }

  // FIXME(timd) implement parsing of tokens
  return null;
}

const _primitiveFieldFns = {
  String: stringFieldFns,
  Int8: intFieldFns(-128, 127),
  Int16: intFieldFns(-32678, 32767),
  Int32: intFieldFns(-2147483648, 2147483647),
  Int64: intFieldFns(null, null),
  Word8: intFieldFns(0, 255),
  Word16: intFieldFns(0, 65535),
  Word32: intFieldFns(0, 4294967295),
  Word64: intFieldFns(0, null),
  Float: numberFieldFns(),
  Double: numberFieldFns(),
  Bool: boolFieldFns(),
  Json: jsonFieldFns()
};

export function primitiveFieldFns(primitive: string): FieldFns<unknown> | null {
  if (_primitiveFieldFns[primitive]) {
    return _primitiveFieldFns[primitive];
  } else {
    return null;
  }
}

// Nullable combinator, that allows a field to be empty.
export function nullableField<T>(fieldFns: FieldFns<T>): FieldFns<T | null> {
  const newFieldFns: FieldFns<T | null> = {
    width: fieldFns.width,
    rows: fieldFns.rows,
    toText: v => (v === null ? "" : fieldFns.toText(v)),
    validate: v => {
      if (v === "") {
        return null;
      }
      return fieldFns.validate(v);
    },
    fromText: text => (text === "" ? null : fieldFns.fromText(text)),
    equals: (v1, v2) => {
      if (v1 === null) {
        return v2 === null;
      }
      if (v2 === null) {
        return v1 === null;
      }
      return fieldFns.equals(v1, v2);
    }
  };
  if (fieldFns.datalist) {
    newFieldFns.datalist = fieldFns.datalist;
  }
  return newFieldFns;
}

export function enumField(enumDecl: adlast.Decl, enumUnion: adlast.Union): FieldFns<number> {
  function get(v: string): number | null {
    for (let i = 0; i < enumUnion.fields.length; i++) {
      if (v === enumUnion.fields[i].name) {
        return i;
      }
    }
    return null;
  }

  return {
    width: 30,
    rows: 1,
    toText(v: number): string {
      return enumUnion.fields[v].name;
    },
    validate(v: string): string | null {
      const r = get(v);
      if (r === null) {
        return "must be a " + enumDecl.name;
      }
      return null;
    },
    fromText(v: string): number {
      const r = get(v);
      if (r === null) {
        throw new Error("must be a " + enumDecl.name);
      }
      return r;
    },
    equals: (v1, v2) => {
      return v1 === v2;
    },
    datalist: enumUnion.fields.map((f: { name: string}) => f.name)
  };
}

export function maybeField<T>(fieldFns: FieldFns<T>): FieldFns<systypes.Maybe<T>> {
  const newFieldFns: FieldFns<systypes.Maybe<T>> = {
    width: fieldFns.width,
    rows: fieldFns.rows,
    toText: v => (v.kind === "just" ? fieldFns.toText(v.value) : ""),
    validate: v => {
      if (v === "") {
        return null;
      }
      return fieldFns.validate(v);
    },
    fromText: text =>
      text === "" ? { kind: "nothing" } : { kind: "just", value: fieldFns.fromText(text) },
    equals: (v1, v2) => {
      if (v1.kind === "nothing") {
        return v2.kind === "nothing";
      } else {
        if (v2.kind === "nothing") {
          return false;
        } else {
          return fieldFns.equals(v1.value, v2.value);
        }
      }
    }
  };
  if (fieldFns.datalist) {
    newFieldFns.datalist = fieldFns.datalist;
  }
  return newFieldFns;
}
