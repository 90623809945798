import { AdlEditor } from "@adltools/adl-editor";
import * as AT from "@adltools/adl-table";
import { createAdlTree } from "@adltools/adl-tree";
import { AdlTable, AdlTableProps } from "@adltools/components/AdlTable";
import { assertNever } from "@hx/util/types";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Button,  Header, Icon, Modal } from "semantic-ui-react";

import { TableColumn } from "../../adl-gen/common/adminui/api";
import { WithDbId } from "../../adl-gen/common/db";
import { SortDirection, TableView } from "../../adl-gen/common/tabular";
import {
  TableMetadata,
  TablePageEvent,
  TablePageState,
  TSRow
} from "../store";

import { renderLoading } from "./main-page";
import * as styles from "./table-page.css";

type Event
  = TablePageEvent
  | {kind:'done'}
  ;

export interface AdminUiTablePageProps {
  state: TablePageState;
  onEvent(event: Event): void;
}

type ModalState =
  | { kind: "add" }
  | { kind: "edit"; value: WithDbId<TSRow> }
  | { kind: "delete"; value: WithDbId<TSRow> }
  ;

@observer
export class AdminUiTablePage extends React.Component<AdminUiTablePageProps> {
  @observable
  modalState: ModalState | null = null;

  @observable
  columnPropsShown: string | null = null;

  constructor(props: AdminUiTablePageProps) {
    super(props);
  }

  render() {
    return renderLoading(this.props.state.tmetadata, this.renderPage);
  }

  renderPage = (tmetadata: TableMetadata): JSX.Element => {
    const columns = this.getColumns(tmetadata);
    const modal
      = this.modalState !== null ? this.renderModal(tmetadata, this.modalState)
      : this.props.state.pendingDbError ? this.renderDbError(this.props.state.pendingDbError)
      : null;
    return (
      <div className={styles.page}>
        <div className={styles.topbar1}>
          <h1 className={styles.title}>{this.props.state.table}</h1>
          <AT.IconButton name="window close outline" onClick={() => this.props.onEvent({kind:'done'})} />
        </div>
        <div className={styles.topbar2}>
          <p>{tmetadata.table.description}</p>
        </div>
        {this.renderFilter()}
        <div  className={styles.topbar3}>
          {this.renderPageButtons(tmetadata)}
        </div>
        <div className={styles.tableholder}>
          <AdminTable columns={columns} values={this.props.state.loadedRows ? this.props.state.loadedRows.items : []} />
        </div>
        {modal}
      </div>
    );
  };

  renderPageButtons = (tmetadata: TableMetadata): JSX.Element => {
    const addButton = !tmetadata.table.allowInsert ? (
      undefined
    ) : (
      <AT.PageButton tooltip="Add a new row" icon="plus" onClick={this.addRow} />
    );
    const prevPageButton = (
      <AT.PageButton
        tooltip={"Previous page"}
        icon="left arrow"
        disabled={!this.props.state.canPageBack}
        onClick={() => this.props.onEvent({kind:'pageback'})}
      />
    );
    let pageLocation = <span>...</span>;
    const page = this.props.state.loadedRows;
    if (page !== null) {
      const fromi = page.current_offset + 1;
      const toi = fromi + page.items.length - 1;
      const total = page.total_size;
      pageLocation = (
        <span className={styles.pagelocation}>
          {fromi}-{toi}/{total}
        </span>
      );
    }
    const nextPageButton = (
      <AT.PageButton
        tooltip={"Next page"}
        icon="right arrow"
        disabled={!this.props.state.canPageForward}
        onClick={() => this.props.onEvent({kind:'pageforward'})}
      />
    );
    const refreshButton = (
      <AT.PageButton
        tooltip={"Refresh"}
        icon="refresh"
        disabled={this.props.state.rowsLoading}
        loading={this.props.state.rowsLoading}
        onClick={() => this.props.onEvent({kind: 'reload'})}
      />
    );
    return (
      <div className={styles.buttonpanel}>
        {addButton}
        {prevPageButton}
        {pageLocation}
        {nextPageButton}
        {refreshButton}
      </div>
    );
  };

  renderModal = (tmetadata: TableMetadata, state: ModalState): JSX.Element => {
    if (state.kind === "add") {
      const header = "Adding new " + tmetadata.table.label + " value";
      const content = (
        <AdlEditor
          value={null}
          veditor={tmetadata.veditor}
          onCancel={this.clearModal}
          onApply={(tsrow: TSRow) => {
              this.props.onEvent({kind:'create', tmetadata, value:tsrow});
              this.clearModal()
          }}
          allowRaw={tmetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={this.clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "edit") {
      const header = "Editing " + tmetadata.table.label + " value with id " + state.value.id;
      const content = (
        <AdlEditor
          value={state.value.value}
          veditor={tmetadata.veditor}
          onCancel={this.clearModal}
          onApply={(tsrow: TSRow) => {
            this.props.onEvent({kind:'update', tmetadata, value:{ id: state.value.id, value: tsrow }});
            this.clearModal()
          }}
          allowRaw={tmetadata.jsonBinding}
        />
      );
      return (
        <Modal open={true} onClose={this.clearModal}>
          <Header>{header}</Header>
          <Modal.Content style={{ margin: 0 }}>{content}</Modal.Content>
        </Modal>
      );
    } else if (state.kind === "delete") {
      return (
       <Modal open={true} onClose={this.clearModal}>
          <Header>Delete {tmetadata.table.name}:{state.value.id}?</Header>
          <Modal.Content style={{ margin: 0 }}>
            <div>
              Please confirm you wish to delete {state.value.id} from {tmetadata.table.name}.
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.clearModal}>
              <Icon />Cancel
            </Button>
            <Button color="red" onClick={() => {
              this.props.onEvent({kind:'delete', tmetadata, id: state.value.id});
              this.clearModal()
            }}>

              <Icon name="checkmark" />Delete
            </Button>
          </Modal.Actions>
       </Modal>
      );
    } else {
       return assertNever(state);
    }

  };

  renderDbError = (dbError: string): JSX.Element => {
     return (
     <Modal open={true} onClose={this.clearDbError}>
        <Header>Database Error</Header>
        <Modal.Content style={{ margin: 0 }}>
          <div>{dbError}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.clearDbError}>Ok</Button>
        </Modal.Actions>
     </Modal>
     );
  }

  renderFilter = (): JSX.Element | undefined => {
    const view = this.props.state.tableView;
    if(!view) {
      return undefined;
    }
    return (
      <div className={styles.filterholder}>
        <AT.FilterView filter={view.filter} onClearFilter={this.onClearFilter}/>
      </div>
    );
  }

  getColumns = (tmetadata: TableMetadata): AdminTableColumn[] => {
    const dbColumns: AdminTableColumn[] = [];
    const view = this.props.state.tableView;

    // The action button column
    dbColumns.push(this.createActionsColumn(tmetadata));

    // The id column
    dbColumns.push(this.createIdColumn(view));

    // All of the simple data fields - ie those that can be displayed/edited
    tmetadata.table.columns.forEach(tcol => {
      const acol = this.createDbColumn(tcol, tmetadata, view);
      if (acol !== null) {
        dbColumns.push(acol);
      }
    });

    return dbColumns;
  };

  createIdColumn = (view: TableView | null): AdminTableColumn => {

    const label = "ID";
    const header
      = view === null
      ? AT.cellContent(label)
      : this.createHeaderCell(view, label, "id");

    return {
      id: "id",
      header,
      content: (tsrow: WithDbId<TSRow>) => AT.cellContent(tsrow.id)
    };
  };

  createActionsColumn = (tmetadata: TableMetadata): AdminTableColumn => {
    return {
      id: "actions",
      header: AT.cellContent(""),
      content: (tsrow: WithDbId<TSRow>) => {
        const editButton = (
          <AT.IconButton name="edit" onClick={() => this.editRow(tsrow)} />
        );
        const deleteButton = !tmetadata.table.allowDelete ? (
          undefined
        ) : (
          <AT.IconButton name="trash" onClick={() => this.deleteRow(tsrow)} />
        );
        return AT.cellContent(
          <span>
            {editButton}
            {deleteButton}
          </span>
        );
      }
    };
  };

  createDbColumn = (
    tcol: TableColumn,
    tmetadata: TableMetadata,
    view: TableView | null
  ): AdminTableColumn | null => {
    const adlTree = createAdlTree(tcol.typeExpr, tmetadata.resolver);
    const fieldfns = AT.getFieldFns(
      tmetadata.resolver,
      null,
      null,
      adlTree,
      tmetadata.customize.getCustomField
    );
    if (fieldfns === null) {
      return null;
    }
    const fieldname = tcol.name;

    const header
      = view === null
      ? AT.cellContent(tcol.label)
      : this.createHeaderCell(view, tcol.label, fieldname);

    return {
      id: tcol.name,
      header,
      content: (tsrow: WithDbId<TSRow>) => AT.cellContent(fieldfns.toText(tsrow.value[tcol.name]))
    };
  };

  createHeaderCell(view: TableView, label: string, fieldname: string): AT.CellContent {
    return AT.cellContent(
      <AT.HeaderCell
        label={label}
        sort={AT.getViewSort(view, fieldname)}
        filter={AT.getFieldFilter(view.filter, fieldname)}
        showProps={this.columnPropsShown === fieldname}
        onSort={this.onSort.bind(this, fieldname)}
        onFilter={this.onFilter.bind(this, fieldname)}
        onShowProps={this.setColumnPropsShown.bind(this, fieldname)}
      />
    );
  }

  @action
  editRow = (value: WithDbId<TSRow>) => {
    this.modalState = { kind: "edit", value };
  };

  @action
  deleteRow = (value: WithDbId<TSRow>) => {
    this.modalState = { kind: "delete", value };
  };

  @action
  addRow = () => {
    this.modalState = { kind: "add" };
  };

  @action
  onSort = (fieldname: string, direction: SortDirection) => {
    const view = this.props.state.tableView;
    if (view) {
      const view1 = AT.withViewSort(view, fieldname, direction);
      this.props.onEvent({kind: 'settableview', view: view1});
      this.columnPropsShown = null;
    }
  };

  @action
  onFilter = (fieldname: string, pattern: string) => {
    const view = this.props.state.tableView;
    if (view) {
      const view1 = { ...view };
      view1.filter = AT.withFieldFilter(view1.filter, fieldname, pattern);
      this.props.onEvent({kind: 'settableview', view: view1});
    }
  };

  @action
  onClearFilter = () => {
    const view = this.props.state.tableView;
    if (view) {
      const view1 = { ...view };
      view1.filter = {kind:'literal', value: true};
      this.props.onEvent({kind: 'settableview', view: view1});
    }
  };

  @action
  setColumnPropsShown = (fieldname: string, shown: boolean): void => {
    this.columnPropsShown = shown ? fieldname : null;
  };

  @action
  clearModal = () => {
    this.modalState = null;
  };

  @action
  clearDbError = () => {
    this.modalState = null;
    this.props.onEvent({kind:'cleardberror'});
  };
}

export type AdminTableColumn = AT.Column<unknown, string>;

function AdminTable(props: AdlTableProps<WithDbId<TSRow>, string>): JSX.Element {
  return React.createElement(AdlTable, props);
}
