/* @generated from adl module whistle.propte.calcs */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';

/**
 * An amount at date
 */
export interface AmountAtDate {
  amount: common.BigDecimal;
  date: common.LocalDate;
}

export function makeAmountAtDate(
  input: {
    amount: common.BigDecimal,
    date: common.LocalDate,
  }
): AmountAtDate {
  return {
    amount: input.amount,
    date: input.date,
  };
}

const AmountAtDate_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.calcs","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An amount at date\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[],"serializedName":"date","default":{"kind":"nothing"},"name":"date","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDate"}},"parameters":[]}}]}},"name":"AmountAtDate","version":{"kind":"nothing"}}};

export const snAmountAtDate: ADL.ScopedName = {moduleName:"whistle.propte.calcs", name:"AmountAtDate"};

export function texprAmountAtDate(): ADL.ATypeExpr<AmountAtDate> {
  return {value : {typeRef : {kind: "reference", value : snAmountAtDate}, parameters : []}};
}

/**
 * An amount at an integer period (used internally in calcs)
 */
export interface AmountAtPeriod {
  amount: common.BigDecimal;
  period: number;
}

export function makeAmountAtPeriod(
  input: {
    amount: common.BigDecimal,
    period: number,
  }
): AmountAtPeriod {
  return {
    amount: input.amount,
    period: input.period,
  };
}

const AmountAtPeriod_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.calcs","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An amount at an integer period (used internally in calcs)\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[],"serializedName":"period","default":{"kind":"nothing"},"name":"period","typeExpr":{"typeRef":{"kind":"primitive","value":"Int64"},"parameters":[]}}]}},"name":"AmountAtPeriod","version":{"kind":"nothing"}}};

export const snAmountAtPeriod: ADL.ScopedName = {moduleName:"whistle.propte.calcs", name:"AmountAtPeriod"};

export function texprAmountAtPeriod(): ADL.ATypeExpr<AmountAtPeriod> {
  return {value : {typeRef : {kind: "reference", value : snAmountAtPeriod}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.calcs.AmountAtDate" : AmountAtDate_AST,
  "whistle.propte.calcs.AmountAtPeriod" : AmountAtPeriod_AST
};
