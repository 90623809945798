import { LocalDate } from "../../../adl-gen/common"
import { Updating } from "../../../models/updating"
import { DbTenancySchedule } from "../../../adl-gen/whistle/propte/views";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { PropertySelectionStore } from "../propertySelector/propertySelectionStore";
import { computed } from "mobx";
import { Service } from "../../../service/service";
import { LeaseTurnoverSummaryQuery } from "../../../adl-gen/whistle/propte/lease";

export type TurnoverSummaryTableRow = LeaseTurnoverSummaryQuery;

export type TurnoverSummaryTableCol = {
  header: string;
  get: (row: TurnoverSummaryTableRow)=>string;
};

export type TurnoverSummaryTableData = {
  columns: TurnoverSummaryTableCol[];
  rows: TurnoverSummaryTableRow[];
};

const turnoverSummaryTableCols: TurnoverSummaryTableCol[] = [
  {
    header: "Turnover Id",
    get: (row:TurnoverSummaryTableRow)=>row.turnoverId
  },
  {
    header: "Lease Id",
    get: (row:TurnoverSummaryTableRow)=>row.leaseId
  },
  {
    header: "Property Id",
    get: (row:TurnoverSummaryTableRow)=>row.propertyId
  },
  {
    header: "Property",
    get: (row:TurnoverSummaryTableRow)=>row.propertyName
  },
  {
    header: "Date",
    get: (row:TurnoverSummaryTableRow)=>row.date
  },
  {
    header: "Inc GST?",
    get: (row:TurnoverSummaryTableRow)=>row.incGst ? "true" : "false"
  },
  {
    header: "# Days",
    get: (row:TurnoverSummaryTableRow)=>`${row.numDays}`
  },
  {
    header: "Amount",
    get: (row:TurnoverSummaryTableRow)=>row.amount
  },
  {
    header: "Lease key",
    get: (row:TurnoverSummaryTableRow)=>row.leaseKey
  },
  {
    header: "Lessee",
    get: (row:TurnoverSummaryTableRow)=>row.lesseeName
  }
];

export const emptyTurnoverSummaryTable : TurnoverSummaryTableData = {
  columns: turnoverSummaryTableCols,
  rows: []
};

export class TurnoverSummaryStore {
  input: Updating<{}> = new Updating();    // placeholder for some input data / reload button

  constructor(
    private service : Service) {
  }

  @computed get turnoverSummary() : Updating<LeaseTurnoverSummaryQuery[]> {
    return this.input.then( (inp: {})=>{
      return Updating.ofPromise(this.service.leaseTurnoverSummaryQuery(inp));
    });
  }

  @computed get tableData() : Updating<TurnoverSummaryTableData> {


    return this.turnoverSummary.map(tos=>{
      const rows : TurnoverSummaryTableRow[] = [...tos];

      // sort by property, lease, date
      rows.sort((a,b)=>{
        function compare(i: TurnoverSummaryTableRow) : string {
          return `${i.propertyName} ${i.leaseKey} ${i.date}`;
        }

        const aV = compare(a);
        const bV = compare(b);
        return aV.localeCompare(bV);
      });

      return {
        columns: turnoverSummaryTableCols,
        rows
      };
    });
  }
}
