import { observer } from "mobx-react";
import React from "react";
import { Header, Table, Segment, Button, Rail } from "semantic-ui-react";
import { ScenarioStore } from "../scenarioSelector/scenarioStore";
import { ScenarioTablesStore, ScenarioCombinedMetricTable, emptyScenarioCombinedMetricTable } from "./scenarioTablesStore";
import { ScenarioSelectionStore } from "../scenarioSelector/scenarioSelectionStore";
import { texprAccountCategory } from "../../../adl-gen/whistle/propte/category";
import { createJsonBinding } from "../../../adl-gen/runtime/json";
import { RESOLVER } from "../../../adl-gen/resolver";
import moment from "moment";


const styles = require("./scenarioTables.css");

/** Props for the component */
export interface ScenarioAnalysisProps {
  scenarioStore: ScenarioStore;
  scenarioTablesStore: ScenarioTablesStore;
  scenarioSelectionStore: ScenarioSelectionStore;
}

/**
 * Component for Scenario Analysis (table of info about scenarios)
 */
@observer
export class ScenarioTableCombined extends React.Component<ScenarioAnalysisProps> {
  /** Renders the component */
  render() {
    const scmTable = this.props.scenarioTablesStore.scenarioCombinedMetricsTable;

    const updating = scmTable.updating > 0;
    const value = scmTable.value;

    return <>
      <Segment loading={updating}>
        {this.renderContents(value)}
      </Segment>
    </>;
  }

  renderContents(scmTable: ScenarioCombinedMetricTable|null) : JSX.Element|undefined {
    return <>
      <Segment basic>
        <Header>Scenario Data Table</Header>

        {(scmTable !== null) && (scmTable.rows.length > 0) &&
        <Rail attached internal position='right'>
          <Segment basic textAlign='right'>
            <Button primary onClick={()=>{this.downloadTable(scmTable)}}>Download</Button>
          </Segment>
        </Rail>}
      </Segment>

      {this.renderTable(scmTable || emptyScenarioCombinedMetricTable)}
    </>;
  }

  downloadTable(scmTable: ScenarioCombinedMetricTable) {

    const filename = `scenarioExport-${moment().format("YYYY-MM-DD-HH-mm")}.csv`;

    const element = document.createElement("a");
    const file = new Blob([ this.renderTableCSV(scmTable) ], {type: 'text/csv'});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
  }

  renderTableCSV(scmTable: ScenarioCombinedMetricTable) : string {
    const header : string[] = scmTable.columns.map( (col)=>`"${col.header}"`);

    const body : string[][] = scmTable.rows.map((rowData)=>
      scmTable.columns.map((col)=>`"${col.get(rowData)}"`)
    );

    return [header, ...body].join('\n');
  }

  renderTable(scmTable: ScenarioCombinedMetricTable) : JSX.Element {
    return <div className={styles.table}>

      <Table celled >
        <Table.Header>
          <Table.Row>
          {
            scmTable.columns.map( (col,i)=><Table.HeaderCell key={i}>{col.header}</Table.HeaderCell>)
          }
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {scmTable.rows.length > 0 ? scmTable.rows.map((rowData,i)=>
            <Table.Row key={i}>
              {scmTable.columns.map((col,j)=><Table.Cell key={j}>{col.get(rowData)}</Table.Cell>)}
            </Table.Row>
          )

          :
          <Table.Row>
              {scmTable.columns.map((col,j)=><Table.Cell key={j}> </Table.Cell>)}
          </Table.Row>

          }
        </Table.Body>
      </Table>
    </div>;
  }
}

