import { observer } from "mobx-react";
import * as React from "react";
import { Grid, Header, Segment } from "semantic-ui-react";
import { Service } from "../../../service/service";
import { PropteStore } from "../../../stores/propte-store";
import { LeaseSelectorSingle } from "../../components/leaseSelector/leaseSelector";
import { LeaseTurnoverScenarioSelectorSingle } from "../../components/leaseTurnoverScenarioSelector/leaseTurnoverScenarioSelector";
import { PropertySelectorSingle } from "../../components/propertySelector/propertySelector";
import { LeaseCreate } from "./lease-create";
import { LeaseTurnoverCreate } from "./lease-turnover-create";
import { LeaseTurnoverScenarioCreate } from "./lease-turnover-scenario-create";
import { LeaseTurnoverTable } from "./lease-turnover-table";
import { PropertyCreate } from "./property-create";

export interface PropertyDataPageProps {
  propteStore: PropteStore;
}

/**
 *
 */
@observer
export class PropertyDataPage extends React.Component<PropertyDataPageProps> {
  /** Renders the page */
  render() {
    const propertyStore = this.props.propteStore.propertyStore;
    const propertySelectionStore = this.props.propteStore
      .propertySelectionStore;

    const leaseStore = this.props.propteStore.leaseStore;
    const leaseSelectionStore = this.props.propteStore.leaseSelectionStore;

    const leaseTurnoverScenarioStore = this.props.propteStore.leaseTurnoverScenarioStore;
    const leaseTurnoverScenarioSelectionStore = this.props.propteStore.leaseTurnoverScenarioSelectionStore;

    const leaseTurnoverStore = this.props.propteStore.leaseTurnoverStore;
    const leaseTurnoverSelectionStore = this.props.propteStore.leaseTurnoverSelectionStore;

    return (
      <>
      <Grid>
        <Grid.Column width={8}>
          <Segment>
            <Header as='h3'>Property</Header>

            <Segment basic>
              <PropertySelectorSingle
                propertyStore={propertyStore} propertySelectionStore={propertySelectionStore}
              />
            </Segment>

            <Segment basic>
              <PropertyCreate service={this.props.propteStore.service} propertyStore={propertyStore} propertySelectionStore={propertySelectionStore}/>
            </Segment>
          </Segment>
        </Grid.Column>

        <Grid.Column width={8}>
          <Segment>
            <Header as='h3'>Turnover Scenario</Header>

            <Segment basic>
              <LeaseTurnoverScenarioSelectorSingle
                leaseTurnoverScenarioStore={leaseTurnoverScenarioStore} leaseTurnoverScenarioSelectionStore={leaseTurnoverScenarioSelectionStore}
              />
            </Segment>

            <Segment basic>
              <LeaseTurnoverScenarioCreate service={this.props.propteStore.service} leaseTurnoverScenarioStore={leaseTurnoverScenarioStore} leaseTurnoverScenarioSelectionStore={leaseTurnoverScenarioSelectionStore}/>
            </Segment>
          </Segment>
        </Grid.Column>
      </Grid>

      {
        propertySelectionStore.propertySelectionSingle.hasValue() ?
        <Segment disabled={!propertySelectionStore.propertySelectionSingle.hasValue()}>
          <Header as='h3'>Lease</Header>

          <Segment basic>
            <LeaseSelectorSingle
              leaseStore={leaseStore} leaseSelectionStore={leaseSelectionStore}
            />
          </Segment>

          <Segment basic>
            <LeaseCreate service={this.props.propteStore.service} leaseStore={leaseStore} leaseSelectionStore={leaseSelectionStore}/>
          </Segment>
        </Segment>
        : undefined
      }

      {
        [
          propertySelectionStore.propertySelectionSingle.hasValue(),
          leaseSelectionStore.leaseSelectionSingle.hasValue(),
          leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelectionSingle.hasValue(),
        ].every(x=>x) ?
        <Segment>
          <Header as='h3'>Turnover Data</Header>

          <Grid>
            <Grid.Column width={8}>
              <LeaseTurnoverTable leaseTurnoverStore={leaseTurnoverStore}/>
            </Grid.Column>
            <Grid.Column width={8}>
              <LeaseTurnoverCreate service={this.props.propteStore.service} leaseTurnoverStore={leaseTurnoverStore} leaseTurnoverSelectionStore={leaseTurnoverSelectionStore}/>
            </Grid.Column>
          </Grid>

        </Segment>
        : undefined
      }


      </>
    );
  }
}
