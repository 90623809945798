import { observer } from "mobx-react";
import React from "react";
import { Grid, Header, Icon, Input, Divider, Table, Placeholder } from "semantic-ui-react";
import { PropertySelectorMulti } from "../propertySelector/propertySelector";
import { ScenarioStore } from "../scenarioSelector/scenarioStore";
import { ScenarioAnalysisStore } from "./scenarioAnalysisStore";
import { ScenarioSelectionStore } from "../scenarioSelector/scenarioSelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Scenario } from "../../../adl-gen/whistle/propte/scenario";
import { DbTenancySchedule, ScenarioMetrics } from "../../../adl-gen/whistle/propte/views";
import { ScenarioSelector } from "../scenarioSelector/scenarioSelector";
import {Big} from 'big.js'
import { Updating } from "../../../models/updating";
import ts from "typescript";

/** Props for the component */
export interface ScenarioAnalysisProps {
  scenarioStore: ScenarioStore;
  scenarioAnalysisStore: ScenarioAnalysisStore;
  scenarioSelectionStore: ScenarioSelectionStore;
}

type ScenarioAnalysisRow = {
  cells: JSX.Element[]
};

type ScenarioAnalysisScenario = {
  scenarioId: DbKey<Scenario>;
  rows: ScenarioAnalysisRow[];
};

type ScenarioAnalysisTable = {
  headers: string[],
  rowsByScenario: ScenarioAnalysisScenario[]
};
type GetCellStr = (data:ScenarioMetrics)=>string;

type GetField = {
  header: string,
  cell: GetCellStr,
  ordering: number
};

function IsGetField(x : GetField|null) : x is GetField {
  return x!==null;
}

/**
 * Component for Scenario Analysis (table of info about scenarios)
 */
@observer
export class ScenarioAnalysis extends React.Component<ScenarioAnalysisProps> {

  private tableData(data: Map<DbKey<Scenario>, Updating<ScenarioMetrics>>|null) : ScenarioAnalysisTable {

    const fields : {[key in keyof ScenarioMetrics] : GetField|null}  = {
      scenarioId: null,
      scenarioKey: {
        header: "Key",
        cell: (vals:ScenarioMetrics)=>vals.scenarioKey,
        ordering: 1
      },
      scenarioName: {
        header: "Name",
        cell: (vals:ScenarioMetrics)=>vals.scenarioKey,
        ordering: 2
      },
      scenarioCategory:{
        header: "Category",
        cell: (vals:ScenarioMetrics)=>vals.scenarioCategory.kind,
        ordering: 3
      },
      scenarioStartDate: {
        header: "Start Date",
        cell: (vals:ScenarioMetrics)=>vals.scenarioStartDate,
        ordering: 4
      },
      scenarioEndDate: {
        header: "End Date",
        cell: (vals:ScenarioMetrics)=>vals.scenarioEndDate,
        ordering: 5
      },
      //totalsByCategoryDbCr: null,
      //netByCategory: null,
      internalRateOfReturn: {
        header: "Internal Rate of Return (% P.A.)",
        cell: (vals:ScenarioMetrics)=>new Big(vals.internalRateOfReturn.rate).times(100).toFixed(2),
        ordering: 6
      },
      //investmentsDistributions: null
    };

    const getFields = Object.keys(fields)
      .map(f=>{
        const x : GetField|null = fields[f];
        return x;
      })
      .filter(IsGetField)
      .sort((a,b)=>a.ordering - b.ordering);

    const headers : string[] = getFields.map(f=>f.header);
    const columns : GetCellStr[] = getFields.map(f=>f.cell);

    const scenarioAnalysisRowsByScenario : ScenarioAnalysisScenario[] = Array.from(data?.entries() || []).map(kv=>{
      const dbKey = kv[0];
      const tsByProp : Updating<ScenarioMetrics> = kv[1];

      const datax = tsByProp.getValue();

      if(datax === null) {
        const rows = [
          {
            cells: columns.map(colToStr=>
              <Placeholder>
                <Placeholder.Line/>
                <Placeholder.Line/>
              </Placeholder>
            )
          }
        ];
        return {
          scenarioId: dbKey,
          rows
        };
      } else {
        const rows = [
          {
            cells: columns.map(colToStr=><span>{colToStr(datax)}</span>)
          }
        ]
        return {
          scenarioId: dbKey,
          rows
        };
      }
    });

    return {
      headers,
      rowsByScenario: scenarioAnalysisRowsByScenario
    };
  }


  /** Renders the component */
  render() {
    const scenarioSelection = this.props.scenarioSelectionStore.scenarioSelection.value;
    const scenarioAnalysis = this.props.scenarioAnalysisStore.scenarioAnalysis.value;
    const selectionsReady = (scenarioSelection!== null);

    const scenarioIds = Array.from(scenarioSelection?.entries() || []).map(kv=>kv[0]);
    const tData = this.tableData(scenarioAnalysis);
    const dataLoading = scenarioAnalysis===null;

    return <>
      <ScenarioSelector
        scenarioStore={this.props.scenarioStore}
        scenarioSelectionStore={this.props.scenarioSelectionStore}
      />
      <Divider></Divider>


      <Table celled>
        <Table.Header>
          <Table.Row>
            {tData.headers.map((h,i)=><Table.HeaderCell key={i}>{h}</Table.HeaderCell>)}
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {tData.rowsByScenario.map((scenario,i)=>
            scenario.rows.map((row,j)=>
              <Table.Row key={`${i}-${j}`}>
                {row.cells.map((cell,k)=>
                  <Table.Cell key={`${i}-${j}-${k}`}>{cell}</Table.Cell>
                )}
              </Table.Row>)
            )
          }
        </Table.Body>

      </Table>
    </>;
  }
}
