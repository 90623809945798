import { observer } from "mobx-react";
import * as React from "react";
import { Dropdown, DropdownProps } from "semantic-ui-react";
import { DbKey } from "../../../adl-gen/common/db";
import { LeaseTurnoverScenario } from "../../../adl-gen/whistle/propte/lease";
import { LeaseTurnoverScenarioSelectionStore } from "./leaseTurnoverScenarioSelectionStore";
import { LeaseTurnoverScenarioStore } from "./leaseTurnoverScenarioStore";

/** Props for the component */
export interface LeaseTurnoverScenarioSelectorProps {
  leaseTurnoverScenarioStore: LeaseTurnoverScenarioStore,
  leaseTurnoverScenarioSelectionStore: LeaseTurnoverScenarioSelectionStore
}

@observer
export class LeaseTurnoverScenarioSelectorSingle extends React.Component<LeaseTurnoverScenarioSelectorProps> {

  /** Renders the component */
  render() {
    const leaseturnoverscenarioStore = this.props.leaseTurnoverScenarioStore;
    const options = leaseturnoverscenarioStore.selectorOptions.getValue() || [];
    const updating = leaseturnoverscenarioStore.selectorOptions.isUpdating();

    return (
      <Dropdown
        placeholder='Lease Turnover Scenario'
        fluid
        search
        selection
        scrolling
        clearable
        options={options}
        loading={updating}
        onChange={this.onChange}
        value={this.getSelection()}
      />
    );
  }

  private getSelection() : DbKey<LeaseTurnoverScenario> {
    const val = this.props.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelectionSingle;
    if(val.value === null) {
      return "";
    }
    return val.value.id;
  }

  private assignSelection = (dbKey: DbKey<LeaseTurnoverScenario>)=>{
    const x = this.props.leaseTurnoverScenarioStore.properties.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>[dbKey].find(i=>i===kv[0]));
      return new Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario>(entries);
    });
    this.props.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    const dbKey : DbKey<LeaseTurnoverScenario> = data.value as string;
    this.assignSelection(dbKey);
    return;
  }
}
