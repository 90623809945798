/* @generated from adl module whistle.propte.utils */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';

/**
 * Keyed string type used to identify types.
 */
export type Key<_T> = string;

const Key_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.utils","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Keyed string type used to identify types.\n"}],"type_":{"kind":"newtype_","value":{"typeParams":["T"],"default":{"kind":"nothing"},"typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}}},"name":"Key","version":{"kind":"nothing"}}};

export const snKey: ADL.ScopedName = {moduleName:"whistle.propte.utils", name:"Key"};

export function texprKey<T>(texprT : ADL.ATypeExpr<T>): ADL.ATypeExpr<Key<T>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "whistle.propte.utils",name : "Key"}}, parameters : [texprT.value]}};
}

/**
 * Rate per annum as decimal eg 0.05 for 5% per annum
 */
export interface RatePerAnnum {
  rate: common.BigDecimal;
}

export function makeRatePerAnnum(
  input: {
    rate: common.BigDecimal,
  }
): RatePerAnnum {
  return {
    rate: input.rate,
  };
}

const RatePerAnnum_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.utils","decl":{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Rate per annum as decimal eg 0.05 for 5% per annum\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"rate","default":{"kind":"nothing"},"name":"rate","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}}]}},"name":"RatePerAnnum","version":{"kind":"nothing"}}};

export const snRatePerAnnum: ADL.ScopedName = {moduleName:"whistle.propte.utils", name:"RatePerAnnum"};

export function texprRatePerAnnum(): ADL.ATypeExpr<RatePerAnnum> {
  return {value : {typeRef : {kind: "reference", value : snRatePerAnnum}, parameters : []}};
}

export interface RatePerMonth {
  rate: common.BigDecimal;
}

export function makeRatePerMonth(
  input: {
    rate: common.BigDecimal,
  }
): RatePerMonth {
  return {
    rate: input.rate,
  };
}

const RatePerMonth_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.utils","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"rate","default":{"kind":"nothing"},"name":"rate","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}}]}},"name":"RatePerMonth","version":{"kind":"nothing"}}};

export const snRatePerMonth: ADL.ScopedName = {moduleName:"whistle.propte.utils", name:"RatePerMonth"};

export function texprRatePerMonth(): ADL.ATypeExpr<RatePerMonth> {
  return {value : {typeRef : {kind: "reference", value : snRatePerMonth}, parameters : []}};
}

export interface Pair2<T> {
  first: T;
  second: T;
}

export function makePair2<T>(
  input: {
    first: T,
    second: T,
  }
): Pair2<T> {
  return {
    first: input.first,
    second: input.second,
  };
}

const Pair2_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.utils","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":["T"],"fields":[{"annotations":[],"serializedName":"first","default":{"kind":"nothing"},"name":"first","typeExpr":{"typeRef":{"kind":"typeParam","value":"T"},"parameters":[]}},{"annotations":[],"serializedName":"second","default":{"kind":"nothing"},"name":"second","typeExpr":{"typeRef":{"kind":"typeParam","value":"T"},"parameters":[]}}]}},"name":"Pair2","version":{"kind":"nothing"}}};

export const snPair2: ADL.ScopedName = {moduleName:"whistle.propte.utils", name:"Pair2"};

export function texprPair2<T>(texprT : ADL.ATypeExpr<T>): ADL.ATypeExpr<Pair2<T>> {
  return {value : {typeRef : {kind: "reference", value : {moduleName : "whistle.propte.utils",name : "Pair2"}}, parameters : [texprT.value]}};
}

export enum EmptyU {
  unspecified,
}

const EmptyU_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.utils","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"unspecified","default":{"kind":"nothing"},"name":"unspecified","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"EmptyU","version":{"kind":"nothing"}}};

export const snEmptyU: ADL.ScopedName = {moduleName:"whistle.propte.utils", name:"EmptyU"};

export function texprEmptyU(): ADL.ATypeExpr<EmptyU> {
  return {value : {typeRef : {kind: "reference", value : snEmptyU}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.utils.Key" : Key_AST,
  "whistle.propte.utils.RatePerAnnum" : RatePerAnnum_AST,
  "whistle.propte.utils.RatePerMonth" : RatePerMonth_AST,
  "whistle.propte.utils.Pair2" : Pair2_AST,
  "whistle.propte.utils.EmptyU" : EmptyU_AST
};
