import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { Dropdown, Grid, Icon, DropdownProps } from "semantic-ui-react";
import { PropertyStore } from "./propertyStore";
import { PropertySelectionStore } from "./propertySelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { defaultFromUndefined } from "../../../utils/defaultFromUndefined";
import { isReturnStatement } from "typescript";

/** Props for the component */
export interface PropertySelectorProps {
  propertyStore: PropertyStore,
  propertySelectionStore: PropertySelectionStore
}

/**
 * Component for Selecting one or more properties (Whistle realestate properties)
 */
@observer
export class PropertySelectorMulti extends React.Component<PropertySelectorProps> {

  /** Renders the component */
  render() {
    const propertyStore = this.props.propertyStore;
    const options = propertyStore.selectorOptions.getValue() || [];
    const updating = propertyStore.selectorOptions.isUpdating();

    return (
      <Dropdown
        placeholder='Properties'
        fluid
        multiple
        search
        selection
        scrolling
        clearable
        options={options}
        loading={updating}
        onChange={this.onChange}
        value={this.getSelection()}
      />
    );
  }

  private getSelection() : DbKey<Property>[] {
    const vals = this.props.propertySelectionStore.propertySelection.getValue();
    if(vals === null) {
      return [];
    }
    return Array.from(vals.keys())
  }

  private assignSelection = (dbKeys: DbKey<Property>[])=>{
    const x = this.props.propertyStore.properties.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>dbKeys.find(i=>i===kv[0]));
      return new Map<DbKey<Property>, Property>(entries);
    });
    this.props.propertySelectionStore.propertySelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    console.log('propertySelectorMulti onChange', data);
    const dbKeys : DbKey<Property>[] = data.value as string[];
    this.assignSelection(dbKeys);
    return;
  }
}

@observer
export class PropertySelectorSingle extends React.Component<PropertySelectorProps> {

  /** Renders the component */
  render() {
    const propertyStore = this.props.propertyStore;
    const options = propertyStore.selectorOptions.getValue() || [];
    const updating = propertyStore.selectorOptions.isUpdating();

    return (
      <Dropdown
        placeholder='Properties'
        fluid
        search
        selection
        scrolling
        clearable
        options={options}
        loading={updating}
        onChange={this.onChange}
        value={this.getSelection()}
      />
    );
  }

  private getSelection() : DbKey<Property> {
    const val = this.props.propertySelectionStore.propertySelectionSingle;
    if(val.value === null) {
      return "";
    }
    return val.value.id;
  }

  private assignSelection = (dbKey: DbKey<Property>)=>{
    const x = this.props.propertyStore.properties.map(m=>{
      const entries = Array.from(m.entries()).filter(kv=>[dbKey].find(i=>i===kv[0]));
      return new Map<DbKey<Property>, Property>(entries);
    });
    this.props.propertySelectionStore.propertySelection.set(x.value);
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    const dbKey : DbKey<Property> = data.value as string;
    this.assignSelection(dbKey);
    return;
  }
}
