import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";

import { InputState } from "./text-field";
import { Input } from "semantic-ui-react";

const styles = require("./text-input.css");

/** Props for the component */
export interface TextInputProps {
  /** Additional classes on the container */
  className?: string;
  /** HTML input type */
  type:
    | "text"
    | "password"
    | "number"
    | "tel"
    | "email"
    | "date"
    | "datetime-local";
  /** Encapsulation of field state */
  state: InputState;
  /** Placeholder label */
  placeholder: string;
}

/**
 * Component for entering text.
 */
@observer
export class TextInput extends React.Component<TextInputProps> {
  /** Renders the component */
  render() {
    const { type, state } = this.props;
    const errorMsg = state.errorMsg();
    const value = state.value();

    return (
      <div className={classnames(styles.textInput, this.props.className)}>
        <Input
          className={classnames(
            styles.input,
            value.length > 0 && styles.nonEmpty,
            errorMsg && styles.error
          )}
          type={type}
          value={value}
          onChange={this.onChange}
          placeholder={this.props.placeholder}
        />
        <div className={styles.errorMessage}>
          {errorMsg ? <span>{errorMsg}</span> : null}
        </div>
      </div>
    );
  }

  /** Callback when the text changes */
  onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.props.state.onChange(e.target.value);
  };
}
