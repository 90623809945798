import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Button, Dropdown, Form, Input, Loader } from "semantic-ui-react";
import { DbKey, WithDbId } from "../../../adl-gen/common/db";
import { makeInvestmentStatus } from "../../../adl-gen/whistle/propte/category";
import { makePropertyByIndustry, makePropertyCategory, Property } from "../../../adl-gen/whistle/propte/property";
import { EmptyU } from "../../../adl-gen/whistle/propte/utils";
import { Updating } from "../../../models/updating";
import { Service } from "../../../service/service";
import { PropertySelectionStore } from "../../components/propertySelector/propertySelectionStore";
import { PropertyStore } from "../../components/propertySelector/propertyStore";

export interface Props {
  service: Service;
  propertyStore : PropertyStore;
  propertySelectionStore : PropertySelectionStore;
}

export type PropertyWithIdPartial = {
  property: Property;
  id?: DbKey<Property>;
}

@observer
export class PropertyCreate extends React.Component<Props> {

  @observable
  editing : PropertyWithIdPartial|null = null;

  @computed get selectedProperty() : Updating<PropertyWithIdPartial> {
    return this.props.propertySelectionStore.propertySelection.map(m=>{
      return Array.from(m.entries())
    })
    .then(m=>{
      if(m.length === 0) {
        return new Updating<PropertyWithIdPartial>();
      }
      else {
        return new Updating<PropertyWithIdPartial>({
          id: m[0][0],
          property: m[0][1]
        });
      }
    });
  }

  saving: Updating<PropertyWithIdPartial> = new Updating();

  render() {
    const propertyStore = this.props.propertyStore;
    const propertySelectionStore = this.props.propertySelectionStore;

    if (this.editing) {
      return <>
        <PropertyEdit editing={this.editing}/>
        <Button primary onClick={()=>{
          this.saveEdit();
        }}>Save</Button>
        <Button primary onClick={()=>{
          this.cancelEdit();
        }}>Cancel</Button>
        <Loader active={this.saving.isUpdating()}/>
      </>;
    }

    const selectedProperty = this.selectedProperty.value;
    if (selectedProperty === null) {
      return <>
        <Button primary onClick={()=>{
          this.startEditNew();
        }}>New</Button>
      </>;
    }
    return <>
      <Button primary onClick={()=>{
        this.startEditSelected(selectedProperty);
      }}>Edit</Button>
    </>;
  }

  startEditSelected(selectedProperty: PropertyWithIdPartial) {
    this.editing = {
      id: selectedProperty.id,
      property: {
        ...selectedProperty.property
      }
    };
  }

  startEditNew() {
    this.editing = {
      property:{
        key:"",
        name:"",
        category: makePropertyCategory({
          industry: makePropertyByIndustry("neighbourhoodShoppingCenter",{}),
          investmentStatus: makeInvestmentStatus("current",EmptyU.unspecified)
        })
      }
    };
  }

  @action
  cancelEdit() {
    this.editing = null;
  }

  @action
  saveEdit() {
    if(this.editing) {
      this.props.propertySelectionStore.propertySelection.set(new Map)

      let promise : Promise<WithDbId<Property>>;
      const value = this.editing.property;
      if(this.editing.id) {
        const id = this.editing.id;
        promise = this.props.service.updateProperty({
          id,
          value
        }).then(()=>{
          return {
            id,
            value
          };
        });
      } else {
        promise = this.props.service.createProperty(value)
        .then((id)=>{
          return {
            id,
            value
          };
        });
      }

      this.saving.update(
        promise.then(p=>{
          return {
            id: p.id,
            property: p.value
          };
        })
      );

      promise.then(p=>{
        // update global store of all properties
        this.props.propertyStore.update();

        // update as selected property
        const sel : Map<DbKey<Property>, Property> = new Map();
        sel.set(p.id, p.value);
        this.props.propertySelectionStore.propertySelection.set(sel);

        // clear editing
        this.editing = null;
      });
    }
  }
}

export interface PropertyEditProps {
  editing: PropertyWithIdPartial;
};

@observer
export class PropertyEdit extends React.Component<PropertyEditProps> {
  render() {
    const editing = this.props.editing;
    return <>
      <Form>
        <Form.Field>
          <label>Key</label>
          <Input type={"string"} value={editing.property.key} placeholder={"Key"} onChange={(ev,data)=>{
            editing.property.key = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Name</label>
          <Input type={"string"} value={editing.property.name} placeholder={"Name"} onChange={(ev,data)=>{
            editing.property.name = data.value;
          }}></Input>
        </Form.Field>
      </Form>
    </>;
  }
}
