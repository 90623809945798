import { observer } from "mobx-react";
import * as React from "react";
import { PropteStore } from "../../../stores/propte-store";
import { ScenarioSelector } from "../../components/scenarioSelector/scenarioSelector";
import { ScenarioTableCombined } from "../../components/scenarioTables/scenarioTableCombined";

export interface ScenarioTablesExportPageProps {
  propteStore: PropteStore;
}

@observer
export class ScenarioTablesExportPage extends React.Component<ScenarioTablesExportPageProps> {
  /** Renders the page */
  render() {
    const scenarioStore = this.props.propteStore.scenarioStore;
    const scenarioSelectionStore = this.props.propteStore.scenarioSelectionStore;
    const scenarioTablesStore = this.props.propteStore.scenarioTablesStore;

    return (
      <>
        <ScenarioSelector
          scenarioStore={scenarioStore}
          scenarioSelectionStore={scenarioSelectionStore}
        />

        <ScenarioTableCombined
          scenarioStore={scenarioStore}
          scenarioSelectionStore={scenarioSelectionStore}
          scenarioTablesStore={scenarioTablesStore}
        />
      </>
    );
  }
}
