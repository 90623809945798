import classnames from "classnames";
import { observer } from "mobx-react";
import * as React from "react";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { PropertySelectionStore } from "../propertySelector/propertySelectionStore";
import { Segment, Input, Grid, Divider, Icon, Header, Search, Label, Menu, Table } from "semantic-ui-react";
import { TenancyScheduleStore } from "./tenancyScheduleStore";
import DatePicker from "react-datepicker";
import { PropertySelectorMulti } from "../propertySelector/propertySelector";
import { PropertyStore } from "../propertySelector/propertyStore";
import { DbTenancySchedule } from "../../../adl-gen/whistle/propte/views";

/** Props for the component */
export interface TenancyScheduleProps {
  propertyStore: PropertyStore;
  tenancyScheduleStore: TenancyScheduleStore;
  propertySelectionStore: PropertySelectionStore;
}

type TenancyScheduleRow = {
  cells: string[]
};

type TenancyScheduleProperty = {
  propertyId: DbKey<Property>;
  rows: TenancyScheduleRow[];
};

type TenancyScheduleTable = {
  headers: string[],
  rowsByProperty: TenancyScheduleProperty[]
};
type GetCellStr = (data:DbTenancySchedule)=>string;

type GetField = {
  header: string,
  cell: GetCellStr,
  ordering: number
};

function IsGetField(x : GetField|null) : x is GetField {
  return x!==null;
}

/**
 * Component for TenancySchedule (table of info about leases)
 */
@observer
export class TenancySchedule extends React.Component<TenancyScheduleProps> {

  private tableData(data: Map<DbKey<Property>, DbTenancySchedule[]>|null) : TenancyScheduleTable {

    const fields : {[key in keyof DbTenancySchedule] : GetField|null}  = {
      propertyId: null,
      leaseId: null,
      leaseContractId: null,
      propertyKey: null,
      leaseKey: null,
      leaseContractKey: null,

      propertyName: {
        header: "Property",
        cell: (vals:DbTenancySchedule)=>vals.propertyName,
        ordering: 1
      },
      propertyCategory: null,
      shop: {
        header: "Shop",
        cell: (vals:DbTenancySchedule)=>vals.shop,
        ordering: 2
      },

      tenantName: {
        header: "Tenant",
        cell: (vals:DbTenancySchedule)=>vals.tenantName,
        ordering: 3,
      },


      baseRentPerAnnumPerSqm: {
        header: "Base Rent $p.a. / sqm",
        cell: (vals:DbTenancySchedule)=>{
          return `${Math.round(parseFloat(vals.baseRentPerAnnumPerSqm))}`;
        },
        ordering: 4
      },
      baseRentPerAnnum: {
        header: "Base Rent $p.a.",
        cell: (vals:DbTenancySchedule)=>vals.baseRentPerAnnum,
        ordering: 5
      },

      leaseCategory: null,
      grossLettableArea: {
        header: "GLA (sqm)",
        cell: (vals:DbTenancySchedule)=>vals.grossLettableArea,
        ordering: 6,
      },
      startDate: {
        header: "Start Date",
        cell: (vals:DbTenancySchedule)=>vals.startDate,
        ordering: 7,
      },
      expiryDate: {
        header: "Expiry Date",
        cell: (vals:DbTenancySchedule)=>vals.expiryDate,
        ordering: 8,
      },
      termYears: {
        header: "Term",
        cell: (vals:DbTenancySchedule)=>`${vals.termYears}`,
        ordering: 9,
      },
      count  : {
        header: "# options (years)",
        cell: (vals:DbTenancySchedule)=>{
          if(vals.count !== null && vals.years !== null) {
            return `${vals.count} (${vals.years})`;
          }
          return "";
        },
        ordering: 10,
      },
      years: null,
      nextRentReview:{
        header: "Next review",
        cell: (vals:DbTenancySchedule)=>vals.nextRentReview,
        ordering: 12
      },
    };

    const getFields = Object.keys(fields)
      .map(f=>{
        const x : GetField|null = fields[f];
        return x;
      })
      .filter(IsGetField)
      .sort((a,b)=>a.ordering - b.ordering);

    const headers : string[] = getFields.map(f=>f.header);
    const columns : GetCellStr[] = getFields.map(f=>f.cell);

    const tenancyScheduleRowsByProperty : TenancyScheduleProperty[] = Array.from(data?.entries() || []).map(kv=>{
      const dbKey = kv[0];
      const tsByProp : DbTenancySchedule[] = kv[1];
      const rows = Array.from(tsByProp.values()).map(d2=>{
        const tsRow : DbTenancySchedule = d2;

        const tsRowStr : TenancyScheduleRow = {
          cells: columns.map(colToStr=>colToStr(tsRow))
        };
        return tsRowStr;
      });
      return {
        propertyId: dbKey,
        rows
      };
    });

    return {
      headers,
      rowsByProperty: tenancyScheduleRowsByProperty
    };
  }

  /** Renders the component */
  render() {
    const propertySelection = this.props.propertySelectionStore.propertySelection.value;
    const dateSelection = this.props.tenancyScheduleStore.date.value;
    const tenancySchedule = this.props.tenancyScheduleStore.tenancySchedule.value;

    const propertySelectionReady = propertySelection!==null;
    const dateSelectionReady = dateSelection!==null;

    const tData = this.tableData(tenancySchedule);


    return <>

      <Grid columns={2} stackable textAlign='center'>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column>
            <Header icon>
              <Icon name='search' />
              Properties
            </Header>

            <PropertySelectorMulti
              propertyStore={this.props.propertyStore}
              propertySelectionStore={this.props.propertySelectionStore}
            />
          </Grid.Column>

          <Grid.Column>
            <Header>
              Date
            </Header>
            <DatePicker
              customInput={<Input></Input>}
              placeholderText="Click to select a date"
              dateFormat="yyyy/MM/dd"
              todayButton="Today"
              selected={dateSelection ? new Date(dateSelection) : null}
              onChange={(date:Date|null)=>{
                const x = date?.toISOString().split("T")[0] || null;
                this.props.tenancyScheduleStore.date.set(x);
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      {
        propertySelectionReady && dateSelection ? <>
        <Divider></Divider>
          <Table celled>
            <Table.Header>
              <Table.Row>
                {tData.headers.map((h,i)=><Table.HeaderCell key={i}>{h}</Table.HeaderCell>)}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {tData.rowsByProperty.map((property,i)=>
                property.rows.map((row,j)=>
                  <Table.Row key={`${i}-${j}`}>
                    {row.cells.map((cell,k)=>
                      <Table.Cell key={`${i}-${j}-${k}`}>{cell}</Table.Cell>
                    )}
                  </Table.Row>)
                )
              }
            </Table.Body>
          </Table>
        </> : null
      }
    </>;
  }
}
