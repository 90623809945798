import { LocalDate } from "../../../adl-gen/common"
import { Updating } from "../../../models/updating"
import { DbTenancySchedule, ScenarioMetrics } from "../../../adl-gen/whistle/propte/views";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { PropertySelectionStore } from "../propertySelector/propertySelectionStore";
import { computed } from "mobx";
import { Service } from "../../../service/service";
import { ScenarioSelectionStore } from "../scenarioSelector/scenarioSelectionStore";
import { Scenario } from "../../../adl-gen/whistle/propte/scenario";

/// Provide the scenario analysis data
export class ScenarioAnalysisStore {
  constructor(
    private service : Service,
    private scenarioSelectionStore : ScenarioSelectionStore) {
  }

  @computed get scenarioAnalysis() : Updating<Map<DbKey<Scenario>, Updating<ScenarioMetrics>>> {
    return this.scenarioSelectionStore.scenarioSelection.then(scenarios=>{
      const scenarioIds = Array.from(scenarios.keys());
      const map : Map<DbKey<Scenario>, Updating<ScenarioMetrics>> = new Map();
      scenarioIds.forEach(sId=>(
        map.set(sId, Updating.ofPromise(this.service.scenarioMetrics(sId)))
      ));
      return Updating.ofValue(map);
    });
  }

  /*
  private async loadScenarioAnalysis(scenarioIds : DbKey<Scenario>[]) : Promise<Map<DbKey<Scenario>, ScenarioMetrics>> {
    const result : Map<DbKey<Scenario>, ScenarioMetrics> = new Map();
    await Promise.all(scenarioIds.map((scenarioId) => this.loadScenarioAnalys(scenarioId, result)));
    return result;
  }
private async loadScenarioAnalys(scenarioId: DbKey<Scenario>, dest: Map<DbKey<Scenario>, ScenarioMetrics>) : Promise<void> {
    const tsrows = await this.service.scenarioMetrics(scenarioId);
    dest.set(scenarioId, tsrows);
    return;
  }

  */

}
