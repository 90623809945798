/* @generated from adl module whistle.propte.property */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';
import * as common_db from './../../common/db';
import * as whistle_propte_category from './category';
import * as whistle_propte_utils from './utils';

export interface Property {
  /**
   * User generated unique name for external ID purposes
   */
  key: whistle_propte_utils.Key<Property>;
  name: string;
  category: PropertyCategory;
}

export function makeProperty(
  input: {
    key: whistle_propte_utils.Key<Property>,
    name: string,
    category: PropertyCategory,
  }
): Property {
  return {
    key: input.key,
    name: input.name,
    category: input.category,
  };
}

const Property_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.property","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["key"]]}}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User generated unique name for external ID purposes\n"}],"serializedName":"key","default":{"kind":"nothing"},"name":"key","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.utils","name":"Key"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.property","name":"Property"}},"parameters":[]}]}},{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.property","name":"PropertyCategory"}},"parameters":[]}}]}},"name":"Property","version":{"kind":"nothing"}}};

export const snProperty: ADL.ScopedName = {moduleName:"whistle.propte.property", name:"Property"};

export function texprProperty(): ADL.ATypeExpr<Property> {
  return {value : {typeRef : {kind: "reference", value : snProperty}, parameters : []}};
}

export type PropertyId = common_db.DbKey<Property>;

const PropertyId_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.property","decl":{"annotations":[],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.property","name":"Property"}},"parameters":[]}]}}},"name":"PropertyId","version":{"kind":"nothing"}}};

export const snPropertyId: ADL.ScopedName = {moduleName:"whistle.propte.property", name:"PropertyId"};

export function texprPropertyId(): ADL.ATypeExpr<PropertyId> {
  return {value : {typeRef : {kind: "reference", value : snPropertyId}, parameters : []}};
}

export interface PropertyCategory {
  industry: PropertyByIndustry;
  investmentStatus: whistle_propte_category.InvestmentStatus;
}

export function makePropertyCategory(
  input: {
    industry: PropertyByIndustry,
    investmentStatus: whistle_propte_category.InvestmentStatus,
  }
): PropertyCategory {
  return {
    industry: input.industry,
    investmentStatus: input.investmentStatus,
  };
}

const PropertyCategory_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.property","decl":{"annotations":[],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"industry","default":{"kind":"nothing"},"name":"industry","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.property","name":"PropertyByIndustry"}},"parameters":[]}},{"annotations":[],"serializedName":"investmentStatus","default":{"kind":"nothing"},"name":"investmentStatus","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"InvestmentStatus"}},"parameters":[]}}]}},"name":"PropertyCategory","version":{"kind":"nothing"}}};

export const snPropertyCategory: ADL.ScopedName = {moduleName:"whistle.propte.property", name:"PropertyCategory"};

export function texprPropertyCategory(): ADL.ATypeExpr<PropertyCategory> {
  return {value : {typeRef : {kind: "reference", value : snPropertyCategory}, parameters : []}};
}

export interface PropertyByIndustry_NeighbourhoodShoppingCenter {
  kind: 'neighbourhoodShoppingCenter';
  value: common.Unit;
}
export interface PropertyByIndustry_Commercial {
  kind: 'commercial';
  value: common.Unit;
}
export interface PropertyByIndustry_Residential {
  kind: 'residential';
  value: common.Unit;
}
export interface PropertyByIndustry_Industrial {
  kind: 'industrial';
  value: common.Unit;
}

export type PropertyByIndustry = PropertyByIndustry_NeighbourhoodShoppingCenter | PropertyByIndustry_Commercial | PropertyByIndustry_Residential | PropertyByIndustry_Industrial;

export interface PropertyByIndustryOpts {
  neighbourhoodShoppingCenter: common.Unit;
  commercial: common.Unit;
  residential: common.Unit;
  industrial: common.Unit;
}

export function makePropertyByIndustry<K extends keyof PropertyByIndustryOpts>(kind: K, value: PropertyByIndustryOpts[K]) { return {kind, value}; }

const PropertyByIndustry_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.property","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"neighbourhoodShoppingCenter","default":{"kind":"nothing"},"name":"neighbourhoodShoppingCenter","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Unit"}},"parameters":[]}},{"annotations":[],"serializedName":"commercial","default":{"kind":"nothing"},"name":"commercial","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Unit"}},"parameters":[]}},{"annotations":[],"serializedName":"residential","default":{"kind":"nothing"},"name":"residential","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Unit"}},"parameters":[]}},{"annotations":[],"serializedName":"industrial","default":{"kind":"nothing"},"name":"industrial","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Unit"}},"parameters":[]}}]}},"name":"PropertyByIndustry","version":{"kind":"nothing"}}};

export const snPropertyByIndustry: ADL.ScopedName = {moduleName:"whistle.propte.property", name:"PropertyByIndustry"};

export function texprPropertyByIndustry(): ADL.ATypeExpr<PropertyByIndustry> {
  return {value : {typeRef : {kind: "reference", value : snPropertyByIndustry}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.property.Property" : Property_AST,
  "whistle.propte.property.PropertyId" : PropertyId_AST,
  "whistle.propte.property.PropertyCategory" : PropertyCategory_AST,
  "whistle.propte.property.PropertyByIndustry" : PropertyByIndustry_AST
};
