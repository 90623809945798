import { observer } from "mobx-react";
import * as React from "react";

import { MenuIcon } from "../../common/icon/icons";
import { Dropdown } from "semantic-ui-react";

const styles = require("./page-layout.css");

export interface LinkItem {
  text: string;
  onClick(): void;
}

export interface Menu {
  title: string;
  items: LinkItem[];
};

/** Props for the component */
export interface PageLayoutProps {
  /** Page title */
  title: string;

  /** Elements of the primary content area */
  children?: React.ReactNode;

  /** The link to be shown */
  link?: LinkItem;

  /** The menu */
  menu?: Menu
}

/**
 * Layout for typical pages.
 */
@observer
export class PageLayout extends React.Component<PageLayoutProps> {
  /** Renders the header */
  renderHeader() {
    return (
      <header className={styles.header}>
        <div>propte - {this.props.title}</div>
        {this.props.link? (
          <div className={styles.goLink} onClick={this.props.link.onClick}>
            {this.props.link.text}
          </div>
        ) : (
          undefined
        )}
        {this.props.menu? (
          <Dropdown icon={<MenuIcon/>} direction={'left'}>
            <Dropdown.Menu>
            {this.props.menu.items.map((i,ind) => //<a onClick={i.onClick} key={ind}>{i.text}</a>)
              <Dropdown.Item key={ind} onClick={i.onClick}>
                {i.text}
              </Dropdown.Item>
            )}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          undefined
        )}
      </header>
    );
  }

  /** Renders the content body */
  renderBody() {
    return <div className={styles.body}>{this.props.children}</div>;
  }

  /** Renders the page */
  render() {
    return (
      <div className={styles.page}>
        {this.renderHeader()}
        {this.renderBody()}
      </div>
    );
  }
}
