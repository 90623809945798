
import { Property } from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { DbKey } from '../../../adl-gen/common/db';
import { Scenario } from '../../../adl-gen/whistle/propte/scenario';

export class ScenarioSelectionStore {
  scenarioSelection: Updating<Map<DbKey<Scenario>, Scenario>> = new Updating();
};

