/* @generated from adl module whistle.propte.contact */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';
import * as common_db from './../../common/db';
import * as whistle_propte_utils from './utils';

/**
 * Contact for an external person or business
 * As an investor, bank etc.
 */
export interface Contact {
  /**
   * User generated unique name for external ID purposes
   */
  key: whistle_propte_utils.Key<Contact>;
  /**
   * Display name
   */
  name: string;
  category: common.Unit;
}

export function makeContact(
  input: {
    key: whistle_propte_utils.Key<Contact>,
    name: string,
    category: common.Unit,
  }
): Contact {
  return {
    key: input.key,
    name: input.name,
    category: input.category,
  };
}

const Contact_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.contact","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["key"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Contact for an external person or business\nAs an investor, bank etc.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"User generated unique name for external ID purposes\n"}],"serializedName":"key","default":{"kind":"nothing"},"name":"key","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.utils","name":"Key"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.contact","name":"Contact"}},"parameters":[]}]}},{"annotations":[{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Display name\n"}],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Unit"}},"parameters":[]}}]}},"name":"Contact","version":{"kind":"nothing"}}};

export const snContact: ADL.ScopedName = {moduleName:"whistle.propte.contact", name:"Contact"};

export function texprContact(): ADL.ATypeExpr<Contact> {
  return {value : {typeRef : {kind: "reference", value : snContact}, parameters : []}};
}

export type ContactId = common_db.DbKey<Contact>;

const ContactId_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.contact","decl":{"annotations":[],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.contact","name":"Contact"}},"parameters":[]}]}}},"name":"ContactId","version":{"kind":"nothing"}}};

export const snContactId: ADL.ScopedName = {moduleName:"whistle.propte.contact", name:"ContactId"};

export function texprContactId(): ADL.ATypeExpr<ContactId> {
  return {value : {typeRef : {kind: "reference", value : snContactId}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.contact.Contact" : Contact_AST,
  "whistle.propte.contact.ContactId" : ContactId_AST
};
