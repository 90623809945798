/** All the side effect css need to be imported first (disabled ordered imports) */
/* tslint:disable:no-import-side-effect no-submodule-imports ordered-imports*/
import "semantic-ui-css/semantic.min.css";
import "react-datepicker/dist/react-datepicker.min.css";


import { BrowserHttp } from "@hx/hx/service/browser-http";
import { HttpWithRetries } from "@hx/hx/service/retrying-http";
import * as React from "react";
import * as ReactDOM from "react-dom";
import Rollbar from "rollbar";

import {
  texprUiConfig,
  UiConfig
} from "./adl-gen/whistle/propte/uiconfig";
import { RESOLVER } from "./adl-gen/resolver";
import { createJsonBinding } from "./adl-gen/runtime/json";
import { ActionFactory, AdminStore, makePostAction } from "./adminui/store";
import { App } from "./app/app";
import { pathFromRoute } from "./routing/app-routes";
import { Router } from "./routing/router";
import { HttpService } from "./service/http-service";
import { HttpServiceError } from "./service/http-service-error";
import { StorageTokenManager } from "./service/token-manager";
import { AppStore } from "./stores/app-store";
import { IdentityStore } from "./stores/identity-store";
import { MessageBoardStore } from "./stores/message-board-store";
import { PropertyStore } from "./ui/components/propertySelector/propertyStore";
import { PropertySelectionStore } from "./ui/components/propertySelector/propertySelectionStore";
import { TenancyScheduleStore } from "./ui/components/tenancySchedule/tenancyScheduleStore";
import { PropteStore } from "./stores/propte-store";

declare const window: Window & {
  /** UI config JSON injected via index.html */
  UI_CONFIG: {};
};

// Fetch and parse the typed UiConfig record from the window
// This has been populated via a script tag in index.html
function loadUiConfig() : UiConfig {
  if (!window.UI_CONFIG) {
    throw new Error("UI_CONFIG not populated");
  }
  try {
    return createJsonBinding(RESOLVER, texprUiConfig()).fromJson(
      window.UI_CONFIG
    );
  } catch (e) {
    throw new Error("Could not parse UI_CONFIG: " + e);
  }
}

const UI_CONFIG: UiConfig = loadUiConfig();

// tslint:disable:no-console
console.log("Loading Propte");
console.log(`Environment: ${UI_CONFIG.environment}`);
console.log(`Release name: ${UI_CONFIG.releaseName}`);
// tslint:enable:no-console

/*
// Load Rollbar
// NOTE: We are following the NodeJS style configuration as opposed to the
// browser JS style because we do not want to load it via index.html and also
// have a handle on the created Rollbar object.
// See: https://docs.rollbar.com/docs/nodejs
const rollbar = (function(): Rollbar | undefined {
  if (UI_CONFIG.rollbar) {
    // tslint:disable-next-line:no-console
    console.log("Loading Rollbar");
    return new Rollbar({
      accessToken: UI_CONFIG.rollbar.accessToken,
      payload: {
        environment: UI_CONFIG.environment
      },

      // We register our own error handlers and send to Rollbar manually
      captureUncaught: false,
      captureUnhandledRejections: false
    });
  } else {
    return undefined;
  }
})();
*/

// Global error handler, includes errors that occur in the handler
window.addEventListener("error", (event: ErrorEvent) =>
  handleError("global error", event.error, event)
);

// Global unhandled rejection handler
window.addEventListener("unhandledrejection", (event: PromiseRejectionEvent) =>
  handleError("unhandled rejection", event.reason, event)
);

/**
 * Last resort error handler that alerts the user.
 */
function handleError(
  eventType: "global error" | "unhandled rejection",
  error: { publicMessage?: string },
  event: Event
) {
  // tslint:disable-next-line:no-console
  console.error(
    `${eventType}: ${String(error)}, error:`,
    error,
    "event:",
    event
  );
  /*if (rollbar) {
    rollbar.error(
      "" + String(error), // message
      error, // exception object
      { eventType, event } // custom payload
    );
  }*/
  if (error.publicMessage) {
    //window.alert(error.publicMessage);
    // tslint:disable-next-line: no-console
    console.log(error.publicMessage);
  } else {
    /*window.alert(
      "Sorry, an unhandled error has occurred. Please try refreshing and performing the action again."
    );*/
  }
}

const router = new Router(location.pathname, window.history, window);

let appStore: AppStore | undefined;

const tokenManager = new StorageTokenManager("jwt-access-token", localStorage);

// Configure http to retry up to 8 times, with an initial delay of 100ms, with
// exponential increaes.  The precise delays are randomised, but this will
// result in a typical max delay before failure of 25 seconds
const http = new HttpWithRetries(new BrowserHttp(), 8, 100);

const service = new HttpService(
  http,
  "/_a",
  RESOLVER,
  tokenManager,
  (error: HttpServiceError) => {
    // Check if it's an authorization error
    if (error.status === 401) {
      const publicMessageFragment = error.publicMessage
        ? `: ${error.publicMessage}`
        : "";
      window.alert(
        `Sorry, an authorization error occurred ${publicMessageFragment}. You will be logged out.`
      );
      if (appStore) {
        void appStore.navigateTo({ route: "logout" });
      }
      return;
    }
  }
);

// Fetch the server time for kicks
// Declare the actions we want to show in the adminui
const adminUiActions: ActionFactory[] = [
  makePostAction(service.postCreateUser),
  makePostAction(service.postNewMessage),
  makePostAction(service.postRecentMessages),
];

function getTableHref(table: string): string {
  return pathFromRoute({ route: "admin-table", table });
}

// Stores
const identityStore = new IdentityStore(tokenManager, service);

const propteStore = new PropteStore(service);

appStore = new AppStore(
  router,
  identityStore,
  service,
  propteStore,
  () => new MessageBoardStore(service),
  (table?: string) => {
    const store = new AdminStore(service, getTableHref, adminUiActions, RESOLVER);
    if (table) {
      void store.setTable(table);
    }
    return store;
  }
);

ReactDOM.render(
  <App store={appStore} uiconfig={UI_CONFIG} />,
  document.getElementById("root")
);
