/* @generated from adl module whistle.propte.accounting */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';
import * as common_db from './../../common/db';
import * as whistle_propte_category from './category';
import * as whistle_propte_scenario from './scenario';
import * as whistle_propte_utils from './utils';

/**
 * An accounting journal.   Separate Journals used for separate orgs and separate runs of simulations.
 */
export interface Journal {
  scenarioId: common_db.DbKey<whistle_propte_scenario.Scenario>;
  key: whistle_propte_utils.Key<Journal>;
  name: string;
  category: whistle_propte_category.JournalCategory;
}

export function makeJournal(
  input: {
    scenarioId: common_db.DbKey<whistle_propte_scenario.Scenario>,
    key: whistle_propte_utils.Key<Journal>,
    name: string,
    category: whistle_propte_category.JournalCategory,
  }
): Journal {
  return {
    scenarioId: input.scenarioId,
    key: input.key,
    name: input.name,
    category: input.category,
  };
}

const Journal_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["key"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An accounting journal.   Separate Journals used for separate orgs and separate runs of simulations.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"scenarioId","default":{"kind":"nothing"},"name":"scenarioId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.scenario","name":"Scenario"}},"parameters":[]}]}},{"annotations":[],"serializedName":"key","default":{"kind":"nothing"},"name":"key","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.utils","name":"Key"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Journal"}},"parameters":[]}]}},{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"JournalCategory"}},"parameters":[]}}]}},"name":"Journal","version":{"kind":"nothing"}}};

export const snJournal: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"Journal"};

export function texprJournal(): ADL.ATypeExpr<Journal> {
  return {value : {typeRef : {kind: "reference", value : snJournal}, parameters : []}};
}

/**
 * An accounting account
 */
export interface Account {
  journalId: common_db.DbKey<Journal>;
  key: whistle_propte_utils.Key<Account>;
  name: string;
  category: whistle_propte_category.AccountCategory;
}

export function makeAccount(
  input: {
    journalId: common_db.DbKey<Journal>,
    key: whistle_propte_utils.Key<Account>,
    name: string,
    category: whistle_propte_category.AccountCategory,
  }
): Account {
  return {
    journalId: input.journalId,
    key: input.key,
    name: input.name,
    category: input.category,
  };
}

const Account_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["journalId","key"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An accounting account\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalId","default":{"kind":"nothing"},"name":"journalId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Journal"}},"parameters":[]}]}},{"annotations":[],"serializedName":"key","default":{"kind":"nothing"},"name":"key","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.utils","name":"Key"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}},{"annotations":[],"serializedName":"name","default":{"kind":"nothing"},"name":"name","typeExpr":{"typeRef":{"kind":"primitive","value":"String"},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"AccountCategory"}},"parameters":[]}}]}},"name":"Account","version":{"kind":"nothing"}}};

export const snAccount: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"Account"};

export function texprAccount(): ADL.ATypeExpr<Account> {
  return {value : {typeRef : {kind: "reference", value : snAccount}, parameters : []}};
}

/**
 * An accounting journal entry.
 */
export interface JournalEntry {
  journalId: common_db.DbKey<Journal>;
  timestamp: common.LocalDateTime;
  category: whistle_propte_category.JournalEntryCategory;
}

export function makeJournalEntry(
  input: {
    journalId: common_db.DbKey<Journal>,
    timestamp: common.LocalDateTime,
    category: whistle_propte_category.JournalEntryCategory,
  }
): JournalEntry {
  return {
    journalId: input.journalId,
    timestamp: input.timestamp,
    category: input.category,
  };
}

const JournalEntry_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An accounting journal entry.\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalId","default":{"kind":"nothing"},"name":"journalId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Journal"}},"parameters":[]}]}},{"annotations":[],"serializedName":"timestamp","default":{"kind":"nothing"},"name":"timestamp","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDateTime"}},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"JournalEntryCategory"}},"parameters":[]}}]}},"name":"JournalEntry","version":{"kind":"nothing"}}};

export const snJournalEntry: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalEntry"};

export function texprJournalEntry(): ADL.ATypeExpr<JournalEntry> {
  return {value : {typeRef : {kind: "reference", value : snJournalEntry}, parameters : []}};
}

/**
 * An an account in a JournalEntry
 */
export interface JournalEntryAccount {
  journalEntryId: common_db.DbKey<JournalEntry>;
  accountId: common_db.DbKey<Account>;
  amount: common.BigDecimal;
  dbcr: DebitOrCredit;
}

export function makeJournalEntryAccount(
  input: {
    journalEntryId: common_db.DbKey<JournalEntry>,
    accountId: common_db.DbKey<Account>,
    amount: common.BigDecimal,
    dbcr: DebitOrCredit,
  }
): JournalEntryAccount {
  return {
    journalEntryId: input.journalEntryId,
    accountId: input.accountId,
    amount: input.amount,
    dbcr: input.dbcr,
  };
}

const JournalEntryAccount_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["journalEntryId","accountId"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An an account in a JournalEntry\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalEntryId","default":{"kind":"nothing"},"name":"journalEntryId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"JournalEntry"}},"parameters":[]}]}},{"annotations":[],"serializedName":"accountId","default":{"kind":"nothing"},"name":"accountId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}},{"annotations":[],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[],"serializedName":"dbcr","default":{"kind":"nothing"},"name":"dbcr","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"DebitOrCredit"}},"parameters":[]}}]}},"name":"JournalEntryAccount","version":{"kind":"nothing"}}};

export const snJournalEntryAccount: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalEntryAccount"};

export function texprJournalEntryAccount(): ADL.ATypeExpr<JournalEntryAccount> {
  return {value : {typeRef : {kind: "reference", value : snJournalEntryAccount}, parameters : []}};
}

/**
 * A snapshot of balances of accounts in a journal at a timestamp
 */
export interface JournalBalance {
  journalId: common_db.DbKey<Journal>;
  timestamp: common.LocalDateTime;
  category: whistle_propte_category.JournalBalanceCategory;
}

export function makeJournalBalance(
  input: {
    journalId: common_db.DbKey<Journal>,
    timestamp: common.LocalDateTime,
    category: whistle_propte_category.JournalBalanceCategory,
  }
): JournalBalance {
  return {
    journalId: input.journalId,
    timestamp: input.timestamp,
    category: input.category,
  };
}

const JournalBalance_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A snapshot of balances of accounts in a journal at a timestamp\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalId","default":{"kind":"nothing"},"name":"journalId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Journal"}},"parameters":[]}]}},{"annotations":[],"serializedName":"timestamp","default":{"kind":"nothing"},"name":"timestamp","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDateTime"}},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"JournalBalanceCategory"}},"parameters":[]}}]}},"name":"JournalBalance","version":{"kind":"nothing"}}};

export const snJournalBalance: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalBalance"};

export function texprJournalBalance(): ADL.ATypeExpr<JournalBalance> {
  return {value : {typeRef : {kind: "reference", value : snJournalBalance}, parameters : []}};
}

/**
 * An account in a JournalBalance
 */
export interface JournalBalanceAccount {
  journalBalanceId: common_db.DbKey<JournalBalance>;
  accountId: common_db.DbKey<Account>;
  amount: common.BigDecimal;
  dbcr: DebitOrCredit;
}

export function makeJournalBalanceAccount(
  input: {
    journalBalanceId: common_db.DbKey<JournalBalance>,
    accountId: common_db.DbKey<Account>,
    amount: common.BigDecimal,
    dbcr: DebitOrCredit,
  }
): JournalBalanceAccount {
  return {
    journalBalanceId: input.journalBalanceId,
    accountId: input.accountId,
    amount: input.amount,
    dbcr: input.dbcr,
  };
}

const JournalBalanceAccount_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["journalBalanceId","accountId"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An account in a JournalBalance\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalBalanceId","default":{"kind":"nothing"},"name":"journalBalanceId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"JournalBalance"}},"parameters":[]}]}},{"annotations":[],"serializedName":"accountId","default":{"kind":"nothing"},"name":"accountId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}},{"annotations":[],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[],"serializedName":"dbcr","default":{"kind":"nothing"},"name":"dbcr","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"DebitOrCredit"}},"parameters":[]}}]}},"name":"JournalBalanceAccount","version":{"kind":"nothing"}}};

export const snJournalBalanceAccount: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalBalanceAccount"};

export function texprJournalBalanceAccount(): ADL.ATypeExpr<JournalBalanceAccount> {
  return {value : {typeRef : {kind: "reference", value : snJournalBalanceAccount}, parameters : []}};
}

/**
 * A record of change over time in a journal
 */
export interface JournalDelta {
  journalId: common_db.DbKey<Journal>;
  timeFrom: common.LocalDateTime;
  timeTo: common.LocalDateTime;
  category: whistle_propte_category.JournalDeltaCategory;
}

export function makeJournalDelta(
  input: {
    journalId: common_db.DbKey<Journal>,
    timeFrom: common.LocalDateTime,
    timeTo: common.LocalDateTime,
    category: whistle_propte_category.JournalDeltaCategory,
  }
): JournalDelta {
  return {
    journalId: input.journalId,
    timeFrom: input.timeFrom,
    timeTo: input.timeTo,
    category: input.category,
  };
}

const JournalDelta_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"A record of change over time in a journal\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalId","default":{"kind":"nothing"},"name":"journalId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Journal"}},"parameters":[]}]}},{"annotations":[],"serializedName":"timeFrom","default":{"kind":"nothing"},"name":"timeFrom","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDateTime"}},"parameters":[]}},{"annotations":[],"serializedName":"timeTo","default":{"kind":"nothing"},"name":"timeTo","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"LocalDateTime"}},"parameters":[]}},{"annotations":[],"serializedName":"category","default":{"kind":"nothing"},"name":"category","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.category","name":"JournalDeltaCategory"}},"parameters":[]}}]}},"name":"JournalDelta","version":{"kind":"nothing"}}};

export const snJournalDelta: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalDelta"};

export function texprJournalDelta(): ADL.ATypeExpr<JournalDelta> {
  return {value : {typeRef : {kind: "reference", value : snJournalDelta}, parameters : []}};
}

/**
 * An account item in a JournalDelta
 */
export interface JournalDeltaAccount {
  journalDeltaId: common_db.DbKey<JournalDelta>;
  accountId: common_db.DbKey<Account>;
  amount: common.BigDecimal;
  dbcr: DebitOrCredit;
}

export function makeJournalDeltaAccount(
  input: {
    journalDeltaId: common_db.DbKey<JournalDelta>,
    accountId: common_db.DbKey<Account>,
    amount: common.BigDecimal,
    dbcr: DebitOrCredit,
  }
): JournalDeltaAccount {
  return {
    journalDeltaId: input.journalDeltaId,
    accountId: input.accountId,
    amount: input.amount,
    dbcr: input.dbcr,
  };
}

const JournalDeltaAccount_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["journalDeltaId","accountId"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"An account item in a JournalDelta\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"journalDeltaId","default":{"kind":"nothing"},"name":"journalDeltaId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"JournalDelta"}},"parameters":[]}]}},{"annotations":[],"serializedName":"accountId","default":{"kind":"nothing"},"name":"accountId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}},{"annotations":[],"serializedName":"amount","default":{"kind":"nothing"},"name":"amount","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"BigDecimal"}},"parameters":[]}},{"annotations":[],"serializedName":"dbcr","default":{"kind":"nothing"},"name":"dbcr","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"DebitOrCredit"}},"parameters":[]}}]}},"name":"JournalDeltaAccount","version":{"kind":"nothing"}}};

export const snJournalDeltaAccount: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"JournalDeltaAccount"};

export function texprJournalDeltaAccount(): ADL.ATypeExpr<JournalDeltaAccount> {
  return {value : {typeRef : {kind: "reference", value : snJournalDeltaAccount}, parameters : []}};
}

export enum DebitOrCredit {
  debit,
  credit,
}

const DebitOrCredit_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.accounting","decl":{"annotations":[],"type_":{"kind":"union_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"debit","default":{"kind":"nothing"},"name":"debit","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}},{"annotations":[],"serializedName":"credit","default":{"kind":"nothing"},"name":"credit","typeExpr":{"typeRef":{"kind":"primitive","value":"Void"},"parameters":[]}}]}},"name":"DebitOrCredit","version":{"kind":"nothing"}}};

export const snDebitOrCredit: ADL.ScopedName = {moduleName:"whistle.propte.accounting", name:"DebitOrCredit"};

export function texprDebitOrCredit(): ADL.ATypeExpr<DebitOrCredit> {
  return {value : {typeRef : {kind: "reference", value : snDebitOrCredit}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.accounting.Journal" : Journal_AST,
  "whistle.propte.accounting.Account" : Account_AST,
  "whistle.propte.accounting.JournalEntry" : JournalEntry_AST,
  "whistle.propte.accounting.JournalEntryAccount" : JournalEntryAccount_AST,
  "whistle.propte.accounting.JournalBalance" : JournalBalance_AST,
  "whistle.propte.accounting.JournalBalanceAccount" : JournalBalanceAccount_AST,
  "whistle.propte.accounting.JournalDelta" : JournalDelta_AST,
  "whistle.propte.accounting.JournalDeltaAccount" : JournalDeltaAccount_AST,
  "whistle.propte.accounting.DebitOrCredit" : DebitOrCredit_AST
};
