import { AdlEditor } from "@adltools/adl-editor";
import { action, observable, runInAction } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import * as SR from "semantic-ui-react";

import { Table } from "../../adl-gen/common/adminui/api";
import { ActionData, Loading } from "../store";

import * as styles from "./main-page.css";

export interface State {
  tables: Loading<Table[]>,
  actions: ActionData[],
};

export type Event
  = {kind: 'click-table', table: string}
  | {kind: 'done' }
  ;

export interface AdminUiMainPageProps {
  state: State;
  onEvent(event: Event): void;
}

type ModalState =
  | { kind: "show-form"; action: ActionData }
  | { kind: "show-progress"; action: ActionData }
  | { kind: "show-resp"; action: ActionData; resp: unknown };

@observer
export class AdminUiMainPage extends React.Component<AdminUiMainPageProps> {
  @observable
  modalState: ModalState | null = null;

  constructor(props: AdminUiMainPageProps) {
    super(props);
    this.modalState = null;
  }

  render() {
    const modal = this.modalState === null ? null : this.renderModal(this.modalState);
    return (
      <div className={styles.page}>
        {renderLoading(this.props.state.tables, this.renderTables)}
        {modal}
      </div>
    );
  }

  renderModal = (state: ModalState): JSX.Element => {
    switch (state.kind) {
      case "show-form": {
        const content = (
          <AdlEditor
            key="req"
            value={null}
            veditor={state.action.veditorReq}
            onCancel={this.onCancelModal}
            onApply={(req: unknown) => this.runRequest(state.action, req)}
            allowRaw={state.action.jsonBindingReq}
          />
        );
        return (
          <SR.Modal open={true} onClose={this.onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path}
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
      case "show-progress": {
        const content = <SR.Icon loading name="spinner" size="big" />;
        return (
          <SR.Modal open={true} onClose={this.onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path} results:
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
      case "show-resp": {
        const content = (
          <AdlEditor
            key="resp"
            value={state.resp}
            veditor={state.action.veditorResp}
            disabled={true}
            onClose={this.onCancelModal}
            allowRaw={state.action.jsonBindingResp}
          />
        );
        return (
          <SR.Modal open={true} onClose={this.onCancelModal}>
            <SR.Header>
              {state.action.method} {state.action.path} results:
            </SR.Header>
            <SR.Modal.Content style={{ margin: 0 }}>{content}</SR.Modal.Content>
          </SR.Modal>
        );
      }
    }
  };

  renderTables = (tables: Table[]): JSX.Element => {
    return (
      <div>
        <SR.Table celled>
          <SR.Table.Header>
            <SR.Table.Row>
              <SR.Table.HeaderCell>Data tables</SR.Table.HeaderCell>
              <SR.Table.HeaderCell />
            </SR.Table.Row>
          </SR.Table.Header>
          <SR.Table.Body>{tables.map(this.renderTable)}</SR.Table.Body>
        </SR.Table>
        <SR.Table celled>
          <SR.Table.Header>
            <SR.Table.Row>
              <SR.Table.HeaderCell>Actions</SR.Table.HeaderCell>
              <SR.Table.HeaderCell />
            </SR.Table.Row>
          </SR.Table.Header>
          <SR.Table.Body>{this.props.state.actions.map(this.renderAction)}</SR.Table.Body>
        </SR.Table>
      </div>
    );
  };

  renderTable = (table: Table): JSX.Element => {
    return (
      <SR.Table.Row key={table.name}>
        <SR.Table.Cell>
          <a onClick={() => this.props.onEvent({kind:'click-table', table:table.name})} className={styles.tablelink}>
            {table.name}
          </a>
        </SR.Table.Cell>
        <SR.Table.Cell>{table.description}</SR.Table.Cell>
      </SR.Table.Row>
    );
  };

  renderAction = (actionData: ActionData): JSX.Element | null => {
    return (
      <SR.Table.Row key={actionData.path}>
        <SR.Table.Cell>
          <SR.Button onClick={() => this.onClickAction(actionData)}>
            {actionData.method} {actionData.path}
          </SR.Button>
        </SR.Table.Cell>
        <SR.Table.Cell>{actionData.description}</SR.Table.Cell>
      </SR.Table.Row>
    );
  };

  @action
  onCancelModal = () => {
    this.modalState = null;
  };

  @action
  onClickAction = (actionData: ActionData) => {
    this.modalState = { kind: "show-form", action: actionData };
  };

  @action
  runRequest = async (actionData: ActionData, req: unknown) => {
    this.modalState = { kind: "show-progress", action: actionData };
    const resp = await actionData.call(req);
    runInAction(() => {
      this.modalState = { kind: "show-resp", action: actionData, resp };
    });
  };
}

export function renderLoading<T>(lt: Loading<T>, renderfn: (t: T) => JSX.Element): JSX.Element {
  if (lt.kind === "loading") {
    return <div>loading...</div>;
  } else {
    return renderfn(lt.value);
  }
}
