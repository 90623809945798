import { assertNever } from "@hx/util/types";
import { observer } from "mobx-react";
import * as React from "react";

import { UserProfile } from "../adl-gen/whistle/propte/api";
import { UiConfig } from "../adl-gen/whistle/propte/uiconfig";
import { AdminUiMainPage } from "../adminui/page/main-page";
import { AdminUiTablePage } from "../adminui/page/table-page";
import { AppStore } from "../stores/app-store";
import { LinkItem, Menu, PageLayout } from "../ui/layouts/page-layout/page-layout";
import { LoginPage } from "../ui/page/login-page/login-page";
import { LogoutPage } from "../ui/page/logout-page/logout-page";
import { MessageBoardPage } from "../ui/page/message-board/message-board-page";
import { TenancySchedulePage } from "../ui/page/tenancy-schedule/tenancy-schedule-page";
import { ScenarioAnalysisPage } from "../ui/page/scenario-analysis/scenario-analysis-page";
import { ScenarioTablesPage } from "../ui/page/scenario-tables/scenario-tables-page";
import { PropertyDataPage } from "../ui/page/property-data-page/property-data-page";
import { ScenarioTablesExportPage } from "../ui/page/scenario-tables-export/scenario-tables-export-page";
import { ScenarioGraphsPage } from "../ui/page/scenario-graphs/scenario-graphs-page";
import { TurnoverSummaryExportPage } from "../ui/page/turnover-summary-export/turnover-summary-export-page";


interface AppProps {
  /** Top level app state and actions */
  store: AppStore;
  /** UI configuration */
  uiconfig: UiConfig;
}

@observer
export class App extends React.Component<AppProps> {
  render() {
    const state = this.props.store.state;
    switch (state.kind) {
      case 'login':
      return (
        <PageLayout title="Login">
          <LoginPage
            loginError={this.props.store.identityStore.loginError()}
            onLogin={req => this.props.store.onLogin(req)}
          />
        </PageLayout>
      );

      case "main":
      return <PageLayout title="main" menu={createMenu(this.props.store, state.userProfile)}>
      </PageLayout>

      case "property-data":
        return <PageLayout title="Property Data" menu={createMenu(this.props.store, state.userProfile)}>
          <PropertyDataPage propteStore={state.propteStore}/>
        </PageLayout>

      case "tenancy-schedule":
        return <PageLayout title="Tenancy Schedule" menu={createMenu(this.props.store, state.userProfile)}>
          <TenancySchedulePage propteStore={state.propteStore}/>
        </PageLayout>

      case "turnover-summary":
        return <PageLayout title="Turnover Summary Export" menu={createMenu(this.props.store, state.userProfile)}>
          <TurnoverSummaryExportPage propteStore={state.propteStore}/>
        </PageLayout>

      case "scenario-analysis":
        return <PageLayout title="Scenario Analysis" menu={createMenu(this.props.store, state.userProfile)}>
          <ScenarioAnalysisPage propteStore={state.propteStore}/>
        </PageLayout>

      case "scenario-tables":
        return <PageLayout title="Scenario Tables" menu={createMenu(this.props.store, state.userProfile)}>
          <ScenarioTablesPage propteStore={state.propteStore}/>
        </PageLayout>

      case "scenario-graphs":
        return <PageLayout title="Scenario Graphs" menu={createMenu(this.props.store, state.userProfile)}>
          <ScenarioGraphsPage propteStore={state.propteStore}/>
        </PageLayout>

      case "scenario-tables-export":
        return <PageLayout title="Scenario Tables Export" menu={createMenu(this.props.store, state.userProfile)}>
          <ScenarioTablesExportPage propteStore={state.propteStore}/>
        </PageLayout>

      case "message-board":
      return (
        <PageLayout
          title="Messages"
          menu={createMenu(this.props.store, state.userProfile)}
        >
          <MessageBoardPage state={state.messageBoardStore.getViewState()} onEvent={state.messageBoardStore.onEvent} />
        </PageLayout>
      );

      case "logout":
      return (
        <PageLayout
          title="Logout"
          link={{
            text: "Login",
            onClick:() => this.props.store.navigateTo({ route: "login" })
          }}
        >
          <LogoutPage message={"You have been logged out"} />
        </PageLayout>
      );

      case "admin":
      return (
        <PageLayout
          title="Admin"
          menu={createMenu(this.props.store, state.userProfile)}
        >
          <AdminUiMainPage
            state={{
             tables: state.store.getTables(),
             actions: state.store.getActions(),
            }}
            onEvent={ (ev) => {
              switch (ev.kind) {
                case 'click-table':
                  void this.props.store.navigateTo({ route: "admin-table", table:ev.table });
                  break;
                case 'done':
                  this.props.store.onLogout()
              }
            }}
          />
        </PageLayout>
      );

      case "admin-table":
      return (
        <PageLayout
          title="Admin"
          link={{
            text: "Logout",
            onClick:this.props.store.onLogout
          }}
        >
          <AdminUiTablePage
            state={state.store.getTablePageState()}
            onEvent={ (ev) => {
              switch (ev.kind) {
                case 'done':
                  void this.props.store.navigateTo({route: "admin"});
                  break;
                default:
                  state.store.onTablePageEvent(ev)
              }
            }}
            key={state.table}
          />
        </PageLayout>
      );

      default:
        return assertNever(state, "unknown state");
    }
  }
}

function createMenu(store: AppStore, userProfile: UserProfile): Menu {
  const items : LinkItem[] = [];
  items.push({ text: "Main", onClick: () => store.navigateTo({route: "main"})});
  items.push({ text: "Property Data", onClick: () => store.navigateTo({route: "property-data"})});
  items.push({ text: "Tenancy Schedule", onClick: () => store.navigateTo({route: "tenancy-schedule"})});
  items.push({ text: "Turnover Summary", onClick: () => store.navigateTo({route: "turnover-summary"})});
  items.push({ text: "Scenario Analysis", onClick: () => store.navigateTo({route: "scenario-analysis"})});
  items.push({ text: "Scenario Tables", onClick: () => store.navigateTo({route: "scenario-tables"})});
  items.push({ text: "Scenario Graphs", onClick: () => store.navigateTo({route: "scenario-graphs"})});
  items.push({ text: "Scenario Tables Export", onClick: () => store.navigateTo({route: "scenario-tables-export"})});
  items.push({ text: "Messages", onClick: () => store.navigateTo({route: "message-board"})});
  if (userProfile.isAdmin) {
    items.push({ text: "Admin", onClick: () => store.navigateTo({route: "admin"})});
  }
  items.push({ text: "Logout", onClick: store.onLogout});

  return {
    title: userProfile.fullname,
    items
  }
};
