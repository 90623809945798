/** Stores tokens */
export interface TokenManager {
  getToken(): string;
  setToken(token: string): void;
}

/** Token manager backed by a browser storage implementation */
export class StorageTokenManager implements TokenManager {
  constructor(readonly tokenKey: string, readonly storage: Storage) {}

  getToken(): string {
    return this.storage.getItem(this.tokenKey) || "";
  }

  setToken(token: string): void {
    this.storage.setItem(this.tokenKey, token);
  }
}

/** token manager that just stores the token in an instance variable */
export class LocalTokenManager implements TokenManager {
  private token: string = "";

  getToken(): string {
    return this.token;
  }

  setToken(token: string): void {
    this.token = token;
  }
}
