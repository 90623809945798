import { observer } from "mobx-react";
import * as React from "react";
import { Button, Dropdown, DropdownProps, Menu } from "semantic-ui-react";
import { AccountCategoryStore } from "./accountCategoryStore";
import { AccountCategoriesSelectionStore } from "./accountCategorySelectionStore";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";

/** Props for the component */
export interface AccountCategorySelectorProps {
  accountCategoryStore: AccountCategoryStore,
  accountCategoriesSelectionStore: AccountCategoriesSelectionStore
  category: "profitAndLoss" | "balanceSheet";
}

/**
 * Component for Selecting one or more properties (Whistle realestate properties)
 */
@observer
export class AccountCategorySelector extends React.Component<AccountCategorySelectorProps> {

  componentDidMount() {
    this.selectAll();
  }

  /** Renders the component */
  render() {
    const options = this.getCurrentOptions();

    return <Menu fluid>
      <Menu.Item>
      Account Categories
      </Menu.Item>
        <Dropdown
          placeholder='Categories'
          multiple
          search
          selection
          scrolling
          clearable
          options={options}
          onChange={this.onChange}
          value={this.getSelection()}
        />
      <Menu.Item position={'right'}>
        <Button primary onClick={()=>this.selectAll()}>Select All</Button>
      </Menu.Item>
    </Menu>;
  }

  private getCurrentOptions() {
    const accountCategoryStore = this.props.accountCategoryStore;
    const options = this.props.category === 'balanceSheet' ?
      accountCategoryStore.getBalanceSheetCategories() :
      accountCategoryStore.getProfitAndLossCategories();
    return options;
  }

  selectAll(): void {
    const options = this.getCurrentOptions();
    this.assignSelection(options.map(x=>x.value));
  }

  private getSelection() : string[] {

    const vals = this.props.category === 'balanceSheet' ?
      this.props.accountCategoriesSelectionStore.balanceSheetCategories.getValue() :
      this.props.accountCategoriesSelectionStore.profitAndLossCategories.getValue();

    if(vals === null) {
      return [];
    }
    return vals;
  }

  private assignSelection = (vals: string[])=>{

    const valsSorted = vals.sort();

    if(this.props.category === 'balanceSheet') {
      this.props.accountCategoriesSelectionStore.balanceSheetCategories.set(valsSorted);
    } else {
      this.props.accountCategoriesSelectionStore.profitAndLossCategories.set(valsSorted);
    }
  }

  private onChange = (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps)=>{
    const cats : string[] = data.value as string[];
    this.assignSelection(cats);
  }
}
