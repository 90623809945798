import { observer } from "mobx-react";
import * as React from "react";
import { PropteStore } from "../../../stores/propte-store";
import { TenancySchedule } from "../../components/tenancySchedule/tenancySchedule";
import { ScenarioAnalysis } from "../../components/scenarioAnalysis/scenarioAnalysis";

export interface ScenarioAnalysisPageProps {
  propteStore: PropteStore;
}

/**
 *
 */
@observer
export class ScenarioAnalysisPage extends React.Component<ScenarioAnalysisPageProps> {
  /** Renders the page */
  render() {
    const scenarioStore = this.props.propteStore.scenarioStore;
    const scenarioSelectionStore = this.props.propteStore.scenarioSelectionStore;
    const scenarioAnalysisStore = this.props.propteStore.scenarioAnalysisStore;
    return (
      <ScenarioAnalysis
        scenarioStore={scenarioStore}
        scenarioSelectionStore={scenarioSelectionStore}
        scenarioAnalysisStore={scenarioAnalysisStore}
      />
    );
  }
}
