import { observer } from "mobx-react";
import * as React from "react";

import { LoginReq } from "../../../adl-gen/whistle/propte/api";
import {
  FieldState,
  newNonEmptyTextField
} from "../../widgets/text-input/text-field";
import { TextInput } from "../../widgets/text-input/text-input";
import { Button } from "semantic-ui-react";

const styles = require("./login-page.css");

export interface LoginPageProps {
  /** Error message to show upon login failure */
  loginError?: string;
  /** Callback to login */
  onLogin(req: LoginReq): void;
}

/**
 * Login page of the app.
 */
@observer
export class LoginPage extends React.Component<LoginPageProps> {
  email: FieldState<string> = newNonEmptyTextField();
  password: FieldState<string> = newNonEmptyTextField();

  /** Renders the page */
  render() {
    return (
      <div>
        <TextInput type="text" state={this.email} placeholder="Email" />
        <TextInput
          type="password"
          state={this.password}
          placeholder="Password"
        />
        <p className={styles.error}>{this.props.loginError}</p>
        <Button primary onClick={this.onLogin} className={styles.button}>
          Login
        </Button>

        <Button secondary onClick={this.onDevLogin} className={styles.button}>
          Dev Login
        </Button>
      </div>
    );
  }

  /** Returns the validated login form */
  getValidLoginForm(): LoginReq | undefined {
    const email = this.email.getValid();
    const password = this.password.getValid();

    if (email && password) {
      return {
        email,
        password
      };
    } else {
      return undefined;
    }
  }

  /** Callback to log in */
  onLogin = () => {
    const data = this.getValidLoginForm();
    if (data) {
      this.props.onLogin(data);
    }
  };

  onDevLogin = ()=> {
    this.props.onLogin({
      email: 'test@helixta.com.au',
      password: 'abcdefAA!!'
    });
  }
}
