import { LocalDate } from "../../../adl-gen/common"
import { Updating } from "../../../models/updating"
import { ScenarioPeriodMetricRow, AmountWithDbCr, ScenarioInstantMetricRow, ScenarioCombinedMetricRow, makeScenarioCombinedMetricRow, BalanceOrDelta } from "../../../adl-gen/whistle/propte/views";
import { DbKey } from "../../../adl-gen/common/db";
import { computed, reaction } from "mobx";
import { Service } from "../../../service/service";
import { ScenarioSelectionStore } from "../scenarioSelector/scenarioSelectionStore";
import { Scenario, TimeInterval } from "../../../adl-gen/whistle/propte/scenario";
import { AccountCategory, texprAccountCategory } from "../../../adl-gen/whistle/propte/category";
import { createJsonBinding } from "../../../adl-gen/runtime/json";
import { RESOLVER } from "../../../adl-gen/resolver";
import moment from 'moment';
import { DebitOrCredit } from "../../../adl-gen/whistle/propte/accounting";
import { capitalCase, noCase } from "change-case";
import {Big} from 'big.js'
import { PropteStore } from "../../../stores/propte-store";
import { AccountCategoriesSelectionStore } from "../categorySelector/accountCategorySelectionStore";

export  type RowLabel = {
  scenarioId: DbKey<Scenario>,
  accountCategory: AccountCategory;
};
export type ColLabel = {
  periodFrom : LocalDate;
  periodTo: LocalDate;
};
export type CellValue = AmountWithDbCr;

export type ScenarioPeriodMetricTable = {
  rowLabels: RowLabel[];
  colLabels: ColLabel[];
  values: CellValue[][];
};

export type InstantColLabel = {
  date : LocalDate;
};

export type ScenarioInstantMetricTable = {
  rowLabels: RowLabel[];
  colLabels: InstantColLabel[];
  values: CellValue[][];
};

export type SCMTableRow = {
  // Scenario (name)
  scenarioName: string;

  // Account (pipe sep str)
  account: string;

  // date
  date: string;

  // amount
  amount: string;

  // fy_year
  fYear: number;

  // Account 1
  account1: string|null;

  // Account 2
  account2: string|null;

  // Account 3
  account3: string|null;

  // Account 4
  account4: string|null;

  // Basis (Change or Balance)
  basis: "Change"|"Balance";
};

export type SCMTableCol = {
  header: string;
  get: (row: SCMTableRow)=>string;
};

export type ScenarioCombinedMetricTable = {
  columns: SCMTableCol[];
  rows: SCMTableRow[];
};

export type PlotData = {
  keys: {
    [key:string]: string;
  },

  data: MonthlyPlotData[];
}
export type MonthlyPlotData = {
  [key:string] : number|LocalDate
};

const accountCategoryJB = createJsonBinding(RESOLVER, texprAccountCategory());

const scenarioCombinedMetricTableColumns: SCMTableCol[] = [
  {
    header: "Scenario",
    get: (row:SCMTableRow)=>row.scenarioName
  },
  {
    header: "Account",
    get: (row:SCMTableRow)=>row.account
  },
  {
    header: "Date",
    get: (row:SCMTableRow)=>row.date
  },
  {
    header: "Amount",
    get: (row:SCMTableRow)=>`$${row.amount}`
  },
  {
    header: "FYear",
    get: (row:SCMTableRow)=> `${row.fYear}`
  },
  {
    header: "Account 1",
    get: (row:SCMTableRow)=>`${row.account1 ?? ""}`
  },
  {
    header: "Account 2",
    get: (row:SCMTableRow)=>`${row.account2 ?? ""}`
  },
  {
    header: "Account 3",
    get: (row:SCMTableRow)=>`${row.account3 ?? ""}`
  },
  /*{
    header: "Account 4",
    get: (row:SCMTableRow)=>`${row.account4 ?? ""}`,
  },*/
  {
    header: "Basis",
    get: (row:SCMTableRow)=>row.basis
  }
];

export const emptyScenarioCombinedMetricTable : ScenarioCombinedMetricTable = {
  columns: scenarioCombinedMetricTableColumns,
  rows: []
};

/// Provide the scenario tables data
export class ScenarioTablesStore {
  constructor(
    private service : Service,
    private scenarioSelectionStore : ScenarioSelectionStore,
    private accountCategoriesSelectionStore : AccountCategoriesSelectionStore
  ) {
  }

  /// Get scenario metrics at periods: Changes in balances
  @computed get scenarioPeriodMetrics() : Updating<Map<DbKey<Scenario>, ScenarioPeriodMetricRow[]>> {
    return this.scenarioSelectionStore.scenarioSelection.then(scenarios=>{
      const scenarioIds = Array.from(scenarios.keys());

      return Updating.ofPromise(this.service.scenarioPeriodMetrics({
        scenarioIds,
        interval: TimeInterval.month,   // only gives monthly data
      }).then(x=>{
        const map : Map<DbKey<Scenario>, ScenarioPeriodMetricRow[]> = new Map();
        x.forEach(i=>{
          const vs : ScenarioPeriodMetricRow[] = map.get(i.scenarioId) || [];
          vs.push(i);
          map.set(i.scenarioId, vs);
        });
        return map;
      }));
    });
  }

  /// Get scenario metrics at instants: Balances
  @computed get scenarioInstantMetrics() : Updating<Map<DbKey<Scenario>, ScenarioInstantMetricRow[]>> {
    return this.scenarioSelectionStore.scenarioSelection.then(scenarios=>{
      const scenarioIds = Array.from(scenarios.keys());

      return Updating.ofPromise(this.service.scenarioInstantMetrics({
        scenarioIds,
        interval: TimeInterval.month,   // only gives monthly data
      }).then(x=>{
        const map : Map<DbKey<Scenario>, ScenarioInstantMetricRow[]> = new Map();
        x.forEach(i=>{
          const vs : ScenarioInstantMetricRow[] = map.get(i.scenarioId) || [];
          vs.push(i);
          map.set(i.scenarioId, vs);
        });
        return map;
      }));
    });
  }

  /// Get scenario metrics at instants but containing both balances and deltas
  @computed get scenarioCombinedMetrics() : Updating<Map<DbKey<Scenario>, ScenarioCombinedMetricRow[]>> {
    return Updating.combine(this.scenarioInstantMetrics, this.scenarioPeriodMetrics, (im, pm)=>{

      const imm : Map<DbKey<Scenario>, ScenarioInstantMetricRow[]> = im;
      const pmm : Map<DbKey<Scenario>, ScenarioPeriodMetricRow[]> = pm;

      const cmm : Map<DbKey<Scenario>, ScenarioCombinedMetricRow[]> = new Map();

      const scenarioIds : Set<DbKey<Scenario>> = new Set([...imm.keys(), ...pmm.keys()]);
      for(const sId of scenarioIds) {
        const imRows = imm.get(sId) ?? [];
        const cmRows : ScenarioCombinedMetricRow[] = imRows.map(imRow=>makeScenarioCombinedMetricRow({
          scenarioId : imRow.scenarioId,
          date: imRow.date,
          accountCategory: imRow.accountCategory,
          amount: imRow.balance,
          basis: BalanceOrDelta.balance
        }));

        cmm.set(sId, [...(cmm.get(sId) ?? []), ...cmRows]);
      }
      for(const sId of scenarioIds) {
        const pmRows = pmm.get(sId) ?? [];
        const cmRows : ScenarioCombinedMetricRow[] = pmRows.map(pmRow=>makeScenarioCombinedMetricRow({
          scenarioId : pmRow.scenarioId,
          date: pmRow.periodTo,
          accountCategory: pmRow.accountCategory,
          amount: pmRow.netChange,
          basis: BalanceOrDelta.delta
        }));
        cmm.set(sId, [...(cmm.get(sId) ?? []), ...cmRows]);
      }

      return cmm;
    });
  }

  @computed get scenarioPeriodMetricTable() : Updating<ScenarioPeriodMetricTable> {
    return this.scenarioPeriodMetrics.map(spm=>{
      function accountCategoryStr(v: AccountCategory) : string {
        return JSON.stringify(accountCategoryJB.toJson(v));
      }
      function dataKeyStr(scenarioId : DbKey<Scenario>, accountCat: AccountCategory, fromDate: string) {
        return `${scenarioId}-${accountCategoryStr(accountCat)}-${fromDate}`;
      }


      const data : Map<string, AmountWithDbCr> = new Map();

      Array.from(spm.entries()).forEach(e=>{
        const scenarioId : DbKey<Scenario> = e[0];
        e[1].forEach(v=>{
          data.set(dataKeyStr(scenarioId, v.accountCategory, v.periodFrom),v.netChange);
        });
      });

      const scenarioIds : DbKey<Scenario>[] = Array.from(spm.keys());

      const accountCats = new Map<string, AccountCategory>(
        Array.from(spm.values())
        .flat()
        .map(j=>[accountCategoryStr(j.accountCategory), j.accountCategory] as const)
      );

      const allFromDates = Array.from(spm.values())
      .flatMap(i=>i.flatMap(j=> j.periodFrom ));

      const allToDates = Array.from(spm.values())
      .flatMap(i=>i.flatMap(j=> j.periodTo ));

      const startFromDate = allFromDates.map(d=>moment.utc(d)).reduce((a,b)=>a.isBefore(b) ? a : b, moment('9999-01-01') );
      const lastToDate = allToDates.map(d=>moment.utc(d)).reduce((a,b)=>a.isBefore(b) ? b : a, moment('0000-01-01') );

      const fromDates : moment.Moment[] = [];
      let curr = moment(startFromDate);
      while(curr.isBefore(lastToDate)) {
        fromDates.push(curr);
        curr = moment(curr).add({months:1});
      }

      const rowLabels : RowLabel[] = [];
      const colLabels : ColLabel[] = [];
      const cellValues: CellValue[][] = [];
      for(const scenarioId of scenarioIds) {
        for(const accountCat of Array.from(accountCats.entries())) {
          rowLabels.push({
            scenarioId,
            accountCategory: accountCat[1]
          });
        }
      }

      for(const fromDate of fromDates) {
        colLabels.push({
          periodFrom : fromDate.format('YYYY-MM-DD'),
          periodTo: moment(fromDate).add({months:1}).format('YYYY-MM-DD'),
        });
      }

      for(const scenarioId of scenarioIds) {
        for(const accountCat of Array.from(accountCats.entries())) {
          const cellValuesRow : CellValue[] = [];
          for(const fromDate of fromDates) {
            const dataKey = dataKeyStr(scenarioId, accountCat[1], fromDate.format('YYYY-MM-DD'));
            const v = data.get(dataKey) || {
              amount: "0",
              dbcr: DebitOrCredit.debit
            };
            cellValuesRow.push(v);
          }
          cellValues.push(cellValuesRow);
        }
      }

      return {
        rowLabels,
        colLabels,
        values:cellValues
      };
    });


  }

  @computed get scenarioInstantMetricTable() : Updating<ScenarioInstantMetricTable> {
    return this.scenarioInstantMetrics.map(sm=>{
      function accountCategoryStr(v: AccountCategory) : string {
        return JSON.stringify(accountCategoryJB.toJson(v));
      }
      function dataKeyStr(scenarioId : DbKey<Scenario>, accountCat: AccountCategory, fromDate: string) {
        return `${scenarioId}-${accountCategoryStr(accountCat)}-${fromDate}`;
      }


      const data : Map<string, AmountWithDbCr> = new Map();

      Array.from(sm.entries()).forEach(e=>{
        const scenarioId : DbKey<Scenario> = e[0];
        e[1].forEach(v=>{
          data.set(dataKeyStr(scenarioId, v.accountCategory, v.date),v.balance);
        });
      });

      const scenarioIds : DbKey<Scenario>[] = Array.from(sm.keys());

      const accountCats = new Map<string, AccountCategory>(
        Array.from(sm.values())
        .flat()
        .map(j=>[accountCategoryStr(j.accountCategory), j.accountCategory] as const)
      );

      const allDates = Array.from(sm.values())
      .flatMap(i=>i.flatMap(j=> j.date ));

      const startDate = allDates.map(d=>moment.utc(d)).reduce((a,b)=>a.isBefore(b) ? a : b, moment('9999-01-01') );
      const lastDate = allDates.map(d=>moment.utc(d)).reduce((a,b)=>a.isBefore(b) ? b : a, moment('0000-01-01') );

      const dates : moment.Moment[] = [];
      let curr = moment(startDate);
      while(!curr.isAfter(lastDate)) {
        dates.push(curr);
        curr = moment(curr).add({months:1});
      }

      const rowLabels : RowLabel[] = [];
      const colLabels : InstantColLabel[] = [];
      const cellValues: CellValue[][] = [];
      for(const scenarioId of scenarioIds) {
        for(const accountCat of Array.from(accountCats.entries())) {
          rowLabels.push({
            scenarioId,
            accountCategory: accountCat[1]
          });
        }
      }

      for(const date of dates) {
        colLabels.push({
          date : date.format('YYYY-MM-DD'),
        });
      }

      for(const scenarioId of scenarioIds) {
        for(const accountCat of Array.from(accountCats.entries())) {
          const cellValuesRow : CellValue[] = [];
          for(const date of dates) {
            const dataKey = dataKeyStr(scenarioId, accountCat[1], date.format('YYYY-MM-DD'));
            const v = data.get(dataKey) || {
              amount: "0",
              dbcr: DebitOrCredit.debit
            };
            cellValuesRow.push(v);
          }
          cellValues.push(cellValuesRow);
        }
      }

      return {
        rowLabels,
        colLabels,
        values:cellValues
      };
    });


  }

  @computed get allScenariosBalanceMonthly() : Updating<PlotData> {
    return Updating.combine(
      this.scenarioSelectionStore.scenarioSelection,
      this.scenarioInstantMetricTable,
      (scenarioSelection, scenarioInstantMetricTable)=>{
        function rowLabelToStr(rowLabel: RowLabel) {
          let accCatStr : string;
          switch (rowLabel.accountCategory.kind) {
            case 'asset':
            case 'liability':
            case 'equity':
              accCatStr = accountCategoryToStr(rowLabel.accountCategory);
              break;
            default:
              accCatStr = "accum. profit/loss";
              break;
          }
          const scenarioName = scenarioSelection.get(rowLabel.scenarioId)!.name;
          return `${capitalCase(scenarioName)}: ${accCatStr}`;
        }

        const simt = scenarioInstantMetricTable;
        const result : PlotData = {
          keys: {},
          data: [],
        };
        const vals : MonthlyPlotData[] = result.data;

        // tslint:disable-next-line: prefer-for-of
        for(let r=0; r < simt.rowLabels.length; ++r) {
          const rowLabel = simt.rowLabels[r];
          result.keys[rowLabelToStr(rowLabel)] = "...";
        }


        // tslint:disable-next-line: prefer-for-of
        for(let c=0; c < simt.colLabels.length; ++c) {
          const colLabel = simt.colLabels[c];
          const e : MonthlyPlotData = {};

          // tslint:disable-next-line: no-string-literal
          e["date"] = colLabel.date;

          // tslint:disable-next-line: prefer-for-of
          for(let r=0; r < simt.rowLabels.length; ++r) {
            const rowLabelStr = rowLabelToStr(simt.rowLabels[r]);
            const v = simt.values[r][c];
            const creditAmount = parseFloat(amountWithDbCrToStr(v));

            e[rowLabelStr] = (e[rowLabelStr] as number || 0.0) + creditAmount;
          }

          vals.push(e);
        }

        return result;
      }
    );
  }

  @computed get allScenariosDeltaMonthly() : Updating<PlotData> {
    return Updating.combine(
      this.scenarioSelectionStore.scenarioSelection,
      this.scenarioPeriodMetricTable,
      (scenarioSelection, scenarioPeriodMetricTable)=>{
        function rowLabelToStr(rowLabel: RowLabel) {
          let accCatStr : string;
          switch (rowLabel.accountCategory.kind) {
            case 'asset':
            case 'liability':
            case 'equity':
              return null;
            default:
              accCatStr = accountCategoryToStr(rowLabel.accountCategory);
              break;
          }
          const scenarioName = scenarioSelection.get(rowLabel.scenarioId)!.name;
          return `${capitalCase(scenarioName)}: ${accCatStr}`;
        }

        const spmt = scenarioPeriodMetricTable;
        const result : PlotData = {
          keys: {},
          data: [],
        };
        const vals : MonthlyPlotData[] = result.data;

        // tslint:disable-next-line: prefer-for-of
        for(let r=0; r < spmt.rowLabels.length; ++r) {
          const rowLabel = spmt.rowLabels[r];
          const labelStr = rowLabelToStr(rowLabel);
          if(labelStr !== null) {
            result.keys[labelStr] = "...";
          }
        }


        // tslint:disable-next-line: prefer-for-of
        for(let c=0; c < spmt.colLabels.length; ++c) {
          const colLabel = spmt.colLabels[c];
          const e : MonthlyPlotData = {};

          // tslint:disable-next-line: no-string-literal
          e["date"] = colLabel.periodFrom;

          // tslint:disable-next-line: prefer-for-of
          for(let r=0; r < spmt.rowLabels.length; ++r) {
            const rowLabelStr = rowLabelToStr(spmt.rowLabels[r]);
            const v = spmt.values[r][c];
            const creditAmount = parseFloat(amountWithDbCrToStr(v));

            if(rowLabelStr !== null) {
              e[rowLabelStr] = (e[rowLabelStr] as number || 0.0) + creditAmount;
            }
          }

          vals.push(e);
        }

        return result;
      }
    );
  }

  @computed get scenarioCombinedMetricsTable() : Updating<ScenarioCombinedMetricTable> {
    return this.scenarioCombinedMetrics.map(scm=>{
      const scenarioSelection : Map<DbKey<Scenario>, Scenario> = this.scenarioSelectionStore.scenarioSelection.value!;

      const scmMap : Map<DbKey<Scenario>, ScenarioCombinedMetricRow[]> = scm;
      const scmTable : ScenarioCombinedMetricTable = {
        columns: scenarioCombinedMetricTableColumns,
        rows:[]
      };

      for(const sId of scmMap.keys()) {
        const scmRows : ScenarioCombinedMetricRow[] = scmMap.get(sId) ?? [];

        const tableRows : SCMTableRow[] = scmRows.map(scmRow=>({
          // Scenario (name)
          scenarioName: scenarioSelection.get(scmRow.scenarioId)!.name,

          // Account (pipe sep str)
          account: accountCategoryToStr(scmRow.accountCategory),

          // date
          date: moment.utc(scmRow.date).format('YYYY-MM-DD'),

          // amount
          amount: amountWithDbCrToStr(scmRow.amount),

          // fy_year
          fYear: financialYear(moment.utc(scmRow.date)),

          // Account 1
          account1: accountCategoryToStrArr(scmRow.accountCategory)[0] ?? null,

          // Account 2
          account2: accountCategoryToStrArr(scmRow.accountCategory)[1] ?? null,

          // Account 3
          account3: accountCategoryToStrArr(scmRow.accountCategory)[2] ?? null,

          // Account 4
          account4: accountCategoryToStrArr(scmRow.accountCategory)[3] ?? null,

          // Basis (Change or Balance)
          basis: scmRow.basis === BalanceOrDelta.balance ? "Balance" : "Change"
        }));

        scmTable.rows.push(...tableRows);
      }

      // Sort by scenario, date, account
      scmTable.rows.sort((a,b)=>{
        function compare(i: SCMTableRow) : string {
          return `${i.scenarioName} ${i.date} ${i.account}`;
        }

        const aV = compare(a);
        const bV = compare(b);
        return aV.localeCompare(bV);
      });

      return scmTable;
    });
  }

  @computed get scenariosDeltaAccountConventional() : Updating<PlotData> {

    return Updating.combine3(
      this.scenarioSelectionStore.scenarioSelection,
      this.scenarioPeriodMetricTable,
      this.accountCategoriesSelectionStore.profitAndLossCategories,

      (
        scenarioSelection,
        scenarioPeriodMetricTable,
        categories
      )=>{

        /// get plot lines label (or null to exclude)
        function rowLabelToStr(rowLabel: RowLabel) {
          let accCatStr : string;
          switch (rowLabel.accountCategory.kind) {
            case 'asset':
            case 'liability':
            case 'equity':
              return null;
            default:
              accCatStr = accountCategoryToStr(rowLabel.accountCategory);

              /// crude method of filtering graph plot lines by accountCategoriesSelectionStore.profitAndLossCategories:
              const x = accountCategoryToKey(rowLabel.accountCategory);
              if(!categories.includes(x)) {
                return null;
              }
              break;
          }

          const scenarioName = scenarioSelection.get(rowLabel.scenarioId)!.name;
          return `${capitalCase(scenarioName)}: ${accCatStr}`;
        }

        const spmt = scenarioPeriodMetricTable;
        const result : PlotData = {
          keys: {},
          data: [],
        };
        const vals : MonthlyPlotData[] = result.data;

        // tslint:disable-next-line: prefer-for-of
        for(let r=0; r < spmt.rowLabels.length; ++r) {
          const rowLabel = spmt.rowLabels[r];
          const labelStr = rowLabelToStr(rowLabel);
          if(labelStr !== null) {
            result.keys[labelStr] = "...";
          }
        }

        // tslint:disable-next-line: prefer-for-of
        for(let c=0; c < spmt.colLabels.length; ++c) {
          const colLabel = spmt.colLabels[c];
          const e : MonthlyPlotData = {};

          // tslint:disable-next-line: no-string-literal
          e["date"] = colLabel.periodFrom;

          // tslint:disable-next-line: prefer-for-of
          for(let r=0; r < spmt.rowLabels.length; ++r) {
            const rowLabel = spmt.rowLabels[r];
            const rowLabelStr = rowLabelToStr(rowLabel);
            const v = spmt.values[r][c];
            const creditAmount = parseFloat(amountWithDbCrToStr(v));

            // Show values in form of positive for the conventional accounting-side that account uses
            let flipSign = 1;
            switch (rowLabel.accountCategory.kind) {
              case "asset":
                flipSign = -1;
                break;
              case "liability":
                flipSign = 1;
                break;
              case "equity":
                flipSign = -1;
                break;
              case "revenue":
                flipSign = 1;
                break;
              case "expense":
                flipSign = -1;
                break;
            }

            if(rowLabelStr !== null) {
              e[rowLabelStr] = (e[rowLabelStr] as number || 0.0) + flipSign*creditAmount;
            }
          }

          vals.push(e);
        }

        return result;
      }
    );
  }

  @computed get scenarioBalancesConventional() : Updating<PlotData> {

    return Updating.combine3(
      this.scenarioSelectionStore.scenarioSelection,
      this.scenarioInstantMetricTable,
      this.accountCategoriesSelectionStore.balanceSheetCategories,
      (
        scenarioSelection,
        scenarioInstantMetricTable,
        categories
      )=>{

        /// get plot lines label (or null to exclude)
        function rowLabelToStr(rowLabel: RowLabel) {
          let accCatStr : string;
          switch (rowLabel.accountCategory.kind) {
            case 'asset':
            case 'liability':
            case 'equity':
              accCatStr = accountCategoryToStr(rowLabel.accountCategory);

              /// crude method of filtering graph plot lines
              const x = accountCategoryToKey(rowLabel.accountCategory);
              if(!categories.includes(x)) {
                return null;
              }
              break
            default:
              accCatStr = "accum. profit/loss";
              if(!categories.includes(accCatStr)) {
                return null;
              }
              break;
          }

          const scenarioName = scenarioSelection.get(rowLabel.scenarioId)!.name;
          return `${capitalCase(scenarioName)}: ${accCatStr}`;
        }

        const simt = scenarioInstantMetricTable;
        const result : PlotData = {
          keys: {},
          data: [],
        };
        const vals : MonthlyPlotData[] = result.data;

        // tslint:disable-next-line: prefer-for-of
        for(let r=0; r < simt.rowLabels.length; ++r) {
          const rowLabel = simt.rowLabels[r];
          const labelStr = rowLabelToStr(rowLabel)
          if(labelStr !== null) {
            result.keys[labelStr] = "...";
          }
        }

        // tslint:disable-next-line: prefer-for-of
        for(let c=0; c < simt.colLabels.length; ++c) {
          const colLabel = simt.colLabels[c];
          const e : MonthlyPlotData = {};

          // tslint:disable-next-line: no-string-literal
          e["date"] = colLabel.date;

          // tslint:disable-next-line: prefer-for-of
          for(let r=0; r < simt.rowLabels.length; ++r) {
            const rowLabel = simt.rowLabels[r];
            const rowLabelStr = rowLabelToStr(rowLabel);
            const v = simt.values[r][c];
            const creditAmount = parseFloat(amountWithDbCrToStr(v));

            // Show values in form of positive for the conventional accounting-side that account uses
            let flipSign = 1;
            switch (rowLabel.accountCategory.kind) {
              case "asset":
                flipSign = -1;
                break;
              case "liability":
                flipSign = 1;
                break;
              case "equity":

                switch (rowLabel.accountCategory.value.kind) {
                  case 'distribution':
                    flipSign = -1;
                    break;
                  case 'internal':
                    flipSign = -1;
                    break;
                  case 'investment':
                    flipSign = 1;
                    break;
                }


                break;
              case "revenue":
                flipSign = 1;
                break;
              case "expense":
                flipSign = -1;
                break;
            }

            if(rowLabelStr !== null) {
              e[rowLabelStr] = (e[rowLabelStr] as number || 0.0) + flipSign*creditAmount;
            }
          }

          vals.push(e);
        }

        return result;
      }
    );
  }
  /*/// crude method of filtering graph plot lines by accountCategoriesSelectionStore.profitAndLossCategories:
              const x = accountCategoryToKey(rowLabel.accountCategory);
              if(!categories.includes(x)) {
                return null;
              }
  */

}

// Render AmountWithDbCr to (numeric) string (credits positive)
export function amountWithDbCrToStr(v: AmountWithDbCr) : string {
  const amountStr = new Big(v.amount).toFixed(2);
  if(amountStr === '0.00') {
    return amountStr;
  }

  return `${v.dbcr === DebitOrCredit.credit ? '' : '-'}${amountStr}`;
}

export function accountCategoryToStrArr(ac: AccountCategory) : string[] {
  return JSON.stringify(accountCategoryJB.toJson(ac))
    .replace("unspecified","")
    .split(/[{}]/)
    .filter(i=>i.length>0)
    .map(i=>capitalCase(i));
}

export function accountCategoryToStr(ac: AccountCategory) : string {
  return accountCategoryToStrArr(ac).join(' | ')
}


export function accountCategoryToKey(ac: AccountCategory) : string {
  return accountCategoryToStrArr(ac).map(i=>noCase(i).trim()).join('|')
}

/// Financial year of a date - assuming AU (1st-July) based FY and number is the year of the 1st-July date.
export function financialYear(date: moment.Moment) : number {
  return moment(date).subtract({months:6}).year();
}

