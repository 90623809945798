import { observer } from "mobx-react";
import * as React from "react";
import { PropteStore } from "../../../stores/propte-store";
import { ScenarioTables } from "../../components/scenarioTables/scenarioTables";
import { ScenarioBalanceTable } from "../../components/scenarioBalanceTables/scenarioBalanceTables";
import { ScenarioSelector } from "../../components/scenarioSelector/scenarioSelector";
import { LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import { Header, Segment } from "semantic-ui-react";
import { AccountCategorySelector } from "../../components/categorySelector/accountCategorySelector";


export interface ScenarioGraphsPageProps {
  propteStore: PropteStore;
}

/**
 *
 */
@observer
export class ScenarioGraphsPage extends React.Component<ScenarioGraphsPageProps> {
  /** Renders the page */
  render() {
    const scenarioStore = this.props.propteStore.scenarioStore;
    const scenarioSelectionStore = this.props.propteStore.scenarioSelectionStore;
    const scenarioTablesStore = this.props.propteStore.scenarioTablesStore;

    const accountCategoryStore = this.props.propteStore.accountCategoryStore;
    const accountCategoriesSelectionStore = this.props.propteStore.accountCategoriesSelectionStore;

    const balancesMonthly = scenarioTablesStore.scenarioBalancesConventional.value;
    const balancesMonthlyUpdating = scenarioTablesStore.scenarioBalancesConventional.isUpdating();

    const deltasMonthly = scenarioTablesStore.scenariosDeltaAccountConventional.value;
    const deltasMonthlyUpdating = scenarioTablesStore.scenariosDeltaAccountConventional.isUpdating();

    const colors = [
      "#B70127",
      "#C57302",
      "#87D304",
      "#05E228",
      "#07F0E4",
      "#094BFF",
    ];

    return (
      <>
        <ScenarioSelector
          scenarioStore={scenarioStore}
          scenarioSelectionStore={scenarioSelectionStore}
        />



        <Header>Profit and Loss Accounts</Header>
        <AccountCategorySelector
          accountCategoryStore={accountCategoryStore}
          accountCategoriesSelectionStore={accountCategoriesSelectionStore}
          category={'profitAndLoss'}
        />
        <Segment loading={deltasMonthlyUpdating}>
          {deltasMonthly ?
          <LineChart width={1500} height={500} data={deltasMonthly.data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend layout={'vertical'} verticalAlign={'middle'} align={'right'}/>
            {
              Object.keys(deltasMonthly.keys).map((k,i)=>{
                return <Line key={i} type="linear" isAnimationActive={false} dot={false} dataKey={k} stroke={colors[i % colors.length]} />
              })
            }
          </LineChart> : undefined}
        </Segment>


        <Header>Balance Sheet Accounts</Header>
        <AccountCategorySelector
          accountCategoryStore={accountCategoryStore}
          accountCategoriesSelectionStore={accountCategoriesSelectionStore}
          category={'balanceSheet'}
        />
        <Segment loading={balancesMonthlyUpdating}>
        {
          balancesMonthly ?
        <LineChart width={1500} height={500} data={balancesMonthly.data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend layout={'vertical'} verticalAlign={'middle'} align={'right'}/>
          {
            Object.keys(balancesMonthly.keys).map((k,i)=>{
              return <Line key={i} type="linear" isAnimationActive={false} dot={false} dataKey={k} stroke={colors[i % colors.length]} />
            })
          }
        </LineChart> : undefined
        }
        </Segment>
      </>
    );
  }
}
