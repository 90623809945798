import classnames from "classnames";
import {
  action,
  autorun,
  IObservableValue,
  observable,
  runInAction
} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { Loading } from "../../../models/loading2";

const styles = require("./loader.css");

/**
 * Spinner over the viewport indicating something is loading.
 */
@observer
export class Spinner extends React.Component {
  /**
   * Whether the "render the overlay" lock has been taken across all spinners.
   * We only want to render the overlay once to avoid stacked blurring.
   */
  static overlayLockFree: IObservableValue<boolean> = observable.box(true);

  /** Whether this instance should render the overlay */
  @observable.ref hasOverlayLock: boolean = false;

  /** Disposer for the autorun to obtain the overlay rendering lock */
  disposeOverlayLockAutorun: () => void;

  /** Take overlay rendering lock if it is available and watch for changes */
  @action componentDidMount() {
    this.disposeOverlayLockAutorun = autorun(() => {
      if (Spinner.overlayLockFree.get()) {
        runInAction(() => {
          this.hasOverlayLock = true;
          Spinner.overlayLockFree.set(false);
        });
      }
    });
  }

  /** Release overlay rendering lock if it is held */
  @action componentWillUnmount() {
    this.disposeOverlayLockAutorun();
    if (this.hasOverlayLock) {
      Spinner.overlayLockFree.set(true);
    }
  }

  /** Renders the component */
  render() {
    const className = classnames(
      styles.loader,
      /*
       * NOTE: The reason of always rendering the loader (as opposed to not
       * rendering if the lock is not held) is to cater for the common use case
       * of loading multiple pieces of data concurrently, which may complete at
       * different times. All of the loaders would have the same mount time and
       * hence the same rotational position of their spinners. So as their
       * siblings disappear the remaining spinners would continue to overlay
       * each other exactly.
       */
      this.hasOverlayLock ? styles.withOverlay : undefined
    );
    return (
      <div className={className}>
        <svg className={styles.circular} viewBox="25 25 50 50">
          <circle
            className={styles.path}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </div>
    );
  }
}



/**
 * Shows a loading placeholder until data has loaded.
 */
export function renderLoading<T>(loading: Loading<T>, renderf: (v:T) => JSX.Element): JSX.Element {
  if (loading.kind === 'loading') {
    return <Spinner />;
  } else {
    return renderf(loading.value);
  }
}
