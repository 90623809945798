
import {Property} from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { ObservableMap, computed } from 'mobx';
import { DbKey } from '../../../adl-gen/common/db';
import { Service } from '../../../service/service';
import { makeTableQuery } from '../../../adl-gen/common/tabular';
import { Scenario } from '../../../adl-gen/whistle/propte/scenario';

export type SelectorOption = {
  key: string;
  text: string;
  value: DbKey<Scenario>
};

export class ScenarioStore {
  scenarios: Updating<Map<DbKey<Scenario>, Scenario>> = new Updating();

  constructor(private service: Service) {
    // init with an update at first
    this.update();
  }

  update() {
    this.scenarios.update(this._update());
  }

  private async _update() : Promise<Map<DbKey<Scenario>, Scenario>> {
    const pag = await this.service.queryScenario(makeTableQuery({}));
    const map = new Map<DbKey<Scenario>, Scenario>(pag.items.map(wid=>{
      return [wid.id, wid.value];
    }));
    return map;
  }

  @computed get selectorOptions() : Updating<SelectorOption[]> {
    return this.scenarios.map(m=>{
      const selOpts : SelectorOption[] = Array.from(m.entries()).map(entry=>{
        const [key,prop] = entry;
        return {
          key,
          text: prop.name,
          value: key
        };
      });
      return selOpts;
    });
  }
};
