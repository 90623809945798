
import { Updating } from '../../../models/updating';
import { DbKey } from '../../../adl-gen/common/db';
import { Scenario } from '../../../adl-gen/whistle/propte/scenario';
import { Set } from '../../../../../../../helix-core/tslibs/adltools/src/adl-gen/runtime/sys/types';
import { AccountCategory } from '../../../adl-gen/whistle/propte/category';



export class AccountCategoriesSelectionStore {

  balanceSheetCategories: Updating<string[]> = new Updating();

  profitAndLossCategories: Updating<string[]> = new Updating();

  //accountCategoriesSelection: Updating<AccountCategoryPrefix[]> = new Updating();
};
