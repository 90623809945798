import { InterestRevenue, InternalEquityType, makeAccountCategory, makeAssetAccountType, makeAssetGrowthRevenue, makeDepreciationExpenses, makeDistributionEquityType, makeEquityAccountType, makeExpenseAccountType, makeGeneralNonrecoverable, makeInvestmentEquityType, makeLiabilityAccountType, makeLoanAccountType, makePropertyAssetType, makeRentalIncomeType, makeRentalRevenueType, makeRevenueAccountType } from "../adl-gen/whistle/propte/category";
import { makeOutgoingsExpenses, makeAirconditioning, makeCarpark, makeCleaning, makeDirectRecoverables, makeElectricity, makeFireProtection, makeGardening, makeInsurance, makeManagement, makeMiscellaneous, makePestControl, makeRepairsMaintenance, makeSecurity, makeStatutoryOutgoings, makeTransactionExpenses, makeStatutoryNonrecoverable } from "../adl-gen/whistle/propte/expense";
import { EmptyU } from "../adl-gen/whistle/propte/utils";

export function makeEmptyU(s?: string, n?: null) {
  return EmptyU.unspecified;
}

// noop maker functions due to ADL enums not generating maker functions:
function makeInternalEquityType(u:'unspecified', n: null) : InternalEquityType.unspecified {
  return InternalEquityType.unspecified;
}
function makeInterestRevenue(u:'unspecified', n: null) : InterestRevenue.unspecified {
  return InterestRevenue.unspecified;
}

// body from tool generateFiniteTypes.ts with texprAccountCategory
// (Actually trimmed down to match ViewsService.java toMainAccountCategory)
// prettier-ignore
export const accountCategories = {
  // generateFiniteTypes texprAccountCategory
  "asset": makeAccountCategory('asset',makeAssetAccountType('unspecified',null)),
  "asset|bank": makeAccountCategory('asset',makeAssetAccountType('bank',makeEmptyU('unspecified',null))),
  "asset|property": makeAccountCategory('asset',makeAssetAccountType('property',makePropertyAssetType('unspecified',null))),
  "liability": makeAccountCategory('liability',makeLiabilityAccountType('unspecified',null)),
  "liability|loan": makeAccountCategory('liability',makeLiabilityAccountType('loan',makeLoanAccountType('unspecified',null))),
  "liability|loan|fixed": makeAccountCategory('liability',makeLiabilityAccountType('loan',makeLoanAccountType('fixed',makeEmptyU('unspecified',null)))),
  "liability|loan|variable": makeAccountCategory('liability',makeLiabilityAccountType('loan',makeLoanAccountType('variable',makeEmptyU('unspecified',null)))),
  "equity": makeAccountCategory('equity',makeEquityAccountType('unspecified',null)),
  "equity|internal": makeAccountCategory('equity',makeEquityAccountType('internal',makeInternalEquityType('unspecified',null))),
  "equity|investment": makeAccountCategory('equity',makeEquityAccountType('investment',makeInvestmentEquityType('unspecified',null))),
  "equity|distribution": makeAccountCategory('equity',makeEquityAccountType('distribution',makeDistributionEquityType('unspecified',null))),
  "revenue": makeAccountCategory('revenue',makeRevenueAccountType('unspecified',null)),
  "revenue|rental": makeAccountCategory('revenue',makeRevenueAccountType('rental',makeRentalRevenueType('unspecified',null))),
  "revenue|rental|rental income": makeAccountCategory('revenue',makeRevenueAccountType('rental',makeRentalRevenueType('rentalIncome',makeRentalIncomeType('unspecified',null)))),
  "revenue|rental|outgoings reimbursement": makeAccountCategory('revenue',makeRevenueAccountType('rental',makeRentalRevenueType('outgoingsReimbursement',makeEmptyU('unspecified',null)))),
  "revenue|interest": makeAccountCategory('revenue',makeRevenueAccountType('interest',makeInterestRevenue('unspecified',null))),
  "revenue|asset growth": makeAccountCategory('revenue',makeRevenueAccountType('assetGrowth',makeAssetGrowthRevenue('unspecified',null))),
  "revenue|asset growth|property": makeAccountCategory('revenue',makeRevenueAccountType('assetGrowth',makeAssetGrowthRevenue('property',makePropertyAssetType('unspecified',null)))),
  "expense": makeAccountCategory('expense',makeExpenseAccountType('unspecified',null)),
  "expense|outgoings expenses": makeAccountCategory('expense',makeExpenseAccountType('outgoingsExpenses',makeOutgoingsExpenses('unspecified',null))),
  "expense|transaction expenses": makeAccountCategory('expense',makeExpenseAccountType('transactionExpenses',makeTransactionExpenses('unspecified',null))),
  "expense|depreciation": makeAccountCategory('expense',makeExpenseAccountType('depreciation',makeDepreciationExpenses('unspecified',null))),
  "expense|loan interest": makeAccountCategory('expense',makeExpenseAccountType('loanInterest',makeLoanAccountType('unspecified',null))),
  "expense|statutory nonrecoverable": makeAccountCategory('expense',makeExpenseAccountType('statutoryNonrecoverable',makeStatutoryNonrecoverable('unspecified',null))),
  "expense|general nonrecoverable": makeAccountCategory('expense',makeExpenseAccountType('generalNonrecoverable',makeGeneralNonrecoverable('unspecified',null))),
  // generateFiniteTypes texprAccountCategory
};
