/* @generated from adl module whistle.propte.db */

import * as ADL from './../../runtime/adl';
import * as common from './../../common';
import * as common_db from './../../common/db';
import * as common_strings from './../../common/strings';
import * as whistle_propte_accounting from './accounting';
import * as whistle_propte_lease from './lease';
import * as whistle_propte_property from './property';

export interface LeaseAccount {
  leaseId: common_db.DbKey<whistle_propte_lease.Lease>;
  accountId: common_db.DbKey<whistle_propte_accounting.Account>;
}

export function makeLeaseAccount(
  input: {
    leaseId: common_db.DbKey<whistle_propte_lease.Lease>,
    accountId: common_db.DbKey<whistle_propte_accounting.Account>,
  }
): LeaseAccount {
  return {
    leaseId: input.leaseId,
    accountId: input.accountId,
  };
}

const LeaseAccount_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["leaseId","accountId"]]}}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"leaseId","default":{"kind":"nothing"},"name":"leaseId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.lease","name":"Lease"}},"parameters":[]}]}},{"annotations":[],"serializedName":"accountId","default":{"kind":"nothing"},"name":"accountId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}}]}},"name":"LeaseAccount","version":{"kind":"nothing"}}};

export const snLeaseAccount: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"LeaseAccount"};

export function texprLeaseAccount(): ADL.ATypeExpr<LeaseAccount> {
  return {value : {typeRef : {kind: "reference", value : snLeaseAccount}, parameters : []}};
}

export interface PropertyAccount {
  propertyId: common_db.DbKey<whistle_propte_property.Property>;
  accountId: common_db.DbKey<whistle_propte_accounting.Account>;
}

export function makePropertyAccount(
  input: {
    propertyId: common_db.DbKey<whistle_propte_property.Property>,
    accountId: common_db.DbKey<whistle_propte_accounting.Account>,
  }
): PropertyAccount {
  return {
    propertyId: input.propertyId,
    accountId: input.accountId,
  };
}

const PropertyAccount_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"uniquenessConstraints":[["propertyId","accountId"]]}}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"propertyId","default":{"kind":"nothing"},"name":"propertyId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.property","name":"Property"}},"parameters":[]}]}},{"annotations":[],"serializedName":"accountId","default":{"kind":"nothing"},"name":"accountId","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.accounting","name":"Account"}},"parameters":[]}]}}]}},"name":"PropertyAccount","version":{"kind":"nothing"}}};

export const snPropertyAccount: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"PropertyAccount"};

export function texprPropertyAccount(): ADL.ATypeExpr<PropertyAccount> {
  return {value : {typeRef : {kind: "reference", value : snPropertyAccount}, parameters : []}};
}

/**
 * Details for a user
 */
export interface AppUser {
  fullname: common_strings.StringNE;
  email: common_strings.StringNE;
  isAdmin: boolean;
  hashedPassword: common_strings.StringNE;
}

export function makeAppUser(
  input: {
    fullname: common_strings.StringNE,
    email: common_strings.StringNE,
    isAdmin: boolean,
    hashedPassword?: common_strings.StringNE,
  }
): AppUser {
  return {
    fullname: input.fullname,
    email: input.email,
    isAdmin: input.isAdmin,
    hashedPassword: input.hashedPassword === undefined ? "" : input.hashedPassword,
  };
}

const AppUser_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"indexes":[["email"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"Details for a user\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"fullname","default":{"kind":"nothing"},"name":"fullname","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"email","default":{"kind":"nothing"},"name":"email","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}},{"annotations":[],"serializedName":"isAdmin","default":{"kind":"nothing"},"name":"isAdmin","typeExpr":{"typeRef":{"kind":"primitive","value":"Bool"},"parameters":[]}},{"annotations":[],"serializedName":"hashedPassword","default":{"kind":"just","value":""},"name":"hashedPassword","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringNE"}},"parameters":[]}}]}},"name":"AppUser","version":{"kind":"nothing"}}};

export const snAppUser: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"AppUser"};

export function texprAppUser(): ADL.ATypeExpr<AppUser> {
  return {value : {typeRef : {kind: "reference", value : snAppUser}, parameters : []}};
}

export type AppUserId = common_db.DbKey<AppUser>;

const AppUserId_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.db","name":"AppUser"}},"parameters":[]}]}}},"name":"AppUserId","version":{"kind":"nothing"}}};

export const snAppUserId: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"AppUserId"};

export function texprAppUserId(): ADL.ATypeExpr<AppUserId> {
  return {value : {typeRef : {kind: "reference", value : snAppUserId}, parameters : []}};
}

/**
 * messages posted on the noticeboard
 */
export interface Message {
  postedAt: common.Instant;
  postedBy: common_db.DbKey<AppUser>;
  message: common_strings.StringML;
}

export function makeMessage(
  input: {
    postedAt: common.Instant,
    postedBy: common_db.DbKey<AppUser>,
    message: common_strings.StringML,
  }
): Message {
  return {
    postedAt: input.postedAt,
    postedBy: input.postedBy,
    message: input.message,
  };
}

const Message_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[{"v1":{"moduleName":"common.db","name":"DbTable"},"v2":{"withIdPrimaryKey":true,"indexes":[["posted_at"]]}},{"v1":{"moduleName":"sys.annotations","name":"Doc"},"v2":"messages posted on the noticeboard\n"}],"type_":{"kind":"struct_","value":{"typeParams":[],"fields":[{"annotations":[],"serializedName":"postedAt","default":{"kind":"nothing"},"name":"postedAt","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common","name":"Instant"}},"parameters":[]}},{"annotations":[],"serializedName":"postedBy","default":{"kind":"nothing"},"name":"postedBy","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.db","name":"AppUser"}},"parameters":[]}]}},{"annotations":[],"serializedName":"message","default":{"kind":"nothing"},"name":"message","typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.strings","name":"StringML"}},"parameters":[]}}]}},"name":"Message","version":{"kind":"nothing"}}};

export const snMessage: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"Message"};

export function texprMessage(): ADL.ATypeExpr<Message> {
  return {value : {typeRef : {kind: "reference", value : snMessage}, parameters : []}};
}

export type MessageId = common_db.DbKey<Message>;

const MessageId_AST : ADL.ScopedDecl =
  {"moduleName":"whistle.propte.db","decl":{"annotations":[],"type_":{"kind":"type_","value":{"typeParams":[],"typeExpr":{"typeRef":{"kind":"reference","value":{"moduleName":"common.db","name":"DbKey"}},"parameters":[{"typeRef":{"kind":"reference","value":{"moduleName":"whistle.propte.db","name":"Message"}},"parameters":[]}]}}},"name":"MessageId","version":{"kind":"nothing"}}};

export const snMessageId: ADL.ScopedName = {moduleName:"whistle.propte.db", name:"MessageId"};

export function texprMessageId(): ADL.ATypeExpr<MessageId> {
  return {value : {typeRef : {kind: "reference", value : snMessageId}, parameters : []}};
}

export const _AST_MAP: { [key: string]: ADL.ScopedDecl } = {
  "whistle.propte.db.LeaseAccount" : LeaseAccount_AST,
  "whistle.propte.db.PropertyAccount" : PropertyAccount_AST,
  "whistle.propte.db.AppUser" : AppUser_AST,
  "whistle.propte.db.AppUserId" : AppUserId_AST,
  "whistle.propte.db.Message" : Message_AST,
  "whistle.propte.db.MessageId" : MessageId_AST
};
