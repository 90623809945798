import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Button, Form, Input, Loader } from "semantic-ui-react";
import { DbKey, WithDbId } from "../../../adl-gen/common/db";
import { Lease, makeLeaseCategory, makeSCCABroadCategory, SCCAMajorTenant } from "../../../adl-gen/whistle/propte/lease";
import { Updating } from "../../../models/updating";
import { Service } from "../../../service/service";
import { LeaseSelectionStore } from "../../components/leaseSelector/leaseSelectionStore";
import { LeaseStore } from "../../components/leaseSelector/leaseStore";

export interface Props {
  service: Service;
  leaseStore : LeaseStore;
  leaseSelectionStore : LeaseSelectionStore;
}

export type LeaseWithIdPartial = {
  lease: Lease;
  id?: DbKey<Lease>;
}

@observer
export class LeaseCreate extends React.Component<Props> {

  @observable
  editing : LeaseWithIdPartial|null = null;

  @computed get selectedLease() : Updating<LeaseWithIdPartial> {
    return this.props.leaseSelectionStore.leaseSelection.map(m=>{
      return Array.from(m.entries())
    })
    .then(m=>{
      if(m.length === 0) {
        return new Updating<LeaseWithIdPartial>();
      }
      else {
        return new Updating<LeaseWithIdPartial>({
          id: m[0][0],
          lease: m[0][1]
        });
      }
    });
  }

  saving: Updating<LeaseWithIdPartial> = new Updating();

  render() {

    if (this.editing) {
      return <>
        <LeaseEdit editing={this.editing}/>
        <Button primary onClick={()=>{
          this.saveEdit();
        }}>Save</Button>
        <Button primary onClick={()=>{
          this.cancelEdit();
        }}>Cancel</Button>
        <Loader active={this.saving.isUpdating()}/>
      </>;
    }

    const selectedLease = this.selectedLease.value;
    if (selectedLease === null) {
      return <>
        <Button primary onClick={()=>{
          this.startEditNew();
        }}>New</Button>
      </>;
    }
    return <>
      <Button primary onClick={()=>{
        this.startEditSelected(selectedLease);
      }}>Edit</Button>
    </>;
  }

  startEditSelected(selectedLease: LeaseWithIdPartial) {
    this.editing = {
      id: selectedLease.id,
      lease: {
        ...selectedLease.lease
      }
    };
  }

  startEditNew() {
    this.editing = {
      lease:{
        key:"",
        name:"",
        category: makeLeaseCategory({
          broadCategory: makeSCCABroadCategory('major',SCCAMajorTenant.unspecified)
        }),
        startDate: "2020-01-01",
        expiryDate:"2020-01-01",
        grossLettableArea: "0.0",
        propertyId: this.props.leaseStore.curProperty.id,
        shop:""
      }
    };
  }

  @action
  cancelEdit() {
    this.editing = null;
  }

  @action
  saveEdit() {
    if(this.editing) {
      this.props.leaseSelectionStore.leaseSelection.set(new Map)

      let promise : Promise<WithDbId<Lease>>;
      const value = this.editing.lease;
      if(this.editing.id) {
        const id = this.editing.id;
        promise = this.props.service.updateLease({
          id,
          value
        }).then(()=>{
          return {
            id,
            value
          };
        });
      } else {
        promise = this.props.service.createLease(value)
        .then((id)=>{
          return {
            id,
            value
          };
        });
      }

      this.saving.update(
        promise.then(p=>{
          return {
            id: p.id,
            lease: p.value
          };
        })
      );

      promise.then(p=>{

        // update as selected lease
        const sel : Map<DbKey<Lease>, Lease> = new Map();
        sel.set(p.id, p.value);
        this.props.leaseSelectionStore.leaseSelection.set(sel);

        // clear editing
        this.editing = null;
      });
    }
  }
}

export interface LeaseEditProps {
  editing: LeaseWithIdPartial;
};

@observer
export class LeaseEdit extends React.Component<LeaseEditProps> {
  render() {
    const editing = this.props.editing;
    return <>
      <Form>
        <Form.Field>
          <label>Key</label>
          <Input type={"string"} value={editing.lease.key} placeholder={"Key"} onChange={(ev,data)=>{
            editing.lease.key = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Name</label>
          <Input type={"string"} value={editing.lease.name} placeholder={"Name"} onChange={(ev,data)=>{
            editing.lease.name = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Start Date</label>
          <Input type={"string"} value={editing.lease.startDate} placeholder={"Start Date"} onChange={(ev,data)=>{
            editing.lease.startDate = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Expiry Date</label>
          <Input type={"string"} value={editing.lease.startDate} placeholder={"Expiry Date"} onChange={(ev,data)=>{
            editing.lease.expiryDate = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Gross Lettable Area</label>
          <Input type={"string"} value={editing.lease.grossLettableArea} placeholder={"GLA"} onChange={(ev,data)=>{
            editing.lease.grossLettableArea = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Shop Label</label>
          <Input type={"string"} value={editing.lease.shop} placeholder={"Shop Label"} onChange={(ev,data)=>{
            editing.lease.shop = data.value;
          }}></Input>
        </Form.Field>
      </Form>
    </>;
  }
}
