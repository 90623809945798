import { observable, action } from "mobx";

/** A value that is either present or null & optionally updating */
export class Updating<T> {
  @observable
  public updating: number = 0;

  @observable.ref
  public value: T|null = null;

  constructor(
    value: T|null = null,
    updating: number = 0
  ){
    this.value = value;
    this.updating = updating;
  }

  static ofPromise<T> (p: Promise<T>) : Updating<T>  {
    const upd = new Updating<T>();
    upd.update(p);
    return upd;
  }

  static ofValue<T> (v: T) : Updating<T>  {
    const upd = new Updating<T>();
    upd.set(v);
    return upd;
  }

  hasValue() : boolean {
    return this.value !== null;
  }

  getValue() : T|null {
    return this.value;
  }

  /** Whether the value is in progress of updating */
  isUpdating(): boolean {
    return this.updating > 0;
  }

  /** Clone - note that obtaining all values makes mobx track value and isUpdating changes */
  clone() : Updating<T> {
    return new Updating<T>(this.value, this.updating);
  }

  /** Write to value without changing to force cascade of updates */
  @action
  touch() {
    this.set(this.value);
  }

  /** Set the value - or reset if value is null */
  @action
  set(arg: T|null): void {
    this.value = arg;
  }

  /** Assign from another Updating<T> */
  @action
  assign(arg: Updating<T>) {
    this.value = arg.value;
    this.updating = arg.updating;
  }

  /** Invalidate the value and mark as null - or reset and update from promise */
  @action
  reset(futureValue: Promise<T>|null = null): void {
    this.value = null;
    if(futureValue !== null) {
      this.update(futureValue);
    }
  }

  /** Prepare to update the value (keeping value as-is in the meantime) */
  @action
  update(futureValue: Promise<T>): void {
    this.incrementUpdating(1);
    futureValue
      .then(value => {
        this.finishUpdating(value);
      });
  }

  /** Reset to null and update from promise */
  @action
  resetUpdate(futureValue: Promise<T>): void {
    this.value = null;
    this.update(futureValue);
  }

  /** If there is a value, apply the map function.  Propagate updating count */
  // (functor)
  map<V>( mapFunc: (t:T)=>V ) : Updating<V> {
    const v : V|null = (this.value === null) ? null : mapFunc(this.value);
    return new Updating<V>(v, this.updating);
  }

  /** Given 2 Updating<U> apply the func if both value exist.  Propate updating counts */
  // (Applicative)
  static combine<T, U, V>(u: Updating<U>, v: Updating<V>, func: (u:U, v:V)=>T ) : Updating<T> {
    if(u.value === null || v.value === null) {
      return new Updating<T>(null, u.updating + v.updating);
    }
    return new Updating<T>(func(u.value, v.value), u.updating + v.updating);
  }

  static combine3<T, A,B,C>(a: Updating<A>, b: Updating<B>, c:Updating<C>, func: (a:A, b:B, c:C)=>T ) : Updating<T> {
    if(a.value === null || b.value === null || c.value === null) {
      return new Updating<T>(null, a.updating + b.updating + c.updating);
    }
    return new Updating<T>(func(a.value, b.value, c.value), a.updating + b.updating + c.updating);
  }

  /** If there is a value, generate a "next" updating value. */
  // (Bind / monad)
  then<V>( bindFunc: (t:T)=>Updating<V>) : Updating<V> {
    // if value is absent, result value is absent - copy this.updating
    if(this.value === null) {
      return new Updating<V>(null, this.updating);
    }

    // if value is present, apply to result (ignore this.updating)
    const v : Updating<V> = bindFunc(this.value);
    return v;
  }

  @action
  public incrementUpdating(delta: number) : void {
    this.updating += delta;
  }

  @action
  private finishUpdating(value: T): void {
    this.set(value);
    this.incrementUpdating(-1);
  }
}
