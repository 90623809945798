

import { Lease } from '../../../adl-gen/whistle/propte/lease'
import { Updating } from '../../../models/updating';
import { DbKey, WithDbId } from '../../../adl-gen/common/db';
import { computed } from 'mobx';

export class LeaseSelectionStore {
  leaseSelection: Updating<Map<DbKey<Lease>, Lease>> = new Updating();

  @computed get leaseSelectionSingle() : Updating<WithDbId<Lease>> {
    return this.leaseSelection.then(vals=>{
      const entries = Array.from(vals.entries());
      if(entries.length === 0) {
        return new Updating();
      }
      const x = entries[0];
      return new Updating({
        id: x[0],
        value: x[1]
      });
    });
  }
};

