import { action, computed, observable } from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Button, Dropdown, DropdownProps, Form, Input, Loader } from "semantic-ui-react";
import { DbKey, WithDbId } from "../../../adl-gen/common/db";
import { DataScenarioCategory, LeaseTurnoverScenario, makeDataScenarioCategory } from "../../../adl-gen/whistle/propte/lease";
import { Updating } from "../../../models/updating";
import { Service } from "../../../service/service";
import { LeaseTurnoverScenarioSelectionStore } from "../../components/leaseTurnoverScenarioSelector/leaseTurnoverScenarioSelectionStore";
import { LeaseTurnoverScenarioStore } from "../../components/leaseTurnoverScenarioSelector/leaseTurnoverScenarioStore";

export interface Props {
  service: Service;
  leaseTurnoverScenarioStore : LeaseTurnoverScenarioStore;
  leaseTurnoverScenarioSelectionStore : LeaseTurnoverScenarioSelectionStore;
}

export type LeaseTurnoverScenarioWithIdPartial = {
  leaseturnoverscenario: LeaseTurnoverScenario;
  id?: DbKey<LeaseTurnoverScenario>;
}

@observer
export class LeaseTurnoverScenarioCreate extends React.Component<Props> {

  @observable
  editing : LeaseTurnoverScenarioWithIdPartial|null = null;

  @computed get selectedLeaseTurnoverScenario() : Updating<LeaseTurnoverScenarioWithIdPartial> {
    return this.props.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelection.map(m=>{
      return Array.from(m.entries())
    })
    .then(m=>{
      if(m.length === 0) {
        return new Updating<LeaseTurnoverScenarioWithIdPartial>();
      }
      else {
        return new Updating<LeaseTurnoverScenarioWithIdPartial>({
          id: m[0][0],
          leaseturnoverscenario: m[0][1]
        });
      }
    });
  }

  saving: Updating<LeaseTurnoverScenarioWithIdPartial> = new Updating();

  render() {

    if (this.editing) {
      return <>
        <LeaseTurnoverScenarioEdit editing={this.editing}/>
        <Button primary onClick={()=>{
          this.saveEdit();
        }}>Save</Button>
        <Button primary onClick={()=>{
          this.cancelEdit();
        }}>Cancel</Button>
        <Loader active={this.saving.isUpdating()}/>
      </>;
    }

    const selectedLeaseTurnoverScenario = this.selectedLeaseTurnoverScenario.value;
    if (selectedLeaseTurnoverScenario === null) {
      return <>
        <Button primary onClick={()=>{
          this.startEditNew();
        }}>New</Button>
      </>;
    }
    return <>
      <Button primary onClick={()=>{
        this.startEditSelected(selectedLeaseTurnoverScenario);
      }}>Edit</Button>
    </>;
  }

  startEditSelected(selectedLeaseTurnoverScenario: LeaseTurnoverScenarioWithIdPartial) {
    this.editing = {
      id: selectedLeaseTurnoverScenario.id,
      leaseturnoverscenario: {
        ...selectedLeaseTurnoverScenario.leaseturnoverscenario
      }
    };
  }

  startEditNew() {
    this.editing = {
      leaseturnoverscenario:{
        key:"",
        name:"",
        comment: "",
        category: makeDataScenarioCategory('actual',{})
      }
    };
  }

  @action
  cancelEdit() {
    this.editing = null;
  }

  @action
  saveEdit() {
    if(this.editing) {
      this.props.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelection.set(new Map)

      let promise : Promise<WithDbId<LeaseTurnoverScenario>>;
      const value = this.editing.leaseturnoverscenario;
      if(this.editing.id) {
        const id = this.editing.id;
        promise = this.props.service.updateLeaseTurnoverScenario({
          id,
          value
        }).then(()=>{
          return {
            id,
            value
          };
        });
      } else {
        promise = this.props.service.createLeaseTurnoverScenario(value)
        .then((id)=>{
          return {
            id,
            value
          };
        });
      }

      this.saving.update(
        promise.then(p=>{
          return {
            id: p.id,
            leaseturnoverscenario: p.value
          };
        })
      );

      promise.then(p=>{
        // update global store of all properties
        this.props.leaseTurnoverScenarioStore.update();

        // update as selected leaseturnoverscenario
        const sel : Map<DbKey<LeaseTurnoverScenario>, LeaseTurnoverScenario> = new Map();
        sel.set(p.id, p.value);
        this.props.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelection.set(sel);

        // clear editing
        this.editing = null;
      });
    }
  }
}

export interface LeaseTurnoverScenarioEditProps {
  editing: LeaseTurnoverScenarioWithIdPartial;
};

export type DataScenarioCategorySelectorOption = {
  key: string;
  text: string;
  value: string
};

@observer
export class LeaseTurnoverScenarioEdit extends React.Component<LeaseTurnoverScenarioEditProps> {

  @computed get categoryValue() {
    return this.props.editing.leaseturnoverscenario.category.kind;
  }

  get categoryOptions() : DataScenarioCategorySelectorOption[] {
    return [
      {
        key:'actual',
        text:'Actual',
        value:'actual'
      },
      /*{
        key:'forecast',
        text:'Forecast',
        value:'forecast'
      }*/
    ];
  }

  @action
  categoryOnChange(data:DropdownProps) {
    if(data.value === 'actual') {
      this.props.editing.leaseturnoverscenario.category = makeDataScenarioCategory('actual',{});
    }
    if(data.value === 'forecast') {
      this.props.editing.leaseturnoverscenario.category = makeDataScenarioCategory('forecast',{});
    }
  }

  render() {
    const editing = this.props.editing;
    return <>
      <Form>
        <Form.Field>
          <label>Key</label>
          <Input type={"string"} value={editing.leaseturnoverscenario.key} placeholder={"Key"} onChange={(ev,data)=>{
            editing.leaseturnoverscenario.key = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Name</label>
          <Input type={"string"} value={editing.leaseturnoverscenario.name} placeholder={"Name"} onChange={(ev,data)=>{
            editing.leaseturnoverscenario.name = data.value;
          }}></Input>
        </Form.Field>

        <Form.Field>
          <label>Comment</label>
          <Input type={"string"} value={editing.leaseturnoverscenario.comment} placeholder={"Comment"} onChange={(ev,data)=>{
            editing.leaseturnoverscenario.comment = data.value;
          }}></Input>
        </Form.Field>

        {/*
        <Form.Field>
          <label>Category</label>
          <Dropdown
            placeholder='Category'
            fluid
            search
            selection
            scrolling
            options={this.categoryOptions}
            onChange={(ev,data)=>this.categoryOnChange(data)}
            value={this.categoryValue}
          />
        </Form.Field>
        */}

      </Form>
    </>;
  }
}
