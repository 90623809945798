import { observer } from "mobx-react";
import * as React from "react";
import { DbKey } from "../../../adl-gen/common/db";
import { Property } from "../../../adl-gen/whistle/propte/property";
import { Segment, Input, Grid, Divider, Icon, Header, Search, Label, Menu, Table } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { LeaseTurnoverStore } from "../../components/leaseTurnover/leaseTurnoverStore";
import { LeaseTurnover } from "../../../adl-gen/whistle/propte/lease";
import { computed } from "mobx";

/** Props for the component */
export interface LeaseTurnoverProps {
  leaseTurnoverStore: LeaseTurnoverStore;
}

type LeaseTurnoverRow = {
  cells: string[]
};

type LeaseTurnoverTableData = {
  headers: string[],
  rows: LeaseTurnoverRow[]
};
type GetCellStr = (data:LeaseTurnover)=>string;

type GetField = {
  header: string,
  cell: GetCellStr,
  ordering: number
};

function IsGetField(x : GetField|null) : x is GetField {
  return x!==null;
}

/**
 * Component for LeaseTurnover (table of lease turnover rows)
 */
@observer
export class LeaseTurnoverTable extends React.Component<LeaseTurnoverProps> {
  @computed get tableData() : LeaseTurnoverTableData {
    const fields : {[key in keyof LeaseTurnover] : GetField|null}  = {
      leaseId: null,
      turnoverScenarioId: null,

      date: {
        header: 'Date',
        cell: (val: LeaseTurnover)=>{
          return val.date;
        },
        ordering: 1
      },

      incGst: {
        header: 'Inc GST',
        cell: (val: LeaseTurnover)=>{
          return val.incGst ? "inc GST" : "ex GST";
        },
        ordering: 2
      },

      numDays: {
        header: 'Num Days',
        cell: (val: LeaseTurnover)=>{
          return `${val.numDays || ""}`;
        },
        ordering: 3
      },

      amount: {
        header: 'Amount',
        cell: (val: LeaseTurnover)=>{
          return val.amount;
        },
        ordering: 3
      },

      category: null,
    };

    const getFields = Object.keys(fields)
      .map(f=>{
        const x : GetField|null = fields[f];
        return x;
      })
      .filter(IsGetField)
      .sort((a,b)=>a.ordering - b.ordering);

    const headers : string[] = getFields.map(f=>f.header);
    const columns : GetCellStr[] = getFields.map(f=>f.cell);

    const data = this.props.leaseTurnoverStore.turnoverDataRows.value || [];

    return {
      headers,
      rows: data.map(r=>({
        cells: columns.map(colToStr=>colToStr(r.value))
      }))
    };
  }

  /** Renders the component */
  render() {
    const tData = this.tableData;

    return <Table celled>
      <Table.Header>
        <Table.Row>
          {tData.headers.map((h,i)=><Table.HeaderCell key={i}>{h}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {tData.rows.map((row,i)=>
          <Table.Row key={i}>
            {row.cells.map((cell,k)=>
              <Table.Cell key={`${i}-${k}`}>{cell}</Table.Cell>
            )}
          </Table.Row>)
        }
      </Table.Body>
    </Table>
  }
}
