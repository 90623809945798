import { PropertyStore } from "../ui/components/propertySelector/propertyStore";
import { PropertySelectionStore } from "../ui/components/propertySelector/propertySelectionStore";
import { TenancyScheduleStore } from "../ui/components/tenancySchedule/tenancyScheduleStore";
import { Service } from "../service/service";
import { ScenarioStore } from "../ui/components/scenarioSelector/scenarioStore";
import { ScenarioSelectionStore } from "../ui/components/scenarioSelector/scenarioSelectionStore";
import { ScenarioAnalysisStore } from "../ui/components/scenarioAnalysis/scenarioAnalysisStore";
import { ScenarioTablesStore } from "../ui/components/scenarioTables/scenarioTablesStore";
import { LeaseStore } from "../ui/components/leaseSelector/leaseStore";
import { LeaseSelectionStore } from "../ui/components/leaseSelector/leaseSelectionStore";
import { autorun, reaction } from "mobx";
import { WithDbId } from "../adl-gen/common/db";
import { Property } from "../adl-gen/whistle/propte/property";
import { Updating } from "../models/updating";
import { Lease, LeaseTurnoverScenario } from "../adl-gen/whistle/propte/lease";
import { LeaseTurnoverScenarioStore } from "../ui/components/leaseTurnoverScenarioSelector/leaseTurnoverScenarioStore";
import { LeaseTurnoverScenarioSelectionStore } from "../ui/components/leaseTurnoverScenarioSelector/leaseTurnoverScenarioSelectionStore";
import { LeaseTurnoverStore } from "../ui/components/leaseTurnover/leaseTurnoverStore";
import { LeaseTurnoverSelectionStore } from "../ui/components/leaseTurnover/leaseTurnoverSelectionStore";
import { AccountCategoryStore } from "../ui/components/categorySelector/accountCategoryStore";
import { AccountCategoriesSelectionStore } from "../ui/components/categorySelector/accountCategorySelectionStore";
import { TurnoverSummaryStore } from "../ui/components/turnoverSummaryTable/turnoverSummaryStore";

export class PropteStore {

  propertyStore : PropertyStore;
  propertySelectionStore : PropertySelectionStore;
  tenancyScheduleStore : TenancyScheduleStore;
  turnoverSummaryStore: TurnoverSummaryStore;

  leaseStore : LeaseStore;
  leaseSelectionStore : LeaseSelectionStore;

  leaseTurnoverScenarioStore : LeaseTurnoverScenarioStore;
  leaseTurnoverScenarioSelectionStore: LeaseTurnoverScenarioSelectionStore;

  scenarioStore: ScenarioStore;
  scenarioSelectionStore: ScenarioSelectionStore;
  scenarioAnalysisStore: ScenarioAnalysisStore;
  scenarioTablesStore: ScenarioTablesStore;

  accountCategoryStore: AccountCategoryStore;
  accountCategoriesSelectionStore: AccountCategoriesSelectionStore;

  leaseTurnoverStore: LeaseTurnoverStore;
  leaseTurnoverSelectionStore : LeaseTurnoverSelectionStore;

  constructor(public service: Service) {
    this.propertyStore = new PropertyStore(service);
    this.propertyStore.update();

    this.propertySelectionStore = new PropertySelectionStore();
    this.tenancyScheduleStore = new TenancyScheduleStore(service, this.propertySelectionStore);
    this.turnoverSummaryStore = new TurnoverSummaryStore(service);

    // not sure why the observables aren't passing into LeaseStore properly (possibly related to an issue with @computed?)
    // workaround: react to it and copy into a new Updating<T>:
    const propertySelectionSingle : Updating<WithDbId<Property>> = new Updating();
    reaction(()=>this.propertySelectionStore.propertySelectionSingle.clone(), (ps)=>{
      propertySelectionSingle.assign(ps);
    });
    this.leaseStore = new LeaseStore(service, propertySelectionSingle);
    this.leaseSelectionStore = new LeaseSelectionStore();

    const leaseSelectionSingle : Updating<WithDbId<Lease>> = new Updating();
    reaction(()=>this.leaseSelectionStore.leaseSelectionSingle.clone(), (ls)=>{
      leaseSelectionSingle.assign(ls);
    });

    this.leaseTurnoverScenarioStore = new LeaseTurnoverScenarioStore(service);
    this.leaseTurnoverScenarioStore.update();

    this.leaseTurnoverScenarioSelectionStore = new LeaseTurnoverScenarioSelectionStore();

    const leaseTurnoverScenarioSelectionSingle : Updating<WithDbId<LeaseTurnoverScenario>> = new Updating();
    reaction(()=>this.leaseTurnoverScenarioSelectionStore.leaseTurnoverScenarioSelectionSingle.clone(), (ls)=>{
      leaseTurnoverScenarioSelectionSingle.assign(ls);
    });

    this.leaseTurnoverStore = new LeaseTurnoverStore(service, propertySelectionSingle, leaseSelectionSingle, leaseTurnoverScenarioSelectionSingle);
    this.leaseTurnoverSelectionStore = new LeaseTurnoverSelectionStore();

    this.scenarioStore = new ScenarioStore(service);
    this.scenarioSelectionStore = new ScenarioSelectionStore();

    this.accountCategoryStore = new AccountCategoryStore();
    this.accountCategoriesSelectionStore = new AccountCategoriesSelectionStore();

    this.scenarioAnalysisStore = new ScenarioAnalysisStore(service, this.scenarioSelectionStore);
    this.scenarioTablesStore = new ScenarioTablesStore(service, this.scenarioSelectionStore, this.accountCategoriesSelectionStore);


  }
}
