
import {Property} from '../../../adl-gen/whistle/propte/property'
import { Updating } from '../../../models/updating';
import { action, autorun, computed, observable, reaction } from 'mobx';
import { DbKey, WithDbId } from '../../../adl-gen/common/db';
import { Service } from '../../../service/service';
import { makeTableQuery, makeFieldPredicate, makeFieldComparison, makeValueOrFunction, makeSortField, SortDirection } from '../../../adl-gen/common/tabular';
import { Lease, LeaseTurnover, LeaseTurnoverScenario } from '../../../adl-gen/whistle/propte/lease';

export class LeaseTurnoverStore {
  constructor(
    private service: Service,
    private property: Updating<WithDbId<Property>>,
    private lease: Updating<WithDbId<Lease>>,
    private leaseTurnoverScenario: Updating<WithDbId<LeaseTurnoverScenario>>,
  ) {

    reaction(
      ()=>{
        if(this.forceUpdate > 0) {
          console.log("turnoverDataRows forceUpdate:", this.forceUpdate);
        }
        if(this.lease.value === null) {
          return null;
        }
        if(this.leaseTurnoverScenario.value === null) {
          return null;
        }
        return {
          lease: this.lease.value,
          leaseTurnoverScenario: this.leaseTurnoverScenario.value
        };
      },
      (v)=>{
        if(v) {
          this.turnoverDataRows.update(this.fetchTurnoverData(
            v.lease.id,
            v.leaseTurnoverScenario.id
          ));
        }
      },
      {
        fireImmediately: true
      }
    );
  }

  turnoverDataRows : Updating<WithDbId<LeaseTurnover>[]> = new Updating();

  @observable
  forceUpdate: number = 0;

  @action
  update() {
    this.forceUpdate++;
  }

  @computed get curProperty() : WithDbId<Property> {
    return this.property.value!;
  }

  @computed get curLease() : WithDbId<Lease> {
    return this.lease.value!;
  }

  @computed get curLeaseTurnoverScenario() : WithDbId<LeaseTurnoverScenario> {
    return this.leaseTurnoverScenario.value!;
  }

  private async fetchTurnoverData(
    leaseId : DbKey<Lease>,
    LeaseTurnoverId : DbKey<LeaseTurnover>
  ) : Promise<WithDbId<LeaseTurnover>[]> {

    const pag = await this.service.queryLeaseTurnover(makeTableQuery({
      filter: makeFieldPredicate('and',[
        makeFieldPredicate('equalTo', makeFieldComparison({
          field: 'lease_id', value: makeValueOrFunction('value', leaseId)
        })),
        makeFieldPredicate('equalTo', makeFieldComparison({
          field: 'turnover_scenario_id', value: makeValueOrFunction('value', LeaseTurnoverId)
        })),
      ]),
      sorting: [
        makeSortField({
          field: 'date',
          direction: SortDirection.ascending
        })
      ]
    }));

    return pag.items;
  }
};
