import { action, observable } from "mobx";

import { LoginReq, UserProfile } from "../adl-gen/whistle/propte/api";
import { AdminStore } from "../adminui/store";
import { pathFromRoute, Route, routeFromPath } from "../routing/app-routes";
import { Router } from "../routing/router";

import { IdentityStore } from "./identity-store";
import { MessageBoardStore } from "./message-board-store";
import { Service } from "../service/service";
import { PropteStore } from "./propte-store";

/** Top level app state */
export type AppState =
  | { kind: "login" }
  | { kind: "logout" }
  | { kind: "main"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "property-data"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "tenancy-schedule"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "turnover-summary"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "scenario-analysis"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "scenario-tables"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "scenario-graphs"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "scenario-tables-export"; userProfile: UserProfile; propteStore: PropteStore}
  | { kind: "message-board"; userProfile: UserProfile; messageBoardStore: MessageBoardStore }
  | { kind: "admin"; userProfile: UserProfile; store: AdminStore }
  | {
      kind: "admin-table";
      userProfile: UserProfile;
      table: string;
      store: AdminStore;
    };


/**
 * Top level app data and actions, including navigation.
 */
export class AppStore {
  @observable.deep state: AppState;

  constructor(
    /** Router which is the bridge to the browser location */
    private readonly router: Router,

    /** The global identity store */
    readonly identityStore: IdentityStore,

    readonly service: Service,

    readonly propteStore: PropteStore,

    /** Instantiates a message board store */
    private readonly makeMessageBoardStore: () => MessageBoardStore,
    /** Instantiates an admin storestore */
    private readonly makeAdminStore: (table?: string) => AdminStore
  ) {
    this.state = { kind: "login" };

    // Asynchronously register route change listener to not block construction,
    // in case the store factories rely on the app store being constructed
    setTimeout(() => router.registerRouteChangeListener(this.onRouteChange), 0);
  }

  /** Updates state based on route changes */
  @action onRouteChange = async () => {
    const userProfile = await this.identityStore.userProfilePromise;
    const isAdminUser = userProfile && userProfile.isAdmin;
    let route = routeFromPath(this.router.getCurrentPath());

    if (route === undefined) {
      // Redirect invalid routes to main or login depending
      // on whether the user is logged in
      route =
        userProfile !== undefined ? { route: "main" } : { route: "login" };
      void this.navigateTo(route);
      return;
    }

    if (route.route === "login") {
      this.state = { kind: "login"};
      return;
    }

    if (route.route === "logout") {
      this.identityStore.onLogout();
      this.state = { kind: "logout" };
      return;
    }

    if (route.route === "main" && userProfile )  {
      this.state = { kind: "main", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "property-data" && userProfile )  {
      this.state = { kind: "property-data", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "tenancy-schedule" && userProfile )  {
      this.state = { kind: "tenancy-schedule", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "turnover-summary" && userProfile )  {
      this.state = { kind: "turnover-summary", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "scenario-analysis" && userProfile )  {
      this.state = { kind: "scenario-analysis", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "scenario-tables" && userProfile )  {
      this.state = { kind: "scenario-tables", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "scenario-graphs" && userProfile )  {
      this.state = { kind: "scenario-graphs", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "scenario-tables-export" && userProfile )  {
      this.state = { kind: "scenario-tables-export", userProfile, propteStore: this.propteStore};
      return;
    }

    if (route.route === "message-board" && userProfile) {
      this.state = {
        kind: "message-board",
        messageBoardStore : this.makeMessageBoardStore(),
        userProfile
      };
      return;
    }

    if (route.route === "admin" && userProfile && isAdminUser) {
      this.state = {
        kind: "admin",
        store: this.getAdminStore(),
        userProfile
      };
      return;
    }

    if (route.route === "admin-table" && userProfile && isAdminUser) {
      this.state = {
        kind: "admin-table",
        table: route.table,
        store: this.getAdminStore(route.table),
        userProfile
      };
      return;
    }

    await this.navigateTo({ route: "login" });
  };

  getAdminStore = (table?: string): AdminStore => {
    if (this.state.kind === "admin" || this.state.kind === "admin-table") {
      if (this.state.store.tableName === table) {
        return this.state.store;
      }
    }
    return this.makeAdminStore(table);
  };

  /** Logs user in and redirects to message board page */
  @action
  onLogin = async (req: LoginReq) => {
    const loginState = await this.identityStore.onLogin(req);
    // tslint:disable-next-line: no-console
    console.log("loginState", loginState);
    if (loginState.kind === 'logged-in') {
      void this.navigateTo({ route: "main" });
    }
  };

  /** Logs user out and redirects to logout page */
  @action
  onLogout = () => {
    this.identityStore.onLogout();
    void this.navigateTo({ route: "logout" });
  };

  async navigateTo(route: Route) {
    return this.router.go(pathFromRoute(route));
  }
}
