import { debounce } from "@hx/util/timers";
import { observable, runInAction } from "mobx";
import moment from "moment";

import { LocalDate } from "../../../adl-gen/common";

/**
 * The state required to render and modify a text field
 */
export interface InputState {
  /** Input field value, i.e. always a string */
  value(): string;
  /** Error message to show, if any */
  errorMsg(): string | undefined;
  /** Callback when the input field value changes */
  onChange(value: string): void;
}

/**
 * Extended state that exposes a typed interface
 */
export interface FormState<T> {
  /** Sets the typed value */
  set(v: T): void;
  /** Gets the state which may be incomplete, valid, or invalid */
  get(): FormValue<T>;
  /** Gets the valid state or undefined */
  getValid(): T | undefined;
}

export type FieldState<T> = InputState & FormState<T>;

export interface Value<T> {
  kind: "valid";
  value: T;
}
export interface Empty {
  kind: "empty";
}
export interface Invalid {
  kind: "invalid";
  error: string;
}

export type FormValue<T> = Value<T> | Empty | Invalid;

export class FieldStateImpl<T> implements FieldState<T> {
  @observable.ref current: string = "";

  constructor(readonly convert: Convert<T>) {}

  value = () => {
    return this.current;
  };

  errorMsg = () => {
    const fv = this.get();
    if (fv.kind === "invalid") {
      return fv.error;
    }
    return undefined;
  };

  onChange = (value: string) => {
    runInAction(() => {
      this.current = value;
    });
  };

  set = (v: T) => {
    runInAction(() => (this.current = this.convert.toText(v)));
  };

  get = (): FormValue<T> => {
    return this.convert.fromText(this.current);
  };

  getValid = (): T | undefined => {
    const v = this.get();
    if (v.kind === "valid") {
      return v.value;
    } else {
      return undefined;
    }
  };
}

/**
 * A plain old text field
 */
export function newTextField(): FieldState<string> {
  return new FieldStateImpl(convertText());
}

/**
 * A text field with content that must match the
 * given regex to be valid
 */
export function newRegexTextField(
  re: RegExp,
  errorMessage: string
): FieldState<string> {
  return new FieldStateImpl(convertRegexp(re, errorMessage));
}

/**
 * A text field that must be non empty and optionally trims whitespace.
 */
export function newNonEmptyTextField(
  options: {
    trimWhitespace?: boolean;
  } = {}
): FieldState<string> {
  if (options.trimWhitespace) {
    return new FieldStateImpl(convertRegexp(/..*/, "field is empty"));
  } else {
    return new FieldStateImpl(convertRegexp(/.*\S.*/, "field is empty"));
  }
}

/**
 * A text field that must be an integer, possibly with restricted range
 */
export function newIntegerFieldState(
  minValue?: number,
  maxValue?: number,
  errorMessage0?: string
): FieldState<number> {
  const errorMessage = errorMessage0 ? errorMessage0 : "field must be a number";
  return new FieldStateImpl(convertInteger(minValue, maxValue, errorMessage));
}

/**
 *
 * A deposit amount (with result value being an integer expressed in cents)
 */
export function newAmountFieldState(
  minValue?: number,
  maxValue?: number,
  errorMessage0?: string
): FieldState<number> {
  const errorMessage = errorMessage0
    ? errorMessage0
    : "field must be an amount";
  return new FieldStateImpl(convertAmount(minValue, maxValue, errorMessage));
}

/**
 * A text field that must be an australian mobile phone number.
 *
 * This will normalise input to start with +61
 */
export function newPhoneFieldState(): FieldState<string> {
  return new FieldStateImpl({
    toText: value => value,
    fromText: text => {
      const stripped = text.replace(/\s/g, "");

      if (stripped.length === 0) {
        return {
          kind: "empty"
        };
      } else if (stripped.match(/^0[45][0-9]{8}$/)) {
        return {
          kind: "valid",
          value: "+61" + stripped.substr(1)
        };
      } else if (stripped.match(/[+]61[45][0-9]{8}$/)) {
        return {
          kind: "valid",
          value: stripped
        };
      } else {
        return {
          kind: "invalid",
          error: "Please enter a valid australian mobile phone number"
        };
      }
    }
  });
}

/**
 * A text field that must be a LocalDate.
 *
 * The user facing editable form is DD/MM/YYYY, whilst the internal
 * representation is correct for LocalDate, ie a ISO 8601 YYYY-MM-DD.
 */
export function newLocalDateFieldState(): FieldState<LocalDate> {
  return new FieldStateImpl({
    toText: (localDate: LocalDate) => {
      // LocalDate is required to be in the ISO YYYY-MM-DD format, so no need
      // to check for failure here.
      return moment(localDate, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    fromText: (text: string): FormValue<LocalDate> => {
      const stripped = text.replace(/\s/g, "");
      if (stripped.length === 0) {
        return { kind: "empty" };
      } else {
        const m = moment(stripped, "DD/MM/YYYY");
        if (
          stripped.match(/^[0-9][0-9]?\/[0-9][0-9]?\/[0-9][0-9][0-9][0-9]$/)
        ) {
          return { kind: "valid", value: m.format("YYYY-MM-DD") };
        } else {
          return {
            kind: "invalid",
            error: "must be a date in DD/MM/YYYY form"
          };
        }
      }
    }
  });
}

/**
 * A text field that consists of a specified number of digits. Whitespace
 * in the field is allowed, but not included in the result.
 */
export function newDigitsFieldState(
  minDigits?: number,
  maxDigits?: number,
  errorMessage0?: string
): FieldState<string> {
  const errorMessage = errorMessage0 ? errorMessage0 : "field must be a number";
  return new FieldStateImpl({
    toText: value => value,
    fromText: text => {
      const stripped = text.replace(/\s/g, "");
      if (stripped === "") {
        return { kind: "empty" };
      }

      if (stripped.match(/^[0-9]*$/)) {
        if (
          (!minDigits || stripped.length >= minDigits) &&
          (!maxDigits || stripped.length <= maxDigits)
        ) {
          return {
            kind: "valid",
            value: stripped
          };
        }
      }

      return {
        kind: "invalid",
        error: errorMessage
      };
    }
  });
}

/**
 * A text field that must be an australian postcode
 */
export function newPostcodeField(): FieldState<string> {
  return newDigitsFieldState(4, 4, "must be a 4 digit Australian postcode");
}

export function newBsbFieldState(): FieldState<string> {
  const errorMessage = "field must be a 6 digit BSB number";
  return new FieldStateImpl({
    toText: value => value,
    fromText: text => {
      const stripped = text.replace(/\s/g, "");
      if (stripped === "") {
        return { kind: "empty" };
      }

      if (stripped.match(/^\d{3}-\d{3}$/)) {
        return {
          kind: "valid",
          value: stripped
        };
      }

      const match = stripped.match(/^(\d{3})(\d{3})$/);
      if (match) {
        return {
          kind: "valid",
          value: match[1] + "-" + match[2]
        };
      }

      return {
        kind: "invalid",
        error: errorMessage
      };
    }
  });
}

export function newAccountNumberFieldState(): FieldState<string> {
  return newDigitsFieldState(
    5 /* minimum account digits */,
    9 /* maximum account digits */,
    "must be an Australian bank account number between 5 and 9 digits"
  );
}

export function newEmailFieldState(): FieldState<string> {
  // Regex from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
  //tslint:disable-next-line:max-line-length
  const re = /^((([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,}))$/i;
  return newRegexTextField(re, "Please enter a valid email");
}

/**
 * A text field that must be a password
 */
export function newPasswordFieldState(): FieldState<string> {
  const error =
    "must be 10 characters and include upper case, lower case, and at least 1 digit";
  return new FieldStateImpl({
    toText(v: string): string {
      return v;
    },
    fromText(text: string): FormValue<string> {
      if (text.length === 0) {
        return { kind: "empty" };
      }
      if (
        text.length < 10 ||
        text.match(/[0-9]/g) === null ||
        text.match(/[a-z]/g) === null ||
        text.match(/[A-Z]/g) === null
      ) {
        return { kind: "invalid", error };
      }
      return { kind: "valid", value: text };
    }
  });
}

/**
 * A text field that must match the value of an input type datetime-local
 */
export function newDateTimeLocalFieldState(): FieldState<string> {
  /**
   * TODO(lois): Replace with a better datetime picker implementation
   * that supports all major browsers including Safari
   */
  return new FieldStateImpl({
    toText: value => value,
    fromText: text => {
      if (text.length === 0) {
        return {
          kind: "empty"
        };
        /**
         * This regexp matches the date form of 'YYYY-MM-DDTHH:mm'
         * return by an input of type datetime-local
         * Works fine on most browsers: Chrome, Firefox and mobile versions
         */
      } else if (text.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/)) {
        return {
          kind: "valid",
          value: text
        };
        /**
         * This case was included due to Safari mobile implementation fo datetime-local inputs
         * returning seconds and milliseconds as part of the input value
         * Special case for: Safari mobile
         */
      } else {
        const m = text.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}/);
        if (m) {
          return {
            kind: "valid",
            value: m[0]
          };
        } else {
          /**
           * This case will show up in browser that dont support datetime-local as an input type
           * (Safari desktop) and such we fallback to show a message with the required format
           */
          return {
            kind: "invalid",
            error:
              "Please enter a valid date in the following format: YYYY-MM-DDTHH:mm"
          };
        }
      }
    }
  });
}

/**
 *  Interface for converting values to/from text
 */
export interface Convert<T> {
  toText(value: T): string;
  fromText(text: string): FormValue<T>;
}

/**
 *  Interface for converting values to/from text asynchronously
 * (ie so that validation can happen server side)
 */
export interface ConvertP<T> {
  toText(value: T): string;
  fromText(text: string): Promise<FormValue<T>>;
}

export class FieldStateImplP<T> implements FieldState<T> {
  @observable.ref current: string = "";
  @observable.ref fvalue: FormValue<T> | undefined = undefined;

  constructor(readonly convert: ConvertP<T>, readonly debounceMs: number) {}

  value = () => {
    return this.current;
  };

  errorMsg = () => {
    if (this.fvalue && this.fvalue.kind === "invalid") {
      return this.fvalue.error;
    } else {
      return undefined;
    }
  };

  onChange = async (value: string) => {
    runInAction(() => {
      this.current = value;
      if (this.fvalue && this.fvalue.kind === "valid") {
        this.fvalue = undefined;
      }
    });

    debounce(this.debounceMs, async (_v: null) => {
      const fvalue = await this.convert.fromText(value);
      runInAction(() => {
        this.fvalue = fvalue;
      });
    })(null);
    return;
  };

  set = (value: T) => {
    runInAction(() => {
      this.current = this.convert.toText(value);
      this.fvalue = { kind: "valid", value };
    });
  };

  get = (): FormValue<T> => {
    return this.fvalue || { kind: "empty" };
  };

  getValid = (): T | undefined => {
    const v = this.get();
    if (v.kind === "valid") {
      return v.value;
    } else {
      return undefined;
    }
  };
}

function convertText(): Convert<string> {
  return {
    toText(v: string): string {
      return v;
    },
    fromText(text: string): FormValue<string> {
      return { kind: "valid", value: text };
    }
  };
}

function convertInteger(
  minValue: number | undefined,
  maxValue: number | undefined,
  errorMessage: string
): Convert<number> {
  return {
    toText(v) {
      return `${v}`;
    },
    fromText(text: string): FormValue<number> {
      //tslint:disable-next-line:no-parameter-reassignment
      text = text.trim();
      if (text === "") {
        return { kind: "empty" };
      }

      const v = parseInt(text, 10);
      if (
        isNaN(v) ||
        (minValue !== undefined && v < minValue) ||
        (maxValue !== undefined && v > maxValue)
      ) {
        return { kind: "invalid", error: errorMessage };
      }
      return { kind: "valid", value: v };
    }
  };
}

const CENTS_PER_DOLLAR = 100;

function convertAmount(
  minAmount: number | undefined,
  maxAmount: number | undefined,
  errorMessage: string
): Convert<number> {
  return {
    toText(v) {
      const a1 = Math.floor(v / CENTS_PER_DOLLAR);
      const a2 = v % CENTS_PER_DOLLAR;
      return `${a1}.${a2}`;
    },
    fromText(text: string): FormValue<number> {
      //tslint:disable-next-line:no-parameter-reassignment
      text = text.trim();
      if (text === "") {
        return { kind: "empty" };
      }
      const amount = parseAmount(text);
      if (amount === null) {
        return { kind: "invalid", error: errorMessage };
      } else if (minAmount !== undefined && amount < minAmount) {
        return { kind: "invalid", error: errorMessage };
      } else if (maxAmount !== undefined && amount > maxAmount) {
        return { kind: "invalid", error: errorMessage };
      }
      return { kind: "valid", value: amount };
    }
  };
}

function parseAmount(text: string): number | null {
  {
    const match = text.match(/^[0-9]+[.]?$/);
    if (match) {
      return parseInt(match[0], 10) * CENTS_PER_DOLLAR;
    }
  }
  {
    const match = text.match(/^([0-9]+)[.]([0-9][0-9]?)$/);
    if (match) {
      const CENTS_GROUP_INDEX = 2;
      return (
        parseInt(match[1], 10) * CENTS_PER_DOLLAR +
        parseInt(match[CENTS_GROUP_INDEX], 10)
      );
    }
  }
  {
    const match = text.match(/^[.]([0-9][0-9]?)$/);
    if (match) {
      return parseInt(match[1], 10);
    }
  }
  return null;
}

/**
 * Makes a converter that treats text as:
 * - valid if it matches the regex
 * - incomplete if it is empty
 * - invalid otherwise
 */
export function convertRegexp(
  re: RegExp,
  errorMessage: string
): Convert<string> {
  return {
    toText(v: string): string {
      return v;
    },
    fromText(text: string): FormValue<string> {
      const match = text.match(re);
      if (match) {
        return { kind: "valid", value: match.length > 1 ? match[1] : match[0] };
      } else {
        if (text === "") {
          return { kind: "empty" };
        }
        return { kind: "invalid", error: errorMessage };
      }
    }
  };
}

/**
 *  Add a callback to a fieldstate that gets notified of every valid value
 */
export function withValueCallback<T>(
  fieldState: FieldState<T>,
  valueCallback: (fv: FormValue<T>) => void
): FieldState<T> {
  //tslint:disable:no-unbound-method
  return {
    value: fieldState.value,
    errorMsg: fieldState.errorMsg,
    onChange: (text: string) => {
      fieldState.onChange(text);
      valueCallback(fieldState.get());
    },
    set: fieldState.set,
    get: fieldState.get,
    getValid: fieldState.getValid
  };
  //tslint:enable:no-unbound-method
}
