import { instantFieldFns } from "@hx/fields";
import { action, observable} from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import { Message } from "../../../adl-gen/whistle/propte/api";
import { Loading } from "../../../models/loading2";
import { renderLoading } from "../../widgets/loader/loader2";

const styles = require("./message-board-page.css");

export interface State {
  messages: Loading<Message[]>,
};

export interface Event
  {kind: 'new-message', message: string}


export interface MessageBoardProps {
  state: State;
  onEvent(event: Event): Promise<void>;
}

/**
 * The Message Board page.
 */
@observer
export class MessageBoardPage extends React.Component<MessageBoardProps> {

  @observable
  message: string = "";

  /** Renders the component */
  render() {
      return (
        <div className={styles.container}>
        {renderLoading(this.props.state.messages, this.renderMessages)}
        </div>
      );
  }

  renderMessages = (messages: Message[]): JSX.Element => {
    return (
      <div >
      {messages.map(this.renderMessage)}
      <textarea className={styles.textArea} value={this.message} onChange={this.changeMessage}></textarea>
      <button className={styles.sendButton} onClick={this.sendMessage}>Send</button>
      </div>
    );
  }

  renderMessage = (message: Message): JSX.Element => {
    return (
      <div className={styles.messagebox} key={message.id}>
        <div className={styles.message}>
          <div className={styles.messageHeader}>
            <div className={styles.messageUser}>{message.userFullName}</div>
            <div className={styles.messagePostedAt}>{instantFieldFns.toText(message.postedAt)}</div>
          </div>
          <div className={styles.messageText}>{message.message}</div>
        </div>
      </div>
    );
  }

  @action
  changeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.message = event.target.value;
  }

  @action
  sendMessage = async (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
    await this.props.onEvent({kind:"new-message", message:this.message});
    this.message = "";
  }
}
