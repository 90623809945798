import deepEqual from "deep-equal";
import moment from "moment";

import { DateFormat, ISO_FORMAT } from "./dateformats";

// A record specifying operations on a text field:
//
//    width    : the suggested width of the text field in chars
//    rows     : the suggested number of rows for the text field
//
//    toText   : convert a js value to the text content
//    validate : validate the text content returning a validate
//               error message on failure, or null on success
//    fromText : convert the text content back into a js value
//    equals   : compare two js values for this field

export interface FieldFns<T> {
  width: number;
  rows: number;

  toText(value: T): string;
  validate(text: string): null | string;
  fromText(text: string): T;
  equals(v1: T, v2: T): boolean;
  datalist?: string[];
}

// A string field

export const stringFieldFns: FieldFns<string> = {
  width: 30,
  rows: 1,
  toText(v) {
    return v;
  },
  validate(_text) {
    return null;
  },
  fromText(text) {
    return text;
  },
  equals(v1, v2) {
    return v1 === v2;
  }
};

// A string field constrained by a regex
//
// If the regex contains capture groups, the field result will be the value
// of the first capture group. Otherwise the field result will be the
// whole matching string. Hence a capture group can be used to allow preceding/trailing
// whitespace in a field, but to exclude it from the result.
export function regexStringFieldFns(regex: string, description: string): FieldFns<string> {
  const re = new RegExp(regex);
  return {
    width: 30,
    rows: 1,
    toText(v) {
      return v;
    },
    validate(text) {
      const match = text.match(re);
      if (match) {
        return null;
      } else {
        return "must be " + description;
      }
    },
    fromText(text) {
      const match = text.match(re);
      if (match && match.length > 1) {
        return match[1];
      }
      return text;
    },
    equals(v1, v2) {
      return v1 === v2;
    }
  };
}

// A string field that can't be empty

export const nonEmptyStringFieldFns: FieldFns<string> = regexStringFieldFns("^.+$", "non-empty");

// A bounded integer field

export function intFieldFns(minValue: number | null, maxValue: number | null): FieldFns<number> {
  return {
    width: 12,
    rows: 1,
    toText(v) {
      return "" + v;
    },
    validate(text) {
      const v = parseInt(text, 10);
      if (isNaN(v)) {
        return "must be an integer";
      } else if (minValue !== null && v < minValue) {
        return "value too small";
      } else if (maxValue !== null && v > maxValue) {
        return "value too large";
      } else {
        return null;
      }
    },
    fromText(text) {
      return parseInt(text, 10);
    },
    equals(v1, v2) {
      return v1 === v2;
    }
  };
}

// An arbitrary number
export function numberFieldFns(): FieldFns<number> {
  return {
    width: 12,
    rows: 1,
    toText(v) {
      return "" + v;
    },
    validate(text) {
      const v = parseFloat(text);
      if (isNaN(v)) {
        return "must be a number";
      } else {
        return null;
      }
    },
    fromText(text) {
      return parseFloat(text);
    },
    equals(v1, v2) {
      return v1 === v2;
    }
  };
}

// A BigDecimal value, which is stored as a string
// but must be validated as a number
export function bigDecimalFieldFns() : FieldFns<string> {
  return regexStringFieldFns( '^\\s*(-?(?:\\d+(?:\\.\\d+)?|\\.\\d+))\\s*$', "a decimal value");
}

export function boolFieldFns(): FieldFns<boolean> {
  return {
    width: 12,
    rows: 1,
    toText(v: boolean) {
      return v ? "true" : "false";
    },
    validate(text: string): string | null {
      const ltext = text.toLowerCase();
      if (ltext.length > 0 && ("true".startsWith(ltext) || "false".startsWith(ltext))) {
        return null;
      }
      return "Bool must be true or false";
    },
    fromText(text) {
      const ltext = text.toLowerCase();
      return "true".startsWith(ltext);
    },
    equals(v1, v2) {
      return v1 === v2;
    }
  };
}

export function jsonFieldFns(): FieldFns<unknown> {
  return {
    width: 30,
    rows: 4,
    toText(v: unknown): string {
      return JSON.stringify(v, null, 2);
    },
    validate(text: string): string | null {
      try {
        JSON.parse(text);
      } catch (e) {
        return "Json is not well formed";
      }
      return null;
    },
    fromText(text: string): unknown {
      return JSON.parse(text);
    },
    equals(v1, v2) {
      return deepEqual(v1, v2);
    }
  };
}

// Date field types

export function mkLocalDateFieldFns(format: DateFormat): FieldFns<string> {
  const errortext = "Must be a date in " + format.template + " format";
  return {
    width: 10,
    rows: 1,
    toText(v) {
      const ndate = ISO_FORMAT.parse(v); // The native ADL serialiation format is ISO
      if (ndate === null) {
        throw new Error(errortext);
      }
      return format.format(ndate);
    },
    validate(text) {
      if (format.parse(text) === null) {
        return errortext;
      }
      return null;
    },
    fromText(text) {
      const ndate = format.parse(text);
      if (ndate !== null) {
        return ISO_FORMAT.format(ndate);
      } else {
        throw new Error(errortext);
      }
    },
    equals(v1, v2) {
      return v1 === v2;
    }
  };
}

// A field containing an instant, in iso format (ie YYYYY-MM-DD[T ]HH:MM(:SS.SSS)?)?"

export const instantFieldFns: FieldFns<number> = {
  width: 15,
  rows: 1,
  toText(v) {
    // We always format in localtime (ie without a tz suffix)
    return moment(v).format("Y-MM-DD HH:mm:ss.SSS");
  },
  validate(text) {
    if (moment(text).isValid()) {
      return null;
    } else {
      return "must be a datetime in ISO YYYY-MM-DD HH:MM:SS format";
    }
  },
  fromText(text) {
    // We parse and process the timezone suffix if present:
    //   No TZ suffix -> localtime
    //   suffix 'Z' -> utc
    //   suffix '+1' -> utc+1 hour, etc
    return moment(text).valueOf();
  },
  equals(v1, v2) {
    return v1 === v2;
  }
};

export const localDateTimeFieldFns: FieldFns<string> = {
  width: 15,
  rows: 1,
  toText(v) {
    return v;
  },
  validate(text) {
    if (moment(text).isValid()) {
      return null;
    } else {
      return "must be a datetime in ISO YYYY-MM-DD HH:MM:SS format";
    }
  },
  fromText(text) {
    return text;
  },
  equals(v1, v2) {
    return v1 === v2;
  }
};

const TIME_REGEXP = new RegExp("^[ \t]*(([0-9]?[0-9]):([0-9][0-9])(:([0-9][0-9]))?)[ \t]*$");

export const localTimeFieldFns: FieldFns<string> = {
  width: 15,
  rows: 1,
  toText(v) {
    return v;
  },
  validate(text) {
    const m = text.match(TIME_REGEXP);
    if (m) {
      const hour = parseInt(m[2], 10);
      const min = parseInt(m[3], 10);
      const sec = (m[5] as string | undefined) === undefined ? 0 : parseInt(m[5], 10);
      if (hour >= 0 && hour < 24 && min >= 0 && min < 60 && sec >= 0 && sec < 60) {
        return null;
      }
    }
    return "must be a time in ISO HH:MM:SS format";
  },
  fromText(text) {
    return text;
  },
  equals(v1, v2) {
    return v1 === v2;
  }
};

interface Mapping<T> {
  value: T;
  label: string;
}

// A custom field for a finite set of values of type t, labeled by strings

export function labelledValuesFieldFns<T>(
  typelabel: string,
  equals: (v1: T, v2: T) => boolean,
  mappings: Mapping<T>[]
): FieldFns<T> {
  const labelmap : {[key: string]: T} = {};
  mappings.forEach(m => {
    labelmap[m.label] = m.value;
  });
  const datalist = mappings.map(m => m.label);

  function toText(value: T): string {
    for (const m of mappings) {
      if (equals(m.value, value)) {
        return m.label;
      }
    }
    // If we can't find a mapping, use the underlying value with ":" as a
    // marker prefix
    return ":" + String(value);
  }

  function validate(text: string): null | string {
    if (labelmap.hasOwnProperty(text)) {
      return null;
    }
    return "must be a " + typelabel;
  }

  function fromText(text: string): T {
    return labelmap[text];
  }

  return {
    width: 30,
    rows: 1,
    toText,
    validate,
    fromText,
    equals,
    datalist
  };
}

// Add a datalist (ie list of precanned values) to a field
export function withDatalist<T>(fieldFns: FieldFns<T>, datalist: string[]): FieldFns<T | null> {
  const newFieldFns = { ...fieldFns };
  newFieldFns.datalist = datalist;
  return newFieldFns;
}
